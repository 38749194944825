/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Container } from "reactstrap"
import MetaTags from "react-meta-tags"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import "./datatables.scss"
import "./specification.scss"
import axios from "axios"
import { toast } from "react-toastify"
import Select from "react-select"
import NotificationForm from "./NotificationForm"
import { useSelector, useDispatch } from "react-redux"
import {
  setTitle,
  updateNotifications
} from "../../../store/actions"
import SweetAlert from "react-bootstrap-sweetalert"


const ActiveUsers = (props) => {
  const dispatch = useDispatch()
  const countries = useSelector(state => state.MainData.countries)
  const notifications = useSelector(state => state.Notifications.reportCount)
  // const users = useSelector(state => state.MainData.users)
  const [active, setActive] = useState(false)
  const [productData, setProductData] = useState([])
  const [data, setData] = useState([])
  const [reports, setReports] = useState([])
  const [editCop, setEditCop] = useState(null)
  const [id, setId] = useState(null)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  const [ids, setIds] = useState([])


  const columns = [{
    dataField: "key",
    text: "Id",
    sort: true
  }, {
    dataField: "from",
    text: "From",
    sort: true
  }, {
    dataField: "to",
    text: "To",
    sort: true
  }, {
    dataField: "description",
    text: "Description",
    sort: true
  }, {
    dataField: "actions",
    text: "Actions",
    sort: true
  }, {
    dataField: "created_at",
    text: "Created On",
    sort: true
  }]

  const pagination = paginationFactory({
    page: 2,
    sizePerPageList: [{
      text: "10", value: 10
    }, {
      text: "25", value: 25
    }, {
      text: "50", value: 50
    }, {
      text: "75", value: 75
    }, {
      text: "100", value: 100
    }, {
      text: "All", value: productData.length
    }],
    showTotal: true
  })


  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" }
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const { SearchBar } = Search

  function addProd(data, type) {
    setEditCop(null)
    if (type === "add") {
      setProductData(products => products.concat({
        ...data,
        actions: <div>
          <button style={editBtn} onClick={() => editCoupon(data)}><i className="bx bx-edit fs-4" /></button>
          <button style={deleteBtn} id={data.id} onClick={(e) => deleteHandler(e.currentTarget.id)}><i
            className="bx bx-trash fs-4" /></button>
        </div>
      }))
    } else {
      setProductData(products => products.map(prod => prod.id === data.id ? { ...prod, ...data } : prod))
    }
  }


  function clickHandle(id) {
    setId({ id, lang: "en" })
    setActive(!active)
    setEditCop(null)
  }


  function editCoupon(data) {
    setEditCop(data)
    setActive(true)
  }


  function deleteHandler(id) {
    let ids = []
    ids.push(id)
    axios.post(`${process.env.REACT_APP_API}/admin/reports/delete`, { ids }, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
      }
    })
      .then(res => {
      })
    setProductData(products => products.filter(prod => prod.key !== id))
    setMsg("Deleted!")
    setsuccess_msg(true)
  }

  // function deleteMultiHandler() {
  //   axios.post(`${process.env.REACT_APP_API}/admin/notifications/delete`, { ids }, {
  //     headers: {
  //       "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
  //     }
  //   })
  //     .then(res => {
  //     })
  //   setProductData(products => products.filter(prod => prod.id !== id))
  //   //props.userChanges(user, "delactive")
  //   setMsg("Deleted!")
  //   setsuccess_msg(true)
  // }

  function deActiveHandler(id, user) {
    axios.post(`${process.env.REACT_APP_API}/admin/users/deactive`, { id }, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
      }
    })
      .then(res => {
        console.log(res.data.data)
      })
    setProductData(products => products.filter(prod => prod.id !== id))
    props.userChanges(user, "unactive")
    toast.success(`Deactive ${user.nameEn}`)
  }

  function countryFilter(country) {
    if (country === "All") {
      setProductData(reports.map(user => {
        return {
          ...user,
          from: user.userFrom.email,
          to: user.userTo.email,
          actions: <div className="d-flex gap-2">
            <button style={editBtn} onClick={(e) => clickHandle()}><i className="bx bx-bell fs-4" />Send Notification
            </button>
            <button style={deleteBtn} onClick={(e) => deleteHandler(user.key)}><i
              className="bx bx-trash fs-4" /> Delete
            </button>
          </div>
        }
      }))
      return
    }
    let report = reports.filter(re => re.userFrom.country === country || re.userTo.country === country)
    setProductData(report.map(user => {
      return {
        ...user,
        from: user.userFrom.email,
        to: user.userTo.email,
        actions: <div className="d-flex gap-2">
          <button style={editBtn} onClick={(e) => clickHandle()}><i className="bx bx-bell fs-4" />Send Notification
          </button>
          <button style={deleteBtn} onClick={(e) => deleteHandler(user.key)}><i className="bx bx-trash fs-4" /> Delete
          </button>
        </div>
      }
    }))
  }

  function userFilter(name) {
    if (name === "All") {
      setProductData(reports.map(user => {
        return {
          ...user,
          from: user.userFrom.email,
          to: user.userTo.email,
          actions: <div className="d-flex gap-2">
            <button style={editBtn} onClick={(e) => clickHandle()}><i className="bx bx-bell fs-4" /> Notification
            </button>
            <button style={deleteBtn} onClick={(e) => deleteHandler(user.key)}><i
              className="bx bx-trash fs-4" /> Delete
            </button>
          </div>
        }
      }))
      return
    }
    let report = reports.filter(re => re.userFrom.key === name || re.userTo.key === name)
    setProductData(report.map(user => {
      return {
        ...user,
        from: user.userFrom.email,
        to: user.userTo.email,
        actions: <div className="d-flex gap-2">
          <button style={editBtn} onClick={(e) => clickHandle()}><i className="bx bx-bell fs-4" /> Notification
          </button>
          <button style={deleteBtn} onClick={(e) => deleteHandler(user.key)}><i className="bx bx-trash fs-4" /> Delete
          </button>
        </div>
      }
    }))
  }

  useEffect(() => {
    dispatch(setTitle("Reports"))
    axios.get(`${process.env.REACT_APP_API}/admin/reports`, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
      }
    })
      .then(res => {
        setReports(res.data.data.pending.data)
        setProductData(res.data.data.pending.data.map(user => {
          return {
            ...user,
            from: user.userFrom.email,
            to: user.userTo.email,
            actions: <div className="d-flex gap-2">
              <button style={editBtn} onClick={(e) => clickHandle(user.userTo.key)}><i className="bx bx-bell fs-4" />Send
                Notification
              </button>
              <button style={deleteBtn} onClick={(e) => deleteHandler(user.key)}><i
                className="bx bx-trash fs-4" /> Delete
              </button>
            </div>
          }
        }))
      })
    axios.post(`${process.env.REACT_APP_API}/admin/setUnread`, { key: "6" }, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
      }
    }).then(res => {
      dispatch(updateNotifications({ unreadReportCount: 0 }))
    })
  }, [])

  useEffect(() => {
    if (notifications) {
      axios.get(`${process.env.REACT_APP_API}/admin/reports`, {
        headers: {
          "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
        }
      })
        .then(res => {
          setReports(res.data.data.pending.data)
          setProductData(res.data.data.pending.data.map(user => {
            return {
              ...user,
              from: user.userFrom.email,
              to: user.userTo.email,
              actions: <div className="d-flex gap-2">
                <button style={editBtn} onClick={(e) => clickHandle(user.userTo.key)}><i
                  className="bx bx-bell fs-4" />Send Notification
                </button>
                <button style={deleteBtn} onClick={(e) => deleteHandler(user.key)}><i
                  className="bx bx-trash fs-4" /> Delete
                </button>
              </div>
            }
          }))
        })
      axios.post(`${process.env.REACT_APP_API}/admin/setUnread`, { key: "6" }, {
        headers: {
          "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
        }
      }).then(res => {
        dispatch(updateNotifications({ unreadReportCount: 0 }))
      })
    }
  }, [notifications])


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Reports | Etoy App</title>
        </MetaTags>
        <Container fluid>
          {success_msg ? (
            <SweetAlert
              title={msg}
              success
              confirmBtnBsStyle="success"
              onConfirm={() => {
                setsuccess_msg(false)
              }}
              onCancel={() => {
                setsuccess_msg(false)
              }}
            >
            </SweetAlert>
          ) : null}
          <NotificationForm clickHandle={clickHandle} active={active} userData={id} />
          <Row className="mb-3">
            <Col>
              <Select
                options={countries ? [{ value: "All", label: "All" }].concat(countries.map(c => {
                  return { value: c.nameEn, label: c.nameEn + ` (${c.users_count})` }
                })) : null}
                placeholder="Country"
                onChange={(e) => countryFilter(e.value)}
              />
            </Col>
          
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody className="pb-0">
                  <ToolkitProvider
                    keyField="key"
                    columns={columns}
                    data={productData}
                    search
                  >
                    {props => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col>
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative ">
                                <SearchBar
                                  {...props.searchProps}
                                  className="input_cus"
                                />
                                <i className="bx bx-search-alt search-icon fs-3 pt-1" />
                              </div>
                            </div>
                          
                          </Col>

                        </Row>

                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"key"}
                                responsive
                                bordered={false}
                                striped={false}
                                pagination={pagination}
                                selectRow={selectRow}
                                classes={
                                  "table align-middle table-nowrap"
                                }
                                headerWrapperClasses={"thead-light"}
                                {...props.baseProps}
                              />
                              {productData.length === 0 ? <div className="emptyStateTable" style={{
                                width: "100%",
                                textAlign: "center",
                                fontSize: "16px",
                                padding: "10px",
                                paddingTop: "0", paddingBottom: "20px"
                              }}>no data available in table</div> : null}
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )
                    }
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ActiveUsers

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px"

}

const editBtn = {
  background: "transparent",
  border: "none",
  color: "#000",
  display: "flex",
  alignItems: "center",
  gap: "5px"
}

const rej = {
  background: "transparent",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px",
  width: "150px",
  height: "48px",
  border: "1px solid #D91414",
  textAlign: "center",
  justifyContent: "center"
}