import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { setTitle } from "../../../store/title/actions"
import MetaTags from "react-meta-tags"
import { Col, Container, Row } from "reactstrap"
import EStore from "./components/EToyStore"


function StoreToys(props) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setTitle("Etoy Store/ Toys"))
  }, [])

  return (
    <>
      <EStore />
    </>
  )
}


export default StoreToys