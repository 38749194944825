/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import AddAvatarForm from './AddAvatarForm'
import UpdateAvatarForm from './UpdateAvatarForm'
import "./datatables.scss"
import './specification.scss'
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert"

const Avatar = (props) => {
  const [active, setActive] = useState(false)
  const [addActive, setAddActive] = useState(false)
  const[productData, setProductData] = useState([])
  const[avatar, setAvatar] = useState(null)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  const columns = [{
    dataField: 'key',
    text: 'Id',
    sort: true,
  }, {
    dataField: 'image',
    text: 'Avatar',
    sort: true
  }, {
    dataField: 'gender',
    text: 'Gender',
    sort: true
  },{
    dataField: 'actions',
    text: 'Actions',
    sort: true
  }];

  const pagination = paginationFactory({
    page: 2,
    sizePerPageList: [{
      text: "10", value: 10
    }, {
      text: "25", value: 25
    }, {
      text: "50", value: 50
    }, {
      text: "75", value: 75
    }, {
      text: "100", value: 100
    }, {
      text: "All", value: productData.length
    }],
    showTotal: true
  })


  
  // Select All Button operation
  const selectRow = {
    mode: 'checkbox'
  }

  const { SearchBar } = Search;


  function clickHandle (avatar, type) {
    setActive(type)
    setAvatar(avatar);
  }
  function clickAddHandle (avatar, type) {
    setAddActive(type)
    setAvatar(avatar);
  }
  
  function update(data, type) {
    if (type === "add"){
        setProductData(users => users.concat({...data, 
          image: <img src={`${data.image}`} alt="" style={{width: "50px", height: "50px", objectFit: "cover"}}/>,
          actions:<div className="d-flex">
                    <button style={editBtn}  onClick={() => clickHandle(data, true)}><i className='bx bx-edit fs-4 pr-1' style={{marginRight: "10px"}}></i> Edit</button>
                    <button style={deleteBtn}  onClick={(e)=>deleteAvatar(data.key)}><i className='bx bx-trash fs-4' style={{marginRight: "10px"}}></i> Delete</button>
                  </div>
        }))
        setMsg("Added!")
    }else {
      setProductData(users => users.map(user =>user.key === data.key? {...data, image: <img src={`${data.image}`} alt="" style={{width: "50px", height: "50px", objectFit: "cover"}}/>,
      actions: <div className="d-flex">
                    <button style={editBtn}  onClick={() => clickHandle(data, true)}><i className='bx bx-edit fs-4 pr-1' style={{marginRight: "10px"}}></i> Edit</button>
                    <button style={deleteBtn}  onClick={(e)=>deleteAvatar(data.key)}><i className='bx bx-trash fs-4' style={{marginRight: "10px"}}></i> Delete</button>
                </div>}: user))
      setMsg("Updated!")
    }
    setsuccess_msg(true)
  }

  function deleteAvatar(id) {
    axios.post(`${process.env.REACT_APP_API}/admin/setting/avatarDelete`, {id}, {
      headers: { 
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`,
      }
    })
    .then(res => {
    })
    setProductData(products => products.filter(prod => prod.key != id))
    setMsg("Deleted!")
    setsuccess_msg(true)
  }

  useEffect(() => {
    setProductData(props.avatar.map(avatar => {return{...avatar, 
      image: <img src={avatar.image} className='usernametabl' alt=""/>, 
      actions: <div className="d-flex">
                    <button style={editBtn}  onClick={() => clickHandle(avatar, true)}><i className='bx bx-edit fs-4 pr-1' style={{marginRight: "10px"}}></i> Edit</button>
                    <button style={deleteBtn}  onClick={(e)=>deleteAvatar(avatar.key)}><i className='bx bx-trash fs-4' style={{marginRight: "10px"}}></i> Delete</button>
              </div>
    }}))
  }, [props.avatar]) 


  return (
    <React.Fragment>
        <div className="container-fluid p-0">
          {success_msg ? (
                    <SweetAlert
                      title={msg}
                      success
                      confirmBtnBsStyle="success"
                      onConfirm={() => {
                        setsuccess_msg(false)
                      }}
                      onCancel={() => {
                        setsuccess_msg(false)
                      }}
                    >
                    </SweetAlert>
                  ) : null}
            <UpdateAvatarForm active={active} clickHandle={clickHandle} avatar={avatar} update={update}/>
            <AddAvatarForm active={addActive} clickAddHandle={clickAddHandle} avatar={addActive} update={update}/>
          <Row>
            <Col className="col-12">
              <Card >
                <CardBody className="pb-0">
                      <ToolkitProvider
                        keyField='key'
                        columns={columns}
                        data={productData}
                        search
                      >
                        {props => (
                          <React.Fragment>

                            <Row className="mb-2">
                              <Col>
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative ">
                                    <SearchBar
                                      {...props.searchProps}
                                      className="input_cus"
                                    />
                                    <i className="bx bx-search-alt search-icon fs-3 pt-1" />
                                  </div>
                                </div>
                              </Col>
                              <Col >
                                <div className="save__btn top">
                                    <button onClick={() => clickAddHandle(null, true)}><i className='bx bx-plus-circle fs-4'></i> Add Avatar</button>
                                </div>
                              </Col>
                            </Row>
                            
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"key"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    pagination={pagination}
                                    selectRow={selectRow}
                                    classes={
                                      "table align-middle table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...props.baseProps}
                                  />
                                  {productData.length === 0?<div className="emptyStateTable" style={{
                                      width: '100%',
                                      textAlign: 'center',
                                      fontSize: '16px',
                                      padding: '10px',
                                      paddingTop: '0', paddingBottom: "20px"}}>no data available in table</div>:null}
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )
                        }
                      </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
    </React.Fragment>
  )
}

export default Avatar

const deleteBtn = {
  background: "transparent",
  border: "none",
  color:"#D91414",
  display: "flex",
  alignItems: "center",
  gap: "0px"

}

const editBtn = {
  background: "transparent",
  border: "none",
  display: "flex",
  alignItems: "center",
  gap: "0px"
}