/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Tooltip, Button } from "reactstrap"
import "../../../General/Notifications/Components/datatables.scss"
import "../../../General/Notifications/Components/specification.scss"
import axios from "axios"
import NotificationsPagination from "./AdminNotificationsPagination"
import { useSelector } from "react-redux"

const AdminNotifications = (props) => {
  const notifications = useSelector(state => state.Notifications.notifyCount)
  const [active, setActive] = useState(false)
  // const [productData, setProductData] = useState([])
  const [adminnotification, setAN] = useState([])
  const [editCop, setEditCop] = useState(null)
  const [ids, setIds] = useState([])

  const columns = [{
    dataField: "key",
    text: "Id",
    sort: true
  }, {
    dataField: "userto[nameEn]",
    text: "To User",
    sort: true
  }, {
    dataField: "message_en",
    text: "Description (English)",
    sort: true
  }, {
    dataField: "message_ar",
    text: "Description (Arabic)",
    sort: true
  }, {
    dataField: "actions",
    text: "Actions",
    sort: true
  }, {
    dataField: "created_at",
    text: "Created On",
    sort: true
  }]

  const [pageNo, setPageNo] = useState(1)
  const sizePerPage=10;
  const [totalCount, setTotalCount] = useState(0)
  const [message_enTooltipOpen, setMessage_enTooltipOpen] = useState(false);
  const [message_arTooltipOpen, setMessage_arTooltipOpen] = useState(false);

  useEffect(() => {
      bindData()
  }, [pageNo, message_enTooltipOpen, message_arTooltipOpen])

  useEffect(() => {
    if(notifications) {
      bindData()
    }
  }, [pageNo, notifications])

  const bindData = async () => {
    // let { pageNo } = filter
    await axios.get(`${process.env.REACT_APP_API}/admin/notifications/getPublishAdminToUsers?page=${pageNo}`, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
      }
    }).then(res => {
      setAN(res.data.data.data.map(user => { return{...user,
        image: <img src={user.image} className='usernametabl' alt=""/>,
        // message_en: user.message_en ? user.message_en.slice(0, 44).concat(" ...ـ") : <span className="fs-4">-</span>,
        // message_ar: user.message_ar ? user.message_ar.slice(0, 44).concat(" ...ـ") : <span className="fs-4">-</span>,
        message_en: user.message_en ? user.message_en : <span className="fs-4">-</span>,
        message_ar: user.message_ar ? user.message_ar : <span className="fs-4">-</span>,
        // <>
        //   <span id="message_en">{user.message_en ? user.message_en.slice(0,43).concat(" ...") : <span className="fs-4">-</span> }</span>
        //   <Tooltip
        //     isOpen={message_enTooltipOpen}
        //     placement="top"
        //     target="message_en"
        //     // delay={{ show:150, hide: 150 }}
        //     style={{backgroundColor: "#2a3042", opacity:"0.9", borderRadius:"10px", textAlign: "left"}}
        //     toggle={() => {
        //       setMessage_enTooltipOpen(!message_enTooltipOpen)
        //     }}>
        //     {user.message_en ? user.message_en : ""}
        //   </Tooltip>
        // </>,
        // message_ar:
        //   <div style={{textAlign: "right"}}>
        //     <span id="message_ar">{user.message_ar ? user.message_ar.slice(0,43).concat(" ...ـ") : <span className="fs-4">-</span> }</span>
        //     <Tooltip
        //       isOpen={message_arTooltipOpen}
        //       placement="top"
        //       target="message_ar"
        //       // delay={{ show:150, hide: 150 }}
        //       style={{backgroundColor: "#2a3042", opacity:"0.9", borderRadius:"10px", textAlign: "right"}}
        //       toggle={() => {
        //         setMessage_arTooltipOpen(!message_arTooltipOpen)
        //       }}>
        //       {user.message_ar ? user.message_ar : ""}
        //     </Tooltip>
        //   </div>,
        deal: user.deal?user.deal:<span className="fs-4">-</span>,
        city: user.city?user.city: <span className="fs-4">-</span>,
        from: user["userFrom"]?user["userFrom"]["nameEn"]:<span className="fs-4">-</span>,
        to: user["userto"]?user["userto"]["nameEn"]:<span className="fs-4">-</span>,
        actions: <div className="d-flex gap-2">
          <button style={deleteBtn}  onClick={(e)=>deleteHandler(user.key, user)}><i className='bx bx-trash fs-4' /> Delete</button>
        </div>
      }}))
      setTotalCount(res.data.data.total)
    })
  }

  const onPageHandler = async (type, {page}) => {
     await setPageNo(page)
  }

  function deleteHandler(id, user) {
    // props.userChanges(id, "sdsdsdsd")
    axios.post(`${process.env.REACT_APP_API}/admin/notifications/delete`, { id }, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
      }
    })
      .then(res => {

      })
  }

  return (
    <React.Fragment>
      <div className="container-fluid p-0">
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody className="pb-0">

                <NotificationsPagination data={adminnotification} page={pageNo}
                                              sizePerPage={sizePerPage} totalSize={totalCount}
                                              onTableChange={onPageHandler} columns={columns} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default AdminNotifications

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414"
}

const editBtn = {
  background: "transparent",
  border: "none",
  color: "green"
}

const rej = {
  background: "transparent",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px",
  width: "150px",
  height: "48px",
  border: "1px solid #D91414",
  textAlign: "center",
  justifyContent: "center"
}