/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Row,Container } from "reactstrap";
import { useSelector } from "react-redux"

const Cards = (props) => {
    const [data, setData] = useState({
        rejectedt_toys_count: 0,
        usersCount: 0,
        toysCount: 0,
        suggestionsCount: 0,
        reportsCount: 0,
        reviews_count: 0,
        historyToysCount: 0,
        pendingUsersCount: 0,
        unActiveUsers: 0,
        pendingToysCount: 0,
        activeToysCount: 0,
        toysSwapCount: 0,
        toysSaleCount: 0,
        feedBacksCount: 0,
        activeChatToysCount: 0,
        toysFreeCount:0
    })
    const countries = useSelector(state => state.MainData.countries)

    useEffect(() => {
        if(props.data) {
            let {
                usersCount,
                toysCount,
                suggestionsCount,
                reportsCount,
                reviews_count,
                historyToysCount,
                pendingUsersCount,
                unActiveUsers,
                pendingToysCount,
                activeToysCount,
                toysSwapCount,
                toysSaleCount,
                feedBacksCount,
                activeChatToysCount,
                toysFreeCount,
                rejectedt_toys_count
            } = props.data
            setData( {
                usersCount,
                toysCount,
                suggestionsCount,
                reportsCount,
                reviews_count,
                historyToysCount,
                pendingUsersCount,
                unActiveUsers,
                pendingToysCount,
                activeToysCount,
                toysSwapCount,
                toysSaleCount,
                feedBacksCount,
                activeChatToysCount,
                toysFreeCount,
                rejectedt_toys_count
            })
        }
    }, [props.data])
    return (
      <Row>
          <Col xl={3} md={4} sm={6} xs={12}>
              <Card className="blog-stats-wid">
                  <CardBody>
                      <div className="d-flex flex-wrap">
                          <div className="me-3">
                              <p className="text-muted mb-2">Number of Countries</p>
                              <h5 className="mb-0">{countries?.length}</h5>
                          </div>

                          <div className="avatar-sm ms-auto">
                              <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                  <svg width="30" height="30" viewBox="0 0 44 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <g clipPath="url(#clip0_478_211)">
                                          <path d="M37.0341 13.1317L36.8798 12.9678C36.041 12.0905 35.8096 10.9528 36.3495 10.4418L38.6635 8.26276C39.0846 7.88396 39.6386 7.6875 40.2043 7.71642C40.7699 7.74533 41.3011 7.99725 41.6813 8.41701L41.8452 8.58092C42.245 8.98522 42.4692 9.53089 42.4692 10.0995C42.4692 10.6681 42.245 11.2137 41.8452 11.618L39.5409 13.7874C38.9624 14.308 37.8729 14.0091 37.0341 13.1317Z" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                          <path d="M17.75 45.3154C17.75 45.3154 19.6301 43.387 20.6425 42.4229C28.5004 43.0014 31.4314 37.5539 32.2124 36.6379" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                          <path d="M37.6508 31.181L37.4869 31.046C36.6481 30.3229 36.3396 29.3009 36.7927 28.7802L38.721 26.553C38.8897 26.3539 39.0971 26.1911 39.3305 26.0744C39.564 25.9578 39.8186 25.8897 40.0792 25.8744C40.3397 25.8591 40.6006 25.8968 40.8461 25.9853C41.0916 26.0737 41.3166 26.2111 41.5075 26.3891L41.6617 26.5241C42.0627 26.8615 42.314 27.3437 42.3609 27.8657C42.4079 28.3876 42.2467 28.9069 41.9124 29.3105L39.9841 31.5281C39.5309 32.0583 38.48 31.9041 37.6508 31.181Z" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                          <path d="M18.7149 38.5664C19.5399 36.2972 19.8025 33.8618 19.4801 31.469C19.1576 29.0761 18.2599 26.797 16.8637 24.8271C17.6198 22.5949 18.0864 20.2748 18.2521 17.9238C18.3356 16.4834 17.8936 15.0617 17.0083 13.9225C16.8313 13.6853 16.5947 13.4992 16.3225 13.383C16.0503 13.2668 15.7522 13.2247 15.4585 13.2609C15.1648 13.2971 14.8858 13.4104 14.65 13.5893C14.4142 13.7681 14.2299 14.0062 14.1158 14.2792C12.5346 19.3218 12.2454 20.7005 11.2234 23.2266C10.1339 26.0613 10.7606 30.0432 10.2592 31.2002C10.1531 31.4316 8.08984 34.7098 8.08984 34.7098" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                          <path d="M37.6605 25.1164L37.4966 24.9814C36.6482 24.2487 36.33 23.2074 36.7928 22.6771L38.7211 20.4113C39.0763 20.0154 39.5737 19.776 40.1047 19.7453C40.6357 19.7146 41.1573 19.8951 41.5557 20.2474L41.7197 20.3824C42.1281 20.7255 42.3839 21.2165 42.4309 21.7479C42.4779 22.2792 42.3122 22.8076 41.9703 23.217L40.042 25.4731C39.5792 26.0323 38.509 25.8492 37.6605 25.1164Z" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                          <path d="M37.6605 19.2156L37.4966 19.071C36.6482 18.3383 36.33 17.3066 36.7928 16.7667L38.7211 14.5009C39.0763 14.105 39.5737 13.8656 40.1047 13.8349C40.6357 13.8042 41.1573 13.9847 41.5557 14.337L41.7197 14.472C42.1281 14.8151 42.3839 15.3061 42.4309 15.8375C42.4779 16.3688 42.3122 16.8972 41.9703 17.3066L40.042 19.5627C39.5792 20.1027 38.509 19.9388 37.6605 19.2156Z" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                          <path d="M25.4629 33.7456H28.3554" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                          <path d="M37.9979 8.67738V3.85659C37.9979 3.08945 37.6932 2.35374 37.1507 1.8113C36.6083 1.26885 35.8726 0.964111 35.1054 0.964111H18.7147C17.9476 0.964111 17.2119 1.26885 16.6694 1.8113C16.127 2.35374 15.8223 3.08945 15.8223 3.85659V13.4982" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                          <path d="M18.7148 36.6379H35.1055C35.8727 36.6379 36.6084 36.3332 37.1508 35.7907C37.6933 35.2483 37.998 34.5126 37.998 33.7455V31.8171" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                          <path d="M18.7142 45.3156L6.18017 32.7815L1.35938 37.6023L9.07264 45.3156" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                          <path d="M6.17969 37.6023L7.14385 38.5665" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                          <path d="M19.6777 30.853H37.0326" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                          <path d="M15.8223 5.78491H37.9979" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                          <path d="M27.3915 11.1553L29.3873 14.6262L33.0994 15.3012L30.6118 18.6082L30.9204 22.2045L27.3915 21.0957L23.8724 22.2527L24.1809 18.5986L21.6934 15.3012L25.4054 14.6262L27.3915 11.1553Z" stroke="black" strokeWidth="2" strokeLinejoin="round"/>
                                      </g>
                                      <defs>
                                          <clipPath id="clip0_478_211">
                                              <rect width="43.445" height="46" fill="white"/>
                                          </clipPath>
                                      </defs>
                                  </svg>
                              </div>
                          </div>
                      </div>
                  </CardBody>
              </Card>
          </Col>
          <Col xl={3} md={4} sm={6} xs={12}>
              <Card className="mini-stats-wid">
                  <CardBody>
                      <div className="d-flex flex-wrap">
                          <div className="me-3">
                              <p className="text-muted mb-2">Number Of Users</p>
                              <h5 className="mb-0">{data.usersCount}</h5>
                          </div>
                          <div className="avatar-sm ms-auto">
                              <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                  <svg width="30" height="30" viewBox="0 0 42 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M15 27.5923V31.5923H14L11 34.5923L6 36.9923C3.3 37.6923 1.1 39.5923 1 41.5923V48.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M31 10.5923V12.5923L33 13.5923V17.4923C33 18.1923 32.7 18.7923 32.1 19.1923L31 20.5923C31 20.5923 31 22.1923 31 22.5923C31 26.4923 25.9 28.1923 25 28.5923C24.1 28.9923 22.6 29.5923 21 29.5923C19.4 29.5923 17.9 28.9923 17 28.5923C16.1 28.1923 11 26.4923 11 22.5923C11 22.0923 11 20.5923 11 20.5923L9.9 19.1923C9.3 18.7923 9 18.1923 9 17.4923V13.5923L11 12.5923V10.5923C11 5.59229 15 1.59229 20 1.59229H22C27 1.59229 31 5.59229 31 10.5923Z" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M15 31.5923L21 36.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M21 36.5923L16 40.5923L11 34.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M27 27.5923V31.5923H28L31 34.5923L36 36.9923C38.7 37.6923 40.9 39.6923 41 41.5923V48.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M27 31.5923L21 36.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M21 36.5923L26 40.5923L31 34.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M24 38.5923L23 43.5923H19L18 38.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M24 47.5923L23 43.5923H19L18 47.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                  </svg>
                              </div>
                          </div>
                      </div>
                  </CardBody>
              </Card>
          </Col>
          <Col xl={3} md={4} sm={6} xs={12}>
              <Card className="blog-stats-wid">
                  <CardBody>
                      <div className="d-flex flex-wrap">
                          <div className="me-3">
                              <p className="text-muted mb-2">Number of pending users</p>
                              <h5 className="mb-0">{data.pendingToysCount}</h5>
                          </div>

                          <div className="avatar-sm ms-auto">
                              <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                  <svg width="30" height="30" viewBox="0 0 48 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M18 21.5923C22.5 21.5923 26 18.2923 26 13.5923V9.59229C26 4.89229 22.5 1.59229 18 1.59229C13.5 1.59229 10 4.89229 10 9.59229V13.5923C10 18.2923 13.5 21.5923 18 21.5923Z" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M24 25.3923C21.8 24.8923 19.6 24.5923 18 24.5923C12.4 24.5923 1 27.4923 1 33.5923V41.5923" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M36 43.5923C42.0751 43.5923 47 38.6674 47 32.5923C47 26.5172 42.0751 21.5923 36 21.5923C29.9249 21.5923 25 26.5172 25 32.5923C25 38.6674 29.9249 43.5923 36 43.5923Z" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M36 24.5923V32.5923L40 35.5923" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                                  </svg>

                              </div>
                          </div>
                      </div>
                  </CardBody>
              </Card>
          </Col>
          <Col xl={3} md={4} sm={6} xs={12}>
              <Card className="blog-stats-wid">
                  <CardBody>
                      <div className="d-flex flex-wrap">
                          <div className="me-3">
                              <p className="text-muted mb-2">Number of rejected toys</p>
                              <h5 className="mb-0">{data.rejectedt_toys_count}</h5>
                          </div>

                          <div className="avatar-sm ms-auto">
                              <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                  <svg width="30" height="30" viewBox="0 0 48 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M18 21.5923C22.5 21.5923 26 18.2923 26 13.5923V9.59229C26 4.89229 22.5 1.59229 18 1.59229C13.5 1.59229 10 4.89229 10 9.59229V13.5923C10 18.2923 13.5 21.5923 18 21.5923Z" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M24 25.3923C21.8 24.8923 19.6 24.5923 18 24.5923C12.4 24.5923 1 27.4923 1 33.5923V41.5923" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M36 43.5923C42.0751 43.5923 47 38.6674 47 32.5923C47 26.5172 42.0751 21.5923 36 21.5923C29.9249 21.5923 25 26.5172 25 32.5923C25 38.6674 29.9249 43.5923 36 43.5923Z" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M36 24.5923V32.5923L40 35.5923" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                                  </svg>

                              </div>
                          </div>
                      </div>
                  </CardBody>
              </Card>
          </Col>
          <Col xl={3} md={4} sm={6} xs={12}>
              <Card className="blog-stats-wid">
                  <CardBody>
                      <div className="d-flex flex-wrap">
                          <div className="me-3">
                              <p className="text-muted mb-2">Number of deactive users</p>
                              <h5 className="mb-0">{data.unActiveUsers}</h5>
                          </div>

                          <div className="avatar-sm ms-auto">
                              <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                  <svg width="30" height="30" viewBox="0 0 48 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M18 21.5923C22.5 21.5923 26 18.2923 26 13.5923V9.59229C26 4.89229 22.5 1.59229 18 1.59229C13.5 1.59229 10 4.89229 10 9.59229V13.5923C10 18.2923 13.5 21.5923 18 21.5923Z" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M24 25.3923C21.8 24.8923 19.6 24.5923 18 24.5923C12.4 24.5923 1 27.4923 1 33.5923V41.5923" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M36 43.5923C42.0751 43.5923 47 38.6674 47 32.5923C47 26.5172 42.0751 21.5923 36 21.5923C29.9249 21.5923 25 26.5172 25 32.5923C25 38.6674 29.9249 43.5923 36 43.5923Z" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M40.2422 28.3496L31.7569 36.8349" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M40.2431 36.8349L31.7578 28.3496" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                                  </svg>
                              </div>
                          </div>
                      </div>
                  </CardBody>
              </Card>
          </Col>
          <Col xl={3} md={4} sm={6} xs={12}>
              <Card className="blog-stats-wid">
                  <CardBody>
                      <div className="d-flex flex-wrap">
                          <div className="me-3">
                              <p className="text-muted mb-2">Number of toys</p>
                              <h5 className="mb-0">{data.toysCount}</h5>
                          </div>

                          <div className="avatar-sm ms-auto">
                              <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                  <svg width="30" height="30" viewBox="0 0 39 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M4 40.5923V37.5923C4 36.4923 4.9 35.5923 6 35.5923H10" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M19 35.5923H23C24.1 35.5923 25 36.4923 25 37.5923V40.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M12 40.5923H3C1.9 40.5923 1 41.4923 1 42.5923V46.5923H28V42.5923C28 41.4923 27.1 40.5923 26 40.5923H21" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M19 16.5923V21.5923L25 27.5923L22 30.5923V35.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M21 6.59229L28 13.5923V16.5923L25 19.5923L22 16.5923H17C15.1 16.5923 13 15.4923 13 13.5923V12.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M7 35.5923V25.5923L4 22.5923V13.5923C4 5.69229 10.8 1.59229 17 1.59229C19.6 1.59229 23.3 2.99229 25 4.59229L22 7.59229" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M25 23.5923C26 21.7923 28 20.5923 30.2 20.5923C33.5 20.5923 36.2 23.2923 36.2 26.5923C36.2 28.6923 35.1 30.4923 33.5 31.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M28 31.5923H36C36.6 31.5923 37 31.9923 37 32.5923V34.5923C37 35.1923 36.6 35.5923 36 35.5923H31" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M30 46.5923H38V44.5923C38 43.4923 37.1 42.5923 36 42.5923H32" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M35 35.5923V42.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                  </svg>
                              </div>
                          </div>
                      </div>
                  </CardBody>
              </Card>
          </Col>
          <Col xl={3} md={4} sm={6} xs={12}>
              <Card className="blog-stats-wid">
                  <CardBody>
                      <div className="d-flex flex-wrap">
                          <div className="me-3">
                              <p className="text-muted mb-2">Pending request toys</p>
                              <h5 className="mb-0">{data.pendingToysCount}</h5>
                          </div>

                          <div className="avatar-sm ms-auto">
                              <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                  <svg width="28" height="28" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M36 40.5923H7C3.7 40.5923 1 37.8923 1 34.5923V7.59229C1 4.29229 3.7 1.59229 7 1.59229H36C39.3 1.59229 42 4.29229 42 7.59229V34.5923C42 37.8923 39.3 40.5923 36 40.5923Z" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M15 11.0923C15 12.9923 13.4 14.5923 11.5 14.5923C9.6 14.5923 8 12.9923 8 11.0923C8 9.19229 9.6 7.59229 11.5 7.59229C13.4 7.59229 15 9.19229 15 11.0923Z" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M15 31.0923C15 32.9923 13.4 34.5923 11.5 34.5923C9.6 34.5923 8 32.9923 8 31.0923C8 29.1923 9.6 27.5923 11.5 27.5923C13.4 27.5923 15 29.1923 15 31.0923Z" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M35 11.0923C35 12.9923 33.4 14.5923 31.5 14.5923C29.6 14.5923 28 12.9923 28 11.0923C28 9.19229 29.6 7.59229 31.5 7.59229C33.4 7.59229 35 9.19229 35 11.0923Z" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M35 31.0923C35 32.9923 33.4 34.5923 31.5 34.5923C29.6 34.5923 28 32.9923 28 31.0923C28 29.1923 29.6 27.5923 31.5 27.5923C33.4 27.5923 35 29.1923 35 31.0923Z" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M25 21.0923C25 22.9923 23.4 24.5923 21.5 24.5923C19.6 24.5923 18 22.9923 18 21.0923C18 19.1923 19.6 17.5923 21.5 17.5923C23.4 17.5923 25 19.1923 25 21.0923Z" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                                  </svg>
                              </div>
                          </div>
                      </div>
                  </CardBody>
              </Card>
          </Col>
          <Col xl={3} md={4} sm={6} xs={12}>
              <Card className="blog-stats-wid">
                  <CardBody>
                      <div className="d-flex flex-wrap">
                          <div className="me-3">
                              <p className="text-muted mb-2">Number of active toys</p>
                              <h5 className="mb-0">{data.activeToysCount}</h5>
                          </div>

                          <div className="avatar-sm ms-auto">
                              <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                  <svg width="30" height="30" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M35.0144 1.59229C33.4144 4.79229 24.1144 7.09229 26.4144 14.2923C27.8144 18.5923 33.6144 18.6923 35.0144 15.4923C36.4144 18.5923 42.2144 18.4923 43.6144 14.2923C45.9144 7.09229 36.6144 4.79229 35.0144 1.59229Z" stroke="black"strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                      <path d="M11.0148 5.69218C10.1148 1.39218 3.01485 1.39218 1.41485 6.49218C-1.18515 14.4922 9.21485 17.0922 11.0148 20.6922C12.8148 17.0922 23.2149 14.4922 20.6149 6.49218C19.0149 1.39218 11.9148 1.39218 11.0148 5.69218Z" stroke="black"strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                      <path d="M11.0156 25.6924C9.61563 28.8924 4.61562 33.4924 1.01562 34.6924C4.61562 35.8924 9.71563 40.4924 11.0156 43.6924C12.3156 40.4924 17.4156 35.8924 21.0156 34.6924C17.4156 33.3924 12.3156 28.8924 11.0156 25.6924Z" stroke="black"strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                      <path d="M32.0156 43.6924H38.0156" stroke="black"strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                      <path d="M32.0156 20.6924H38.0156" stroke="black"strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                      <path d="M39.4145 31.8923C38.9145 31.8923 38.4145 31.9923 38.0145 32.0923C38.6145 31.3923 39.0145 30.4923 39.0145 29.4923C39.0145 27.3923 37.2145 25.5923 34.9145 25.5923C32.6145 25.5923 30.8145 27.2923 30.8145 29.4923C30.8145 30.4923 31.2145 31.3923 31.8145 32.0923C31.4145 31.9923 30.9145 31.8923 30.4145 31.8923C27.9145 31.8923 25.8145 33.8923 25.8145 36.2923C25.8145 38.6923 27.9145 40.6923 30.4145 40.6923C32.3145 40.6923 34.1145 39.5923 34.8145 38.0923C35.5145 39.5923 37.4145 40.6923 39.2145 40.6923C41.7145 40.6923 43.8145 38.6923 43.8145 36.2923C44.0145 33.8923 41.9145 31.8923 39.4145 31.8923Z" stroke="black"strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                  </svg>
                              </div>
                          </div>
                      </div>
                  </CardBody>
              </Card>
          </Col>
          <Col xl={3} md={4} sm={6} xs={12}>
              <Card className="blog-stats-wid">
                  <CardBody>
                      <div className="d-flex flex-wrap">
                          <div className="me-3">
                              <p className="text-muted mb-2">Number active toys - chat</p>
                              <h5 className="mb-0">{data.activeChatToysCount}</h5>
                          </div>

                          <div className="avatar-sm ms-auto">
                              <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                  <svg width="30" height="30" viewBox="0 0 46 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <g clipPath="url(#clip0_478_226)">
                                          <path d="M40.9185 3.75972L23.4317 21.3048L21.9453 26.6869L27.3176 25.1908L44.8044 7.6554C44.9768 7.39437 45.0436 7.0776 44.9913 6.76919C44.939 6.46078 44.7715 6.18375 44.5227 5.99415L42.5797 4.05117C42.0357 3.50713 41.2974 3.38084 40.9185 3.75972Z" stroke="black" strokeWidth="2" strokeLinejoin="round"/>
                                          <path d="M42.7453 9.71481L38.8594 5.82886" stroke="black" strokeWidth="2" strokeLinejoin="round"/>
                                          <path d="M34.0013 7.77186V5.82888C34.0013 4.54061 33.4896 3.30509 32.5786 2.39414C31.6677 1.4832 30.4322 0.971436 29.1439 0.971436H5.82815C4.53987 0.971436 3.30436 1.4832 2.39341 2.39414C1.48246 3.30509 0.970703 4.54061 0.970703 5.82888V26.2301C0.970703 27.5184 1.48246 28.7539 2.39341 29.6649C3.30436 30.5758 4.53987 31.0876 5.82815 31.0876H8.74261V36.9165C8.74261 37.1204 8.80676 37.3192 8.92598 37.4846C9.04521 37.65 9.21346 37.7737 9.4069 37.8382C9.60033 37.9026 9.80915 37.9046 10.0038 37.8438C10.1984 37.783 10.369 37.6625 10.4913 37.4994L17.486 31.0876H29.1439C30.4322 31.0876 31.6677 30.5758 32.5786 29.6649C33.4896 28.7539 34.0013 27.5184 34.0013 26.2301V22.3442" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                          <path d="M8.74414 9.71484H26.2309" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                          <path d="M8.74414 13.6008H26.2309" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                          <path d="M8.74414 17.4868H18.459" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      </g>
                                      <defs>
                                          <clipPath id="clip0_478_226">
                                              <rect width="46" height="38.8596" fill="white"/>
                                          </clipPath>
                                      </defs>
                                  </svg>
                              </div>
                          </div>
                      </div>
                  </CardBody>
              </Card>
          </Col>

          <Col xl={3} md={4} sm={6} xs={12}>
              <Card className="blog-stats-wid">
                  <CardBody>
                      <div className="d-flex flex-wrap">
                          <div className="me-3">
                              <p className="text-muted mb-2">Number of history toys</p>
                              <h5 className="mb-0">{data.historyToysCount}</h5>
                          </div>

                          <div className="avatar-sm ms-auto">
                              <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                  <svg width="30" height="30" viewBox="0 0 48 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M10.5 16.2013L16 26.6013L8.6 35.3013M19 14.5013L9.9 13.6013C9.5 13.6013 9 13.8013 8.7 14.1013L1.4 24.5013C1 24.9013 0.9 25.5013 1.1 26.0013L6.3 37.5013C6.6 38.1013 7 38.4013 7.5 38.5013L20.3 39.6013C20.8 39.6013 21.2 39.4013 21.5 39.0013L27.9 30.0013L19 14.5013Z" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M46.2 9.6013C46.1 9.5013 46.1 9.5013 46 9.4013L45.9 9.3013L35.4 1.8013C35.1 1.6013 34.5 1.5013 34.1 1.7013L22.6 7.0013C22.1 7.1013 21.7 7.6013 21.6 8.2013L20.5 20.8013C20.5 20.9013 20.5 20.9013 20.5 21.0013C20.5 21.1013 20.5 21.2013 20.5 21.2013C20.5 21.3013 20.5 21.4013 20.6 21.5013C20.6 21.6013 20.6 21.6013 20.6 21.7013C20.6 21.7013 20.6 21.7013 20.6 21.8013C20.6 21.9013 20.7 21.9013 20.8 22.0013C20.9 22.1013 20.9 22.1013 21 22.2013C21 22.2013 21 22.2013 21.1 22.3013L31.5 29.6013C31.9 29.9013 32.2 30.1013 32.6 29.9013L44.4 24.5013C45 24.2013 45.3 23.9013 45.3 23.3013L46.4 10.5013C46.4 10.4013 46.4 10.4013 46.4 10.3013C46.4 10.2013 46.4 10.1013 46.4 10.0013C46.4 9.9013 46.3 9.8013 46.3 9.8013C46.3 9.7013 46.2 9.7013 46.2 9.6013Z" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M34.1992 4.30127L32.9992 15.6013L23.1992 19.8013" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M33 15.6013L42.3 22.1013" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M16 26.6013H22" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M9 20.6013V22.6013" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M7 28.6013V30.6013" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M27 9.60132V11.6013" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M33 19.6013V21.6013" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M37 22.6013V24.6013" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M33 24.6013V26.6013" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M29 21.6013V23.6013" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M37 6.60132V8.60132" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M42 15.6013V17.6013" stroke="black"strokeWidth="2" strokeMiterlimit="10"/>
                                  </svg>
                              </div>
                          </div>
                      </div>
                  </CardBody>
              </Card>
          </Col>
          <Col xl={3} md={4} sm={6} xs={12}>
              <Card className="blog-stats-wid">
                  <CardBody>
                      <div className="d-flex flex-wrap">
                          <div className="me-3">
                              <p className="text-muted mb-2">Number of swap toys</p>
                              <h5 className="mb-0">{data.toysSwapCount}</h5>
                          </div>

                          <div className="avatar-sm ms-auto">
                              <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                  <svg width="30" height="30" viewBox="0 0 46 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M8.85304 27.2668C6.28658 27.2668 4.36523 25.1319 4.36523 22.4807V20.89C4.36523 18.2528 6.28658 16.104 8.85304 16.104C11.4195 16.104 13.3408 18.2389 13.3408 20.89V22.4807C13.3408 25.1319 11.4195 27.2668 8.85304 27.2668Z" stroke="black"strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
                                      <path d="M1 32.848V31.7317C1 28.6759 6.0628 27.2666 8.85366 27.2666C11.6445 27.2666 16.7073 28.648 16.7073 31.7038V32.848" stroke="black"strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
                                      <path d="M36.9019 27.2668C34.3354 27.2668 32.4141 25.1319 32.4141 22.4807V20.89C32.4141 18.2528 34.3354 16.104 36.9019 16.104C39.4683 16.104 41.3897 18.2389 41.3897 20.89V22.4807C41.3897 25.1319 39.4683 27.2668 36.9019 27.2668Z" stroke="black"strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
                                      <path d="M29.0488 32.848V31.7317C29.0488 28.6759 34.1116 27.2666 36.9025 27.2666C39.6933 27.2666 44.7561 28.648 44.7561 31.7038V32.848" stroke="black"strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
                                      <path d="M8.25 10.5225C11.3634 5.15042 16.7348 1.59229 22.8354 1.59229C28.9079 1.59229 34.2512 5.10856 37.3787 10.4388" stroke="black"strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
                                      <path d="M37.9127 37.3132C34.8975 43.3272 29.2737 47.3597 22.8506 47.3597C16.4414 47.3597 10.8317 43.3411 7.81641 37.369" stroke="black"strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
                                      <path d="M38.9913 41.9458L37.9114 37.3132L33.3535 38.3179" stroke="black"strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                      <path d="M7.39453 5.84814L8.25002 10.5226L12.864 9.72721" stroke="black"strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                  </svg>
                              </div>
                          </div>
                      </div>
                  </CardBody>
              </Card>
          </Col>
          <Col xl={3} md={4} sm={6} xs={12}>
              <Card className="blog-stats-wid">
                  <CardBody>
                      <div className="d-flex flex-wrap">
                          <div className="me-3">
                              <p className="text-muted mb-2">Giveaway toys</p>
                              <h5 className="mb-0">{data.toysFreeCount}</h5>
                          </div>

                          <div className="avatar-sm ms-auto">
                              <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                  <svg width="30" height="30  " viewBox="0 0 48 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M18 35.5923V32.8923C18 32.8923 21 31.2923 21 27.5923V24.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M11 10.5923L4 16.0923C2.8 17.1923 2 18.7923 2 20.4923V35.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M1 40.5923V35.5923H19V40.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M4 39.5923H6" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M11 18.5923V1.59229H47V23.5923H14" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinejoin="round"/>
                                      <path d="M5.69922 23.3924C5.69922 23.3924 13.0992 16.1924 14.9992 14.2924C16.8992 12.3924 19.0992 16.9924 17.2992 19.6924C16.4992 20.7924 13.1992 24.4924 13.1992 24.4924C14.9992 28.5924 12.2992 31.8924 12.2992 31.8924" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M22 19.5923V14.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M36 19.5923V14.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M26 19.5923V14.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M29 19.5923V14.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M33 19.5923V14.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M26 6.59229H32" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M22 9.59229H36" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                  </svg>
                              </div>
                          </div>
                      </div>
                  </CardBody>
              </Card>
          </Col>
          <Col xl={3} md={4} sm={6} xs={12}>
              <Card className="blog-stats-wid">
                  <CardBody>
                      <div className="d-flex flex-wrap">
                          <div className="me-3">
                              <p className="text-muted mb-2">Number of paid toys</p>
                              <h5 className="mb-0">{data.toysSaleCount}</h5>
                          </div>

                          <div className="avatar-sm ms-auto">
                              <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                  <svg width="30" height="30" viewBox="0 0 47 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M42 42.5923H5C2.8 42.5923 1 40.7923 1 38.5923V22.5923C1 20.3923 2.8 18.5923 5 18.5923H42C44.2 18.5923 46 20.3923 46 22.5923V38.5923C46 40.7923 44.2 42.5923 42 42.5923Z" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M27 6.59229L36 15.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M12 15.5923L21 6.59229" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M24 7.59229C25.6569 7.59229 27 6.24914 27 4.59229C27 2.93543 25.6569 1.59229 24 1.59229C22.3431 1.59229 21 2.93543 21 4.59229C21 6.24914 22.3431 7.59229 24 7.59229Z" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M8 33.5923V34.5923C8 35.1923 8.4 35.5923 9 35.5923H11C11.6 35.5923 12 35.1923 12 34.5923V32.5923C12 32.1923 11.8 31.8923 11.4 31.6923L8.5 30.2923C8.2 30.0923 8 29.6923 8 29.2923V26.5923C8 25.9923 8.4 25.5923 9 25.5923H11C11.6 25.5923 12 25.9923 12 26.5923V27.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M16 36.5923V27.5923C16 26.4923 16.9 25.5923 18 25.5923H19C20.1 25.5923 21 26.4923 21 27.5923V36.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M16 31.5923H21" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M25 24.5923V35.5923H29" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M36 35.5923H32V25.5923H36" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M32 30.5923H36" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M39 24.5923V32.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M39 36.5923V34.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                  </svg>
                              </div>
                          </div>
                      </div>
                  </CardBody>
              </Card>
          </Col>
          <Col xl={3} md={4} sm={6} xs={12}>
              <Card className="blog-stats-wid">
                  <CardBody>
                      <div className="d-flex flex-wrap">
                          <div className="me-3">
                              <p className="text-muted mb-2">Number of reviews</p>
                              <h5 className="mb-0">{data.reviews_count}</h5>
                          </div>

                          <div className="avatar-sm ms-auto">
                              <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                  <svg width="30" height="30" viewBox="0 0 52 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M25.9173 12.324C23.4392 12.324 21.584 10.2715 21.584 7.72277V6.1935C21.584 3.64472 23.4392 1.59229 25.9173 1.59229C28.3954 1.59229 30.2507 3.64472 30.2507 6.1935V7.72277C30.2507 10.2715 28.3954 12.324 25.9173 12.324Z" stroke="black"strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
                                      <path d="M18.334 17.6898V16.6167C18.334 13.6789 23.2225 12.324 25.9173 12.324C28.6121 12.324 33.5006 13.652 33.5006 16.5898V17.6898" stroke="black"strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
                                      <path d="M10.7493 18.763C8.27122 18.763 6.41602 16.7105 6.41602 14.1617V12.6325C6.41602 10.0837 8.27122 8.03125 10.7493 8.03125C13.2275 8.03125 15.0827 10.0837 15.0827 12.6325V14.1617C15.0827 16.7105 13.2275 18.763 10.7493 18.763Z" stroke="black"strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
                                      <path d="M3.16602 24.1288V23.0556C3.16602 20.1178 8.05456 18.7629 10.7493 18.7629C13.4441 18.7629 18.3327 20.091 18.3327 23.0288V24.1288" stroke="black"strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
                                      <path d="M41.0833 18.763C38.6052 18.763 36.75 16.7105 36.75 14.1617V12.6325C36.75 10.0971 38.6052 8.03125 41.0833 8.03125C43.5615 8.03125 45.4167 10.0837 45.4167 12.6325V14.1617C45.4167 16.7105 43.5615 18.763 41.0833 18.763Z" stroke="black"strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
                                      <path d="M33.5 24.1288V23.0556C33.5 20.1178 38.3885 18.7629 41.0833 18.7629C43.7781 18.7629 48.6667 20.091 48.6667 23.0288V24.1288" stroke="black"strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
                                      <path d="M25.9443 21.1509L29.6411 28.824L37.8339 29.6692L31.9297 35.7728L33.2974 43.446L25.9443 40.0655L18.5911 43.446L19.9589 35.7728L14.0547 29.6692L22.2474 28.824L25.9443 21.1509Z" stroke="black"strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                      <path d="M14.0948 34.7667L15.0833 40.2265L9.70729 37.8118L4.33125 40.2265L5.31979 34.7667L1 30.4204L6.99896 29.8301L9.70729 24.3704L11.0885 27.1472" stroke="black"strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                      <path d="M37.7385 34.7667L36.75 40.2265L42.126 37.8118L47.5021 40.2265L46.5135 34.7667L50.8333 30.4204L44.8344 29.8301L42.126 24.3704L40.7448 27.1472" stroke="black"strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                  </svg>
                              </div>
                          </div>
                      </div>
                  </CardBody>
              </Card>
          </Col>
          <Col xl={3} md={4} sm={6} xs={12}>
              <Card className="blog-stats-wid">
                  <CardBody>
                      <div className="d-flex flex-wrap">
                          <div className="me-3">
                              <p className="text-muted mb-2">Number of reports</p>
                              <h5 className="mb-0">{data.reportsCount}</h5>
                          </div>

                          <div className="avatar-sm ms-auto">
                              <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                  <svg width="30" height="30" viewBox="0 0 42 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M40.5606 46.5924H11.3496V8.31592H36.5315L40.5606 12.345V46.5924Z" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M7.6732 43.0039L5.82233 43.2431L1 5.26884L29.9843 1.59229L30.4376 5.21847" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M34.5176 11.3376V14.3595H37.5394" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M20.4144 25.4396C22.0833 25.4396 23.4362 23.8612 23.4362 21.9141C23.4362 19.9671 22.0833 18.3887 20.4144 18.3887C18.7455 18.3887 17.3926 19.9671 17.3926 21.9141C17.3926 23.8612 18.7455 25.4396 20.4144 25.4396Z" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M16.3867 28.4613C16.3867 26.7867 18.1872 25.4395 20.4158 25.4395C22.6444 25.4395 24.4449 26.7867 24.4449 28.4613" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M28.4727 20.4031H35.5236" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M28.4727 24.4321H35.5236" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M28.4727 28.4614H35.5236" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M16.3867 32.4905H35.5249" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M16.3867 36.5195H26.4595" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                  </svg>

                              </div>
                          </div>
                      </div>
                  </CardBody>
              </Card>
          </Col>

          <Col xl={3} md={4} sm={6} xs={12}>
              <Card className="blog-stats-wid">
                  <CardBody>
                      <div className="d-flex flex-wrap">
                          <div className="me-3">
                              <p className="text-muted mb-2">Number of suggestions</p>
                              <h5 className="mb-0">{data.suggestionsCount}</h5>
                          </div>
                          <div className="avatar-sm ms-auto">
                              <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                  <svg width="28" height="28" viewBox="0 0 53 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M5.4348 25.9837H3.2174C1.99783 25.9837 1 24.9858 1 23.7663V3.80968C1 2.59011 1.99783 1.59229 3.2174 1.59229H37.5871C38.8066 1.59229 39.8045 2.59011 39.8045 3.80968V23.7663C39.8045 24.9997 38.8066 25.9837 37.5871 25.9837H34.261V32.6359C34.261 33.6614 32.986 34.1326 32.3208 33.3704L25.3914 25.9837H18.7392" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M44.2395 41.5054C41.7033 41.5054 39.8047 39.3851 39.8047 36.7519V35.172C39.8047 32.5527 41.7033 30.4185 44.2395 30.4185C46.7756 30.4185 48.6743 32.5388 48.6743 35.172V36.7519C48.6743 39.3851 46.7756 41.5054 44.2395 41.5054Z" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M36.4785 45.9402C36.4785 42.9051 41.4815 41.5054 44.2394 41.5054C46.9973 41.5054 52.0003 42.8774 52.0003 45.9124V47.0489" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M27.6086 47.0489C25.0725 47.0489 23.1738 44.9285 23.1738 42.2954V40.7155C23.1738 38.0962 25.0725 35.9619 27.6086 35.9619C30.1448 35.9619 32.0434 38.0823 32.0434 40.7155V42.2954C32.0434 44.9285 30.1448 47.0489 27.6086 47.0489Z" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M19.8477 52.5923V51.4836C19.8477 48.4486 24.8507 47.0488 27.6086 47.0488C30.3664 47.0488 35.3694 48.4208 35.3694 51.4559V52.5923" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M12.0871 37.0706C9.55099 37.0706 7.65234 34.9502 7.65234 32.3171V30.7372C7.65234 28.1179 9.55099 25.9836 12.0871 25.9836C14.6233 25.9836 16.5219 28.104 16.5219 30.7372V32.3171C16.5219 34.9502 14.6233 37.0706 12.0871 37.0706Z" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M4.32617 42.6141V41.5054C4.32617 38.4703 9.32918 37.0706 12.0871 37.0706C14.845 37.0706 19.848 38.4426 19.848 41.4776V42.6141" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M14.3047 13.7878H16.5221" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M19.8477 13.7878H22.0651" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                      <path d="M25.3906 13.7878H27.608" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                  </svg>
                              </div>
                          </div>
                      </div>
                  </CardBody>
              </Card>
          </Col>



          <Col xl={3} md={4} sm={6} xs={12}>
              <Card className="blog-stats-wid">
                  <CardBody>
                      <div className="d-flex flex-wrap">
                          <div className="me-3">
                              <p className="text-muted mb-2">Number of Feedback</p>
                              <h5 className="mb-0">{data.feedBacksCount}</h5>
                          </div>

                          <div className="avatar-sm ms-auto">
                              <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                  <svg width="30" height="30" viewBox="0 0 44 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <g clipPath="url(#clip0_478_211)">
                                          <path d="M37.0341 13.1317L36.8798 12.9678C36.041 12.0905 35.8096 10.9528 36.3495 10.4418L38.6635 8.26276C39.0846 7.88396 39.6386 7.6875 40.2043 7.71642C40.7699 7.74533 41.3011 7.99725 41.6813 8.41701L41.8452 8.58092C42.245 8.98522 42.4692 9.53089 42.4692 10.0995C42.4692 10.6681 42.245 11.2137 41.8452 11.618L39.5409 13.7874C38.9624 14.308 37.8729 14.0091 37.0341 13.1317Z" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                          <path d="M17.75 45.3154C17.75 45.3154 19.6301 43.387 20.6425 42.4229C28.5004 43.0014 31.4314 37.5539 32.2124 36.6379" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                          <path d="M37.6508 31.181L37.4869 31.046C36.6481 30.3229 36.3396 29.3009 36.7927 28.7802L38.721 26.553C38.8897 26.3539 39.0971 26.1911 39.3305 26.0744C39.564 25.9578 39.8186 25.8897 40.0792 25.8744C40.3397 25.8591 40.6006 25.8968 40.8461 25.9853C41.0916 26.0737 41.3166 26.2111 41.5075 26.3891L41.6617 26.5241C42.0627 26.8615 42.314 27.3437 42.3609 27.8657C42.4079 28.3876 42.2467 28.9069 41.9124 29.3105L39.9841 31.5281C39.5309 32.0583 38.48 31.9041 37.6508 31.181Z" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                          <path d="M18.7149 38.5664C19.5399 36.2972 19.8025 33.8618 19.4801 31.469C19.1576 29.0761 18.2599 26.797 16.8637 24.8271C17.6198 22.5949 18.0864 20.2748 18.2521 17.9238C18.3356 16.4834 17.8936 15.0617 17.0083 13.9225C16.8313 13.6853 16.5947 13.4992 16.3225 13.383C16.0503 13.2668 15.7522 13.2247 15.4585 13.2609C15.1648 13.2971 14.8858 13.4104 14.65 13.5893C14.4142 13.7681 14.2299 14.0062 14.1158 14.2792C12.5346 19.3218 12.2454 20.7005 11.2234 23.2266C10.1339 26.0613 10.7606 30.0432 10.2592 31.2002C10.1531 31.4316 8.08984 34.7098 8.08984 34.7098" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                          <path d="M37.6605 25.1164L37.4966 24.9814C36.6482 24.2487 36.33 23.2074 36.7928 22.6771L38.7211 20.4113C39.0763 20.0154 39.5737 19.776 40.1047 19.7453C40.6357 19.7146 41.1573 19.8951 41.5557 20.2474L41.7197 20.3824C42.1281 20.7255 42.3839 21.2165 42.4309 21.7479C42.4779 22.2792 42.3122 22.8076 41.9703 23.217L40.042 25.4731C39.5792 26.0323 38.509 25.8492 37.6605 25.1164Z" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                          <path d="M37.6605 19.2156L37.4966 19.071C36.6482 18.3383 36.33 17.3066 36.7928 16.7667L38.7211 14.5009C39.0763 14.105 39.5737 13.8656 40.1047 13.8349C40.6357 13.8042 41.1573 13.9847 41.5557 14.337L41.7197 14.472C42.1281 14.8151 42.3839 15.3061 42.4309 15.8375C42.4779 16.3688 42.3122 16.8972 41.9703 17.3066L40.042 19.5627C39.5792 20.1027 38.509 19.9388 37.6605 19.2156Z" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                          <path d="M25.4629 33.7456H28.3554" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                          <path d="M37.9979 8.67738V3.85659C37.9979 3.08945 37.6932 2.35374 37.1507 1.8113C36.6083 1.26885 35.8726 0.964111 35.1054 0.964111H18.7147C17.9476 0.964111 17.2119 1.26885 16.6694 1.8113C16.127 2.35374 15.8223 3.08945 15.8223 3.85659V13.4982" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                          <path d="M18.7148 36.6379H35.1055C35.8727 36.6379 36.6084 36.3332 37.1508 35.7907C37.6933 35.2483 37.998 34.5126 37.998 33.7455V31.8171" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                          <path d="M18.7142 45.3156L6.18017 32.7815L1.35938 37.6023L9.07264 45.3156" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                          <path d="M6.17969 37.6023L7.14385 38.5665" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                          <path d="M19.6777 30.853H37.0326" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                          <path d="M15.8223 5.78491H37.9979" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
                                          <path d="M27.3915 11.1553L29.3873 14.6262L33.0994 15.3012L30.6118 18.6082L30.9204 22.2045L27.3915 21.0957L23.8724 22.2527L24.1809 18.5986L21.6934 15.3012L25.4054 14.6262L27.3915 11.1553Z" stroke="black" strokeWidth="2" strokeLinejoin="round"/>
                                      </g>
                                      <defs>
                                          <clipPath id="clip0_478_211">
                                              <rect width="43.445" height="46" fill="white"/>
                                          </clipPath>
                                      </defs>
                                  </svg>
                              </div>
                          </div>
                      </div>
                  </CardBody>
              </Card>
          </Col>
      </Row>
    )
}

export default Cards