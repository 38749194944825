import {
  GET_TITLE
} from "./actionTypes"


export const setTitle = data => ({
  type: GET_TITLE,
  payload: data,
})

