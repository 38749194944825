import axios from "axios";
import * as url from './urlHelpers'

const axiosApi = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    "Content-Type": "application/json",
  },
});

// axiosApi.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
);

export async function get(url) {
  return await axiosApi.get(url, {
    headers: {
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`,
    }
  }).then(response => response.data);
}

export async function post(url, data) {
  return axiosApi
    .post(url, { ...data }, {
      headers: {
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`,
      }
    })
    .then(response => response.data);
}

export async function put(url, data) {
  return axiosApi
    .put(url, { ...data }, {
      headers: {
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`,
      }
    })
    .then(response => response.data);
}

export async function del(url) {
  return await axiosApi
    .delete(url, {
      headers: {
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`,
      }
    })
    .then(response => response.data);
}



