import React, { useEffect, useState } from "react"
import EToyApp from "./Components/EToyApp"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { setTitle } from "../../../store/title/actions"
import axios from "axios"
import { updateNotifications } from "../../../store/notifications/actions"
import { Col, Row } from "reactstrap"

function EToyAppToys(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector(state => state.UserData.user)
  const countries = useSelector(state => state.MainData.countries)
  const notifications = useSelector(state => state.Notifications.toyCount)
  const users = useSelector(state => state.MainData.users)
  // const [active, setActive] = useState([])
  const [admin, setAdmin] = useState([])

  function userChanges(user, type) {
    if (type === "delactive") {
      setAdmin(users => users.filter(u => u.id !== user.id))
    } else if (type === "add") {
      setAdmin(users => [user].concat(users))
    }
  }

  function update(toy, type) {
    if (type === "admin") {
      setAdmin(toys => toys.map(t => {
        if (t.id === toy.id) {
          return toy
        }
        return t
      }))
    }
  }

  useEffect(() => {
    if (user && user.role === 2) {
      history.push("/")
      return
    }
  }, [user])

  useEffect(() => {
    dispatch(setTitle("Admin Toys"))
    axios.get(`${process.env.REACT_APP_API}/admin/toys`, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
      }
    })
      .then(res => {
        setAdmin(res.data.data.adminToys)
        console.log(admin)
        // setToys(res.data.data)
      })
    axios.post(`${process.env.REACT_APP_API}/admin/setUnread`, { key: "2" }, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
      }
    }).then(res => {
      dispatch(updateNotifications({ unreadToyCount: 0 }))
    })
  }, [])

  useEffect(() => {
    if (notifications) {
      axios.get(`${process.env.REACT_APP_API}/admin/toys`, {
        headers: {
          "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
        }
      })
        .then(res => {
          setAdmin(res.data.data.adminToys)
          // setToys(res.data.data)
        })
      axios.post(`${process.env.REACT_APP_API}/admin/setUnread`, { key: "2" }, {
        headers: {
          "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
        }
      }).then(res => {
        dispatch(updateNotifications({ unreadToyCount: 0 }))
      })
    }
  }, [notifications])


  return (
    <>
      {/*<Row md="12 mt-5">*/}
      {/*  <Col md="12 mt-5">*/}
          <EToyApp userChanges={userChanges} active={admin} update={update}/>
      {/*  </Col>*/}
      {/*</Row>*/}
    </>
  )
}

export default EToyAppToys