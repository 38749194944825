/* eslint-disable react/prop-types */
import axios from "axios"
import React, { useState, useRef, useEffect } from "react"
import { Col, Form, FormFeedback, Input, Label, Row } from "reactstrap"
import PulseLoader from "react-spinners/PulseLoader"
import ReactTagInput from "@pathofdev/react-tag-input"
import "@pathofdev/react-tag-input/build/index.css"
import { Link } from "react-router-dom"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

import "./specification.scss"
import Select from "react-select"
import { useSelector } from "react-redux"



const UpdateForm = (props) => {
  const categories = useSelector(state => state.MainData.categories)
  const countries = useSelector(state => state.MainData.countries)




  let p = props
  const [isOpen, setisOpen] = useState(false)
  const [name_en, setNameEn] = useState("")
  const [name_ar, setNameAr] = useState("")
  const [description_en, setDescriptionEn] = useState("")
  const [description_ar, setDescriptionAr] = useState("")
  const [age, setAge] = useState([])
  const [status, setStatus] = useState("")
  const [gender, setGender] = useState("")
  const [deal, setDeal] = useState("")
  const [price, setPrice] = useState("")
  const [country, setCountry] = useState("")
  const [category, setCategory] = useState([])

  const [pickup_address_en, setPickEn] = useState("")
  const [pickup_address_ar, setPickAr] = useState("")
  const [image, setImage] = useState(null)
  const [loading, setLoading] = useState(false)
  const [genderboth, setboth] = useState(false)


  const imageRef = useRef(null)


  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size)
      })
    )
    setImage(files[0])
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  function close() {
    document.getElementById("toyaddForm").scrollTo(0,0)
    props.bindData()
    p.clickHandle ? p.clickHandle() : p.editCoupon()
  }


  function submitHandler() {
    setLoading(true)
    const formData = new FormData()

    let data = {
      id: p.toy.id,
      name_en:name_en,
      name_ar:name_ar,
      description_en:description_en,
      description_ar: description_ar,
      gender:gender,
      status:status,
      deal:deal,
      pickup_address_en:pickup_address_en,
      pickup_address_ar:pickup_address_ar,
      age: age
    }
    for (let key in data) {
      formData.append(key, data[key])
    }
    if (image.name) {
      formData.append("image", image)
    }
    if (deal === "sale") {
      formData.append("price", price)
    }

    console.log(category);

    category.forEach((item, i) => {
      formData.append(`categories[${i}]`, item.value)
    });
    age.forEach((item, i) => {
      formData.append(`ages[${i}]`, item.value)
    });

    props.setLoading(true)

    axios.post(!props.admin ? `${process.env.REACT_APP_API}/admin/toys/admin-toys/update` : `${process.env.REACT_APP_API}/admin/toys/admin-toys/update`, formData, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`,
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        setLoading(false)
        close()

        if (res.data.error) {
          return
        }
        
        close()

        if (props.admin === false && props.update(res.data.data, "active")) {
          props.update(res.data.data, "active")
        } else if (props.admin === false && props.update(res.data.data, "chat"))
        setNameEn("")
        setNameAr("")
        setDescriptionEn("")
        setDescriptionAr("")
        setPickEn("")
        setPickAr("")
        setAge("")
        setCountry("")
        setCategory("")
        setDeal("")
        setGender("")
        setStatus("")
        setImage(null)
        setPrice("")
        close()
        document.getElementById("toyUpdateForm").reset()
        props.setLoading(false)
        props.setPageNo(1)
      })
  }


  useEffect(() => {
    if (p.toy) {
      setNameEn(p.toy.nameEn)
      setNameAr(p.toy.nameAr)
      setDescriptionEn(p.toy.descriptionEn || "")
      setDescriptionAr(p.toy.descriptionAr || "")
      setPickEn(p.toy.pickup_address_en || "")
      setPickAr(p.toy.pickup_address_ar || "")
      setCountry(p.toy.country)
      setDeal(p.toy.deal)
      setGender(p.toy.gender)
      setStatus(p.toy.status)
      setImage({ preview: p.toy.image })
      setPrice(p.toy.price || "")
      setboth(true)
    }
  }, [p.toy])


  return (
    <div className={`overlay_coup ${p.active ? "active" : ""}`}>
      {isOpen ? (
        <Lightbox
          mainSrc={image.preview}
          enableZoom={false}
          onCloseRequest={() => {
            setisOpen(!isOpen)
          }}
        />
      ) : null}
      <Form id="toyUpdateForm" onSubmit={(e) => e.preventDefault()} style={{ paddingBottom: "70px", height: "580px" }}>
        <div className="head_form">
          <div className="log fs-5">
            {/* <i className="fas fa-user fs-5"></i> */}
            Edit Toy
          </div>
          <button onClick={close}><i className="dripicons dripicons-cross" /></button>
        </div>
        <div id="toyaddForm" className="inputs__wrap ">
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">
                  Toy Title (English)
                </Label>
                <Input
                  id="title"
                  name="title"
                  type="text"
                  className="form-control input_cus"
                  placeholder="e.g.Name"
                  value={name_en}
                  onChange={e => setNameEn(e.target.value)}
                />
                
                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">
                  Toy Title (Arabic)
                </Label>
                <Input
                  id="title"
                  name="title"
                  type="text"
                  className="form-control input_cus"
                  placeholder="مثال. الاسم"
                  dir="rtl"
                  value={name_ar}
                  onChange={e => setNameAr(e.target.value)}
                />
                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">
                  Description (English)
                </Label>
                <Input
                  name="text"
                  type="textarea"
                  value={description_en}
                  onChange={e => setDescriptionEn(e.target.value)}
                />
                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">
                  Description (Arabic)
                </Label>
                <Input
                  name="text"
                  type="textarea"
                  value={description_ar}
                  onChange={e => setDescriptionAr(e.target.value)}
                  dir="rtl"
                />
                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
              </div>
            </Col>
          </Row>
          {props.admin ? null : <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">
                  Pickup address (English)
                </Label>
                <Input
                  name="text"
                  type="textarea"
                  value={pickup_address_en}
                  onChange={e => setPickEn(e.target.value)}
                />
                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
              </div>
            </Col>
          </Row>}
          {props.admin ? null : <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">
                  Pickup address (Arabic)
                </Label>
                <Input
                  name="text"
                  type="textarea"
                  value={pickup_address_ar}
                  onChange={e => setPickAr(e.target.value)}
                  dir="rtl"
                />
                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
              </div>
            </Col>
          </Row>}
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">
                  Age
                </Label>
                <Select
                  isMulti={true}
                  closeMenuOnSelect={false}
                  options={[
                    {label: "0 - 2",    value: "+0"},
                    {label: "2 - 4",    value: "+2"},
                    {label: "4 - 6",    value: "+4"},
                    {label: "6 - 8",    value: "+6"},
                    {label: "8 - 10",   value: "+8"},
                    {label: "above 10", value: "+10"},
                  ]}
                  
                  placeholder={age}
                  onChange={(e) => setAge(e)}
                  className="select2-selection"
                  style={{ height:"200px" }}
                />
                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
              </div>
            </Col>
          </Row>
          {props.admin ? null : <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">
                  Country
                </Label>
                <Select
                  options={countries ? countries.map(c => {
                    return { value: c.id, label: c.nameEn }
                  }) : null}
                  placeholder={country}
                  onChange={(e) => setCountry(e.value)}
                />
                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
              </div>
            </Col>
          </Row>}
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">
                  Toy category
                </Label>
                <Select
                  isMulti={true}
                  closeMenuOnSelect={false}
                  options={categories?.map(c => {
                    return {
                        label: `${c.nameEn}`, value:c.key
                      }

                    }).flat()}
                  
                  placeholder={category}
                  onChange={(e) => setCategory(e)}
                  className="select2-selection"
                  style={{ height:"200px" }}
                />
                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
              </div>
            </Col>
          </Row>
          {props.admin ? null : <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">
                  Status
                </Label>
                <select
                  className="form-select fs-5 invalid input_cus"
                  value={status}
                  onChange={e => setStatus(e.target.value)}
                >
                  <option>Select</option>
                  <option value="new">New</option>
                  <option value="used">Used</option>
                </select>
                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
              </div>
            </Col>
          </Row>}
          {props.admin ? null : <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">
                  Purpose
                </Label>
                <select
                  className="form-select fs-5 invalid input_cus"
                  value={deal}
                  onChange={e => setDeal(e.target.value)}
                >
                  <option>Select</option>
                  <option value="swap">swap</option>
                  <option value="sale">sale</option>
                  <option value="free">free</option>
                </select>
                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
              </div>
            </Col>
          </Row>}
          {props.admin ? null : deal === "sale" && <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">
                  Price
                </Label>
                <Input
                  id="title"
                  name="title"
                  type="text"
                  className="form-control input_cus"
                  placeholder="e.g.Name"
                  value={price}
                  onChange={e => setPrice(e.target.value)}
                />
                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
              </div>
            </Col>
          </Row>}

          {genderboth ? <Row>
            <Label htmlFor="productdesc mb-2">Gender ({gender})</Label>
            <Col xs={4}>
              <div className="mb-3">
                <Label htmlFor="productdesc" className="radio">
                  <Input
                    id="title"
                    name="gender"
                    type="radio"
                    className="form-control"
                    placeholder=""
                    value="boy"
                    onChange={e => setGender(e.target.value)}
                    defaultChecked={gender === "boy"}
                  />
                  Boy
                </Label>
                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
              </div>
            </Col>
            <Col xs={4}>
              <div className="mb-3">
                <Label htmlFor="productdesc" className="radio">
                  <Input
                    id="title"
                    name="gender"
                    type="radio"
                    className="form-control"
                    placeholder=""
                    value="girl"
                    onChange={e => setGender(e.target.value)}
                    defaultChecked={gender === "girl"}
                  />
                  girl
                </Label>

                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
              </div>
            </Col>
            <Col xs={4}>
              <div className="mb-3">
                <Label htmlFor="productdesc" className="radio">
                  <Input
                    id="title"
                    name="gender"
                    type="radio"
                    className="form-control"
                    placeholder=""
                    value="both"
                    onChange={e => setGender(e.target.value)}
                    defaultChecked={gender === "both"}
                  />
                  Both
                </Label>

                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
              </div>
            </Col>
          </Row> : null}


          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="metatitle">Toy Image</Label>
                <div className="mb-3" ref={imageRef}>
                  <Input
                    type="file"
                    className="form-control form-control-lg "
                    onChange={e => handleAcceptedFiles(Object.values(e.target.files), e.target.value = "")}
                    style={{ borderRadius: 0 }}
                  />
                  <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                </div>
              </div>
            </Col>
            {image ? <Row className="align-items-center" style={{ paddingLeft: "14px", paddingRight: "0px" }}>
              <Col className="col-auto">
                <img
                  data-dz-thumbnail=""
                  height="80"
                  className="avatar-sm rounded bg-light"
                  alt={image.name}
                  src={image.preview}
                  style={{ objectFit: "cover" }}
                />
              </Col>
              <Col>
                <Link
                  to="#"
                  className="text-muted "
                >
                  {image.name}
                </Link>
                <p className="mb-0">
                  <strong>{image.formattedSize}</strong>
                </p>
              </Col>
              <Col className="p-0">
                <div className="image__options">
                  <button className="p-0" onClick={(e) => setisOpen(true)}><i className="far fa-eye  fs-5 p-0" />
                  </button>
                  <button className="p-0" style={{ color: "#ff3838" }} onClick={(e) => setImage(null)}><i
                    className="bx bx-trash fs-4" /></button>
                </div>
              </Col>
            </Row> : null}
          </Row>
        </div>
        <div className="foot_form">
          <button className="cancel" onClick={close}>Cancel</button>
          <button className="save" onClick={submitHandler}>{loading ?
            <PulseLoader color={"#ffffff"} loading={true} size={10} /> : "Save"}</button>
        </div>
      </Form>
    </div>
  )
}

export default UpdateForm
