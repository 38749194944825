/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import MetaTags from "react-meta-tags"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"

// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import AddUserForm from "./AddUserForm"
import "./datatables.scss"
import "./specification.scss"
import axios from "axios"
import { toast } from "react-toastify"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import SweetAlert from "react-bootstrap-sweetalert"
import { useSelector } from "react-redux";
import AdminPagination from "pages/AdminSection/AdminNotifications/components/AdminPagination"

const PendingUsers = (props) => {

  const notifications = useSelector(state => state.Notifications.notifyCount)
  const [active, setActive] = useState(false)
  const [productData, setProductData] = useState([])
  const [editCop, setEditCop] = useState(null)
  const [isOpen, setisOpen] = useState(false)
  const [prev, setPrev] = useState("")
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  const [filter,setFilter] = useState('')

  const columns = [{
    dataField: "key",
    text: "Id",
    sort: true
  }, {
    dataField: "image",
    text: "Image",
    sort: true
  }, {
    dataField: "nameEn",
    text: "Name",
    sort: true
  }, {
    dataField: "toys",
    text: "# of toys",
    sort: true
  }, {
    dataField: "email",
    text: "Email",
    sort: true
  }, {
    dataField: "gender",
    text: "Gender",
    sort: true
  }, {
    dataField: "source",
    text: "Device",
    sort: true
  },  {
    dataField: "appVersion",
    text: "App Version",
    sort: true
  }, {
    dataField: "deviceVersion",
    text: "Device Version",
    sort: true
  },{
    dataField: "country",
    text: "Country",
    sort: true
  }, {
    dataField: "city",
    text: "City",
    sort: true
  }, {
    dataField: "mobile",
    text: "Mobile",
    sort: true
  }, {
    dataField: "longitude",
    text: "Longitude",
    sort: true
  }, {
    dataField: "latitude",
    text: "Latitude",
    sort: true
  }, {
    dataField: "actions",
    text: "Actions",
    sort: true
  }, {
    dataField: "points",
    text: "Points",
    sort: true
  }, {
    dataField: "created_at",
    text: "Created On",
    sort: true
  }]


  // const defaultSorted = [{
  //   dataField: "key",
  //   order: "asc"
  // }]


  // const pagination = paginationFactory({
  //   page: 2,
  //   sizePerPageList: [{
  //     text: "10", value: 10
  //   }, {
  //     text: "25", value: 25
  //   }, {
  //     text: "50", value: 50
  //   }, {
  //     text: "75", value: 75
  //   }, {
  //     text: "100", value: 100
  //   }, {
  //     text: "All", value: productData.length
  //   }],
  //   showTotal: true
  // })


  // Select All Button operation
  const selectRow = {
    mode: "checkbox"
  }

  // const { SearchBar } = Search

  function addProd(data, type) {
    setEditCop(null)
    if (type === "add") {
      setProductData(products => products.concat({
        ...data,
        image: <img src={`${data.image}`} alt="" style={{ width: "50px", height: "50px", objectFit: "cover" }} />,
        actions: <div style={{ display: "flex", gap: "10px" }}>
          <button style={editBtn} onClick={() => editCoupon(data)}>
            <i className="bx bx-edit fs-4" />
            Edit
          </button>
          <button style={deleteBtn} onClick={(e) => deleteHandler(data.id)}>
            <i className="bx bx-trash fs-4" />
            Delete
          </button>
        </div>
      }))
    } else {
      setProductData(products => products.map(prod => prod.id === data.id ? { ...prod, ...data } : prod))
    }
  }


  function clickHandle() {
    setActive(!active)
    setEditCop(null)
  }

  function setOpen(img) {
    setisOpen(true)
    setPrev(img)
  }

  function editCoupon(data) {
    setEditCop(data)
    setActive(true)
  }


  function acceptHandler(id, user) {
    axios.post(`${process.env.REACT_APP_API}/admin/users/accept`, { id }, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
      }
    })
      .then(res => {
        bindData()
      })
    // setProductData(products => products.filter(prod => prod.id !== id))
    setMsg(`accept ${user.nameEn}`)
    setsuccess_msg(true)
    // props.userChanges(user, "aactive")
  }

  function rejectHandler(id, user) {
    axios.post(`${process.env.REACT_APP_API}/admin/users/reject`, { id }, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
      }
    })
      .then(res => {
        bindData()
      })
    // setProductData(products => products.filter(prod => prod.id !== id))
    setMsg(`reject ${user.nameEn}`)
    setsuccess_msg(true)
    // props.userChanges(user, "del")
  }



  const [pageNo, setPageNo] = useState(1)
  const sizePerPage = 10
  const [totalCount, setTotalCount] = useState(0)

  useEffect(() => {
    bindData()
  }, [pageNo,filter,notifications, props.activeState])


  useEffect(() => {
      setFilter(props.country)
  }, [props.country])
 

  const bindData = async() => {
    await axios.get(`${process.env.REACT_APP_API}/admin/pendingusers?page=${pageNo}&search=${filter}`, {
      headers: {
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`, 
      }
    })
    .then(res => {
        const resData = res.data.data.pending.data
    setProductData(resData.map(user => {
      return {
        ...user,
        image: <img src={user.image} onClick={(e) => setOpen(e.target.src)} className="usernametabl" alt="" />,
        city: user.city ? user.city : <span className="fs-4">-</span>,
        nameEn: user.nameEn ? user.nameEn : <span className="fs-4">-</span>,
        appVersion: user.appVersion ? user.appVersion : <span className="fs-4">-</span>,
        deviceVersion: user.deviceVersion ? user.deviceVersion : <span className="fs-4">-</span>,
        email: user.email ? user.email : <span className="fs-4">-</span>,
        gender: user.gender ? user.gender : <span className="fs-4">-</span>,
        country: user.country ? user.country : <span className="fs-4">-</span>,
        longitude: user.longitude ? user.longitude : <span className="fs-4">-</span>,
        latitude: user.latitude ? user.latitude : <span className="fs-4">-</span>,
        points: user.points ? user.points : <span className="fs-4">-</span>,
        toys: user.toysCount ? user.toysCount : <span className="fs-4">-</span>,
        actions: <div className="d-flex">
          <button style={editBtn} onClick={() => acceptHandler(user.key, user)}><i className="fas fa-check" /> Accept
          </button>
          <button style={{ ...deleteBtn, gap: "5px" }} onClick={(e) => rejectHandler(user.key, user)}><i
            className="fas fa-times fs-5" /> Reject
          </button>
        </div>
      }
    }))
    setTotalCount(res.data.data.pending.total)
    props.setPendingCount(res.data.data.counter)

  })}

  const onPageHandler = async (type, { page }) => {
    await setPageNo(page)
  }

  return (
    <React.Fragment>
      <div className="container-fluid p-0">
        {success_msg ? (
          <SweetAlert
            title={msg}
            success
            confirmBtnBsStyle="success"
            onConfirm={() => {
              setsuccess_msg(false)
            }}
            onCancel={() => {
              setsuccess_msg(false)
            }}
          >
          </SweetAlert>
        ) : null}
        {isOpen ? (
          <Lightbox
            mainSrc={prev}
            enableZoom={false}
            onCloseRequest={() => {
              setisOpen(!isOpen)
            }}
          />
        ) : null}
        <AddUserForm active={active} clickHandle={clickHandle} addProd={addProd} editCop={editCop}/>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody className="pb-0">
                  <AdminPagination 
                  data={productData} page={pageNo}
                  setFilter={setFilter}
                  keyFiled='key'
                  sizePerPage={sizePerPage} totalSize={totalCount}
                  onTableChange={onPageHandler} columns={columns} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default PendingUsers

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px"

}

const editBtn = {
  background: "transparent",
  border: "none",
  color: "green",
  display: "flex",
  alignItems: "center",
  gap: "5px"
}
