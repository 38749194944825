/* eslint-disable react/react-in-jsx-scope */
import firebase from "firebase/app";
import "firebase/messaging";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getNotifications, updateNotifications } from "store/actions";
import axios from "axios";


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDTTPLOsW9ttRNoNTdgs9l0BQuhxnc3XKs",
    authDomain: "etoyapp-f0dea.firebaseapp.com",
    projectId: "etoyapp-f0dea",
    storageBucket: "etoyapp-f0dea.appspot.com",
    messagingSenderId: "53101356724",
    appId: "1:53101356724:web:afe7fdc1eb6b227aaf99e1",
    measurementId: "G-G4FCD129PQ"
};


firebase.initializeApp(firebaseConfig);

let messaging
if (firebase.messaging.isSupported()){
    messaging = firebase.messaging();
}

const publicKey = process.env.REACT_APP_VAPID_KEY;

export const getToken = async () => {
    let currentToken = "";

    try {
        currentToken = await messaging.getToken({ vapidKey: publicKey });
    } catch (error) {
        console.log("An error occurred while retrieving token. ", error);
    }

    return currentToken;
};



export function Fire() {
    const dispatch = useDispatch()
    messaging.onMessage((payload) => {
        dispatch(getNotifications({...payload.notification, type: payload.data.type, date: payload.data.time    }))
        toast(`${payload.notification.body}`, {icon: <i className="fas fa-bell fs-4" style={{color: "#ec7964"}} />})
    });
    
    
    return<></>;
}