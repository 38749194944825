import {
  GET_NOTIFICATIONS,
  UPDATE_NOTIFICATIONS
} from "./actionTypes"


export const getNotifications = data => ({
  type: GET_NOTIFICATIONS,
  payload: data,
})


export const updateNotifications = data => {
  return {
    type: UPDATE_NOTIFICATIONS,
    payload: data,
  }
}

