/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Form, Label, Input, FormFeedback } from "reactstrap"
import MetaTags from 'react-meta-tags'
import PulseLoader from "react-spinners/PulseLoader";

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import AddUserForm from './AddAvatarForm'
import "./datatables.scss"
import './specification.scss'
import axios from "axios";
import { toast } from "react-toastify";
import SweetAlert from "react-bootstrap-sweetalert"

const VersionPop = (props) => {

  const [active, setActive] = useState(false)
  const[required_version_android, setRequired_version_android] = useState("")
  const[required_version_ios, setRequired_version_ios] = useState("")
  const[force_update, setForce_update] = useState(null)
  const [loading, setLoading] = useState(false)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")

  function submit(e) {
    e.preventDefault();
    setLoading(true)
    let data = {
      required_version_android: required_version_android,
      required_version_ios: required_version_ios,
      force_update: force_update
    }
    axios.post(`${process.env.REACT_APP_API}/admin/updateVersionPop`, data, {
      headers: {
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`,
      }
    })
      .then((res) => {
        if(res.data.error) {
          console.log(res.data.error);
        }
        setMsg("Updated!")
        setsuccess_msg(true)
        setLoading(false)
      })
  }


  useEffect(() => {
      if (props.VersionPop) {
        setRequired_version_android(props.VersionPop.required_version_android)
        setRequired_version_ios(props.VersionPop.required_version_ios)
        setForce_update(props.VersionPop.force_update)

        
      }

  }, [props.VersionPop])
  


  return (
    <React.Fragment>
      <div className="container-fluid p-0">
        {success_msg ? (
          <SweetAlert
            title={msg}
            success
            confirmBtnBsStyle="success"
            onConfirm={() => {
              setsuccess_msg(false)
            }}
            onCancel={() => {
              setsuccess_msg(false)
            }}
          >
          </SweetAlert>
        ) : null}
        <Card>
          <CardBody>
            <Form className="border-0 p-0">
              <Row>
                <Col>
                  <div className="mb-3" >
                    <Label htmlFor="productdesc">
                      Android Version
                    </Label>
                    <Input

                      name="title"
                      type="text"
                      className="form-control input_cus"
                      placeholder="e.g.Name"
                      value={required_version_android}
                      onChange = {e => setRequired_version_android(e.target.value)}
                    />
                    <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                  </div>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <div className="mb-1" >
                    <Label htmlFor="productdesc">
                      IOS Version
                    </Label>
                    <Input

                      name="title"
                      type="text"
                      className="form-control input_cus"
                      placeholder="e.g.Name"
                      value={required_version_ios}
                      onChange = {e => setRequired_version_ios(e.target.value)}
                    />
                    <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                  </div>
                </Col>
              </Row>
              {force_update != null?
              <Row className="mb-1">
              
              <Col>
                  <div className="mb-1" >
                    <Label htmlFor="productdesc">
                      Force Update
                    </Label>
                    <Row>
                    <Col xs={3}>
                            <div className="mb-3" >
                                <Label htmlFor="productdesc"  className='radio'>
                                <Input
                                    name="force_update"
                                    type="radio"
                                    className="form-control"
                                    placeholder=""
                                    value="1"
                                    onChange= {e => setForce_update(e.currentTarget.value)}
                                    defaultChecked={force_update === 1}
                                />
                                    True
                                </Label>
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                        <Col >
                            <div className="mb-3">
                                <Label htmlFor="productdesc" className='radio'>
                                    <Input
                                        name="force_update"
                                        type="radio"
                                        className="form-control"
                                        placeholder=""
                                        value="0"
                                        onChange= {e => setForce_update(e.currentTarget.value)}
                                        defaultChecked={force_update === 0}
                                    />
                                    False
                                </Label>
                                
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    
                  </div>
                </Col>
              </Row>
              :''}
              <Row>
                <Col>
                  <div className="save__btn top m-0">
                    <button onClick={e => submit(e)}>{loading?<PulseLoader color={"#ffffff"} loading={true}  size={10} />:"Save"}</button>
                  </div>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}

export default VersionPop

const deleteBtn = {
  background: "transparent",
  border: "none",
  color:"#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px"

}

const editBtn = {
  background: "transparent",
  border: "none",
  color:"green",
  display: "flex",
  alignItems: "center",
  gap: "5px"
}