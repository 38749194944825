/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Container } from "reactstrap"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
// import "../../Toys/Components/datatables.scss"
import "../datatables.scss"
import "../specification.scss"
// import "../../Toys/Components/specification.scss"

import axios from "axios"

import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import SweetAlert from "react-bootstrap-sweetalert"
import AddForm from "./AddForm"
import UpdateForm from "../../../EToyApp/Toys/Components/UpdateForm"
import PdfForm from "../../../EToyApp/Toys/Components/PdfForm"
import MetaTags from "react-meta-tags"

const EToyApp = (props) => {
  const [active, setActive] = useState(false)
  const [productData, setProductData] = useState([])
  const [editCop, setEditCop] = useState(null)
  const [isOpen, setisOpen] = useState(false)
  const [pdfactive, setPdfActive] = useState(false)
  const [toy, setToy] = useState(null)
  const [prev, setPrev] = useState("")
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  const [ids, setIds] = useState([])
  const [id, setId] = useState(null)

  const columns = [{
    dataField: "id",
    text: "Id",
    sort: true
  }, {
    dataField: "image",
    text: "Image",
    sort: true
  }, {
    dataField: "nameEn",
    text: "Toy Title",
    sort: true
  }, {
    dataField: "nameAr",
    text: "Toy Title(Arabic)",
    sort: true
  },
  //   {
  //   dataField: "descriptionEn",
  //   text: "Toy Description(English)",
  //   sort: true
  // }, {
  //   dataField: "descriptionAr",
  //   text: "Toy Description(Arabic)",
  //   sort: true
  // },
    {
    dataField: "categoryEn",
    text: "Category (English)",
    sort: true
  }, {
    dataField: "categoryAr",
    text: "Category (Arabic)",
    sort: true
  }, {
    dataField: "deal",
    text: "Deal",
    sort: true
  }, {
    dataField: "country",
    text: "Country",
    sort: true
  }, {
    dataField: "actions",
    text: "Actions",
    sort: true
  }, {
    dataField: "age",
    text: "Age",
    sort: true
  }, {
    dataField: "created_at",
    text: "Created On",
    sort: true
  }]

  const pagination = paginationFactory({
    page: 2,
    sizePerPageList: [{
      text: "10", value: 10
    }, {
      text: "25", value: 25
    }, {
      text: "50", value: 50
    }, {
      text: "75", value: 75
    }, {
      text: "100", value: 100
    }, {
      text: "All", value: productData.length
    }],
    showTotal: true
  })

  function setOpen(img) {
    setisOpen(true)
    setPrev(img)
  }

  function setPdf(id, type) {
    setPdfActive(type)
    setId(id)
  }

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect, rowIndex, e) => {
      if (e.target.localName === "input") {
        if (isSelect) {
          setIds(ids => ids.concat(row.id))
        } else {
          setIds(ids => ids.filter(id => id !== row.id))
        }
      }
    }
  }

  const { SearchBar } = Search


  function clickHandle() {
    setActive(!active)
  }


  function editCoupon(data) {
    setEditCop(!edit)
  }


  function deleteHandler(id, user) {
    let ids = []
    ids.push(id)
    axios.post(`${process.env.REACT_APP_API}/admin/toys/delete`, { ids }, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
      }
    })
      .then(res => {
      })
    // setProductData(products => products.filter(prod => prod.id != id))
    props.userChanges(user, "delactive")
    setMsg("Deleted!")
    setsuccess_msg(true)
  }

  function deleteMultiHandler() {
    axios.post(`${process.env.REACT_APP_API}/admin/toys/delete`, { ids }, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
      }
    })
      .then(res => {
      })
    //setProductData(products => products.filter(prod => prod.id != id))
    //props.userChanges(user, "delactive")
    setMsg("Deleted!")
    setsuccess_msg(true)
  }


  function edit(toy) {
    setEditCop(true)
    setToy(toy)
  }

  function mssg() {
    setMsg("Toy Added!")
    setsuccess_msg(true)
  }

  useEffect(() => {
    setProductData(props.active?.map(user => {
      return {
        ...user,
        image: <img src={user.image} onClick={(e) => setOpen(e.target.src)} className="usernametabl" alt="" />,
        nameEn: user.nameEn ? user.nameEn : <span className="fs-4">-</span>,
        deal: user.deal ? user.deal : <span className="fs-4">-</span>,
        price: user.price ? user.price : <span className="fs-4">-</span>,
        country: user.country ? user.country : <span className="fs-4">-</span>,
        age: user.age ? user.age : <span className="fs-4">-</span>,
        status: user.status ? user.status : <span className="fs-4">-</span>,
        gender: user.gender ? user.gender : <span className="fs-4">-</span>,
        city: user.city ? user.city : <span className="fs-4">-</span>,
        actions: <div className="d-flex gap-2">
          <button style={editBtn} onClick={(e) => setPdf(user.id, true)}><i className="bx bx-envelope fs-4" /> Send
            Pdf
          </button>
          <button style={editBtn} onClick={(e) => edit(user)}><i className="bx bx-edit fs-4" /> Edit</button>
          <button style={deleteBtn} onClick={(e) => deleteHandler(user.id, user)}><i
            className="bx bx-trash fs-4" /> Delete
          </button>
        </div>
      }
    }))
  }, [props.active])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>eToy Paper</title>
        </MetaTags>
        <Container fluid>
        {success_msg ? (
          <SweetAlert
            title={msg}
            success
            confirmBtnBsStyle="success"
            onConfirm={() => {
              setsuccess_msg(false)
            }}
            onCancel={() => {
              setsuccess_msg(false)
            }}
          >
          </SweetAlert>
        ) : null}
        {isOpen ? (
          <Lightbox
            mainSrc={prev}
            enableZoom={false}
            onCloseRequest={() => {
              setisOpen(!isOpen)
            }}
          />
        ) : null}
        <AddForm active={active} clickHandle={clickHandle} addProd={props.userChanges} mssg={mssg} admin={true}/>
        <UpdateForm active={editCop} editCoupon={editCoupon} addProd={props.userChanges} toy={toy} admin={true}
                    update={props.update} mssg={mssg} />
        <PdfForm active={pdfactive} clickHandle={setPdf} id={id} />
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody className="pb-0">
                    <ToolkitProvider
                      keyField="id"
                      columns={columns}
                      data={productData}
                      search
                    >
                      {props => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col>
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative ">
                                  <SearchBar
                                    {...props.searchProps}
                                    className="input_cus"
                                  />
                                  <i className="bx bx-search-alt search-icon fs-3 pt-1" />
                                </div>
                              </div>
                            </Col>
                            <Col>
                              <div className="save__btn top">
                                {ids.length > 1 ?
                                  <div className="d-flex delet justify-content-end align-items-center fs-5">
                                    <button style={{ ...rej, marginRight: "10px" }}
                                            onClick={(e) => deleteMultiHandler()}><i
                                      className="bx bx-trash fs-4" /> Delete
                                    </button>
                                  </div> : null}
                                <button onClick={clickHandle}><i className="bx bx-plus-circle fs-4" /> Add Toy
                                </button>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  pagination={pagination}
                                  selectRow={selectRow}
                                  classes={
                                    "table align-middle table-nowrap"
                                  }
                                  headerWrapperClasses={"thead-light"}
                                  {...props.baseProps}
                                />
                                {productData.length === 0 ? <div className="emptyStateTable" style={{
                                  width: "100%",
                                  textAlign: "center",
                                  fontSize: "16px",
                                  padding: "10px",
                                  paddingTop: "0", paddingBottom: "20px"
                                }}>no data available in table</div> : null}
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )
                      }
                    </ToolkitProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EToyApp

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px"
}

const editBtn = {
  background: "transparent",
  border: "none",
  color: "#000",
  display: "flex",
  alignItems: "center",
  gap: "5px"

}

const rej = {
  background: "transparent",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px",
  width: "150px",
  height: "48px",
  border: "1px solid #D91414",
  textAlign: "center",
  justifyContent: "center"
}