/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import "./datatables.scss"
import './specification.scss'
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert"

const Country = (props) => {
  const[productData, setProductData] = useState([])
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  const columns = [{
    dataField: 'key',
    text: 'Id',
    sort: true,
  }, {
    dataField: 'nameAr',
    text: 'Name (Arabic)',
    sort: true
  }, {
    dataField: 'nameEn',
    text: 'Name (English)',
    sort: true
  }, {
    dataField: 'currency',
    text: 'Currency',
    sort: true
  },{
    dataField: 'status',
    text: 'Status',
    sort: true
  },{
    dataField: 'actions',
    text: 'Actions',
    sort: true
  }];

  const defaultSorted = [{
    dataField: 'key',
    order: 'asc'
  }];

  const pagination = paginationFactory({
    page: 2,
    sizePerPageList: [{
      text: "10", value: 10
    }, {
      text: "25", value: 25
    }, {
      text: "50", value: 50
    }, {
      text: "75", value: 75
    }, {
      text: "100", value: 100
    }, {
      text: "All", value: productData.length
    }],
    showTotal: true
  })



  // Select All Button operation
  const selectRow = {
    mode: 'checkbox'
  }

  const { SearchBar } = Search;

  

  function submit(id, status) {
    let data = {
        id,
        status
      }
      axios.post(`${process.env.REACT_APP_API}/admin/setting/countryUpdate`, data, {
        headers: {
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`, 
        }
      })
      .then((res) => {
        if(res.data.error) {
          console.log(res.data.error);
        }
      })
      setProductData(count => count.map(c => {
        if(c.key == id) {
          return {...c, 
                  activeOrNot: status,
                  status: status == 1?<i className='bx bxs-check-circle fs-3' style={{color: "green", margin: "0 auto"}}></i>:<i className="fas fa-times-circle fs-4" style={{color: "#D91414", margin: "0 auto"}}></i>, 
                  actions: <div className="d-flex gap-2">
                              {status == 0?<button style={editBtn}  onClick={()=>submit(id, 1)}><i className="fas fa-check"></i> Active</button>:
                              <button style={deleteBtn}  onClick={(e)=>submit(id, 0)}><i className="fas fa-times fs-5"></i> Unactive</button>}
                          </div>
                }
        }
        return c
      }))
      setMsg("Updated!")
      setsuccess_msg(true)
  }


  useEffect(() => {
    setProductData(props.countries.map(country => {return{...country,
      status: country.activeOrNot == "1"?<i className='bx bxs-check-circle fs-3' style={{color: "green", margin: "0 auto"}}></i>:<i className="fas fa-times-circle fs-4" style={{color: "#D91414", margin: "0 auto"}}></i>, 
      actions: <div className="d-flex gap-2">
                  {country.activeOrNot == "0"?<button style={editBtn}  onClick={()=>submit(country.key, 1)}><i className="fas fa-check"></i> Active</button>:
                  <button style={deleteBtn}  onClick={(e)=>submit(country.key, 0)}><i className="fas fa-times fs-5"></i> Unactive</button>}
              </div>
    }}))
  }, [props.countries]) 


  return (
    <React.Fragment>
        <div className="container-fluid p-0">
            
          <Row>
            <Col className="col-12">
              <Card >
                <CardBody className="pb-0">
                {success_msg ? (
                    <SweetAlert
                      title={msg}
                      success
                      confirmBtnBsStyle="success"
                      onConfirm={() => {
                        setsuccess_msg(false)
                      }}
                      onCancel={() => {
                        setsuccess_msg(false)
                      }}
                    >
                    </SweetAlert>
                  ) : null}
                      <ToolkitProvider
                        keyField='key'
                        columns={columns}
                        data={productData}
                        search
                      >
                        {props => (
                          <React.Fragment>

                            <Row className="mb-2">
                              <Col>
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative ">
                                    <SearchBar
                                      {...props.searchProps}
                                      className="input_cus"
                                    />
                                    <i className="bx bx-search-alt search-icon fs-3 pt-1" />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"key"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    pagination={pagination}
                                    selectRow={selectRow}
                                    classes={
                                      "table align-middle table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...props.baseProps}
                                  />
                                  {productData.length === 0?<div className="emptyStateTable" style={{
                                      width: '100%',
                                      textAlign: 'center',
                                      fontSize: '16px',
                                      padding: '10px',
                                      paddingTop: '0', paddingBottom: "20px"}}>no data available in table</div>:null}
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )
                        }
                      </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
    </React.Fragment>
  )
}

export default Country

const deleteBtn = {
  background: "transparent",
  border: "none",
  color:"#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px"

}

const editBtn = {
  background: "transparent",
  border: "none",
  color:"green",
  display: "flex",
  alignItems: "center",
  gap: "5px"
}