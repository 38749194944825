import React, {useEffect, useState} from "react"
import MetaTags from 'react-meta-tags';
import classnames from "classnames";
import {
    Accordion,
    AccordionHeader,
    AccordionItem,
    Card,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Collapse,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from "reactstrap";
import Sticky from 'react-sticky-el';
import Select from 'react-select'
import NoToy from './Components/NoToys'
import YesToy from './Components/YesToy'
import Others from './Components/Others'
import './configration.scss'
import axios from "axios";
import { useSelector, useDispatch } from "react-redux"
import {
    setTitle,
    updateNotifications
} from "../../../store/actions";
import { useHistory } from "react-router-dom";
const Reviews = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const   user = useSelector(state => state.UserData.user)
    const   users = useSelector(state => state.MainData.users)
    const countries = useSelector(state => state.MainData.countries)
    const notifications = useSelector(state => state.Notifications.reviewCount)
    const [data, setData] = useState([])
    const [yes, setYes] = useState([])
    const [no, setNo] = useState([])
    const [other, setOther] = useState([])
    const [verticalActiveTab, setverticalActiveTab] = useState("1");

    const toggleVertical = tab => {
        if (verticalActiveTab !== tab) {
            setverticalActiveTab(tab);
        }
    };

    function countryFilter(name) {
        if(name === "All") {
            setYes(data.yesToyReviews)
            setNo(data.noToyReviews)
            setOther(data.otherReviews)
            return;
        }
        setYes(data.yesToyReviews.filter(toy =>  toy.toyOwner.country == name))
        setNo(data.noToyReviews.filter(toy =>  toy.toyOwner.country == name))
        setOther(data.otherReviews.filter(toy =>  toy.toyOwner.country == name))
    }

    useEffect(() => {
        if(user && user.role == 2) {
            history.push('/')
            return;
        }
        axios.post(`${process.env.REACT_APP_API}/admin/setUnread`, {key: "5"},{
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`, 
            }
        }).then(res => {
            dispatch(updateNotifications({unreadReviewCount: 0}))
        })
    }, [user])
    
    useEffect(() => {
        dispatch(setTitle("Reviews"))
        axios.get(`${process.env.REACT_APP_API}/admin/reviews`, {
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`, 
            }
        })
        .then(res => {
            setData(res?.data?.data)
            setYes(res?.data?.data?.yesToyReviews)
            setNo(res?.data?.data?.noToyReviews)
            setOther(res?.data?.data?.otherReviews)
        }) 
    }, [])

    useEffect(() => {
        if(notifications) {
            axios.get(`${process.env.REACT_APP_API}/admin/reviews`, {
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`, 
                }
            })
            .then(res => {
                setData(res.data.data)
                setYes(res.data.data.yesToyReviews)
                setNo(res.data.data.noToyReviews)
                setOther(res.data.data.otherReviews)
            })
            axios.post(`${process.env.REACT_APP_API}/admin/setUnread`, {key: "5"},{
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`, 
                }
            }).then(res => {
                dispatch(updateNotifications({unreadReviewCount: 0}))
            })
        }
    }, [notifications])

    return (
        <React.Fragment>
            <div className="page-content">
            <MetaTags>
                <title>Reviews | Etoy App</title>
            </MetaTags>
            <Container fluid>
            <Col>
                <Row className="mb-3">
                    <Col>
                        <Select 
                            options={countries?[{value:"All", label:"All"}].concat(countries.map(c => {return {value: c.nameEn, label: c.nameEn + ` (${c.users_count})`} })):null}
                            placeholder="Country"
                            onChange={(e) => countryFilter(e.value)}
                        />
                    </Col> 
                </Row>
                <Row>
                <Col md="12">
                <Sticky topOffset={-70} style={{top: "70px"}}>
                    <Nav pills className="flex-row justify-content-between navs_aside">
                    <NavItem className="border_right">
                        <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "1",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("1");
                        }}
                        >
                        Yes Toy {yes?`(${yes.length})`:null}
                        </NavLink>
                    </NavItem>

                    <NavItem className="border_right">
                        <NavLink
                        style={{ cursor: "pointer", borderRadius: 0}}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "2",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("2");
                        }}
                        >
                            No Toy {no?`(${no.length})`:null}
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "3",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("3");
                        }}
                        >
                            Others {other?`(${other.length})`:null}
                        </NavLink>
                    </NavItem>
                    </Nav>
                    </Sticky>
                </Col>
                <Col md="12  mt-2">
                    <TabContent
                    activeTab={verticalActiveTab}
                    className="text-muted table__content mt-4 mt-md-0"
                    >
                        <TabPane tabId="1">
                            <YesToy data={yes}/>
                        </TabPane>
                        <TabPane tabId="2">
                            <NoToy data={no}/>
                        </TabPane>
                        <TabPane tabId="3">
                            <Others data={other}/>
                        </TabPane>
                        </TabContent>
                </Col>
                </Row>
            </Col>
            </Container>
            </div>
        </React.Fragment>
)
}

export default Reviews



const navsStyle = { 
    cursor: "pointer", 
    borderRadius: 0,

}