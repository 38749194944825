/* eslint-disable react/prop-types */
import axios from "axios"
import React, { useState, useRef, useEffect } from "react"
import { Col, Form, FormFeedback, Input, Label, Row } from "reactstrap"
import PulseLoader from "react-spinners/PulseLoader"
import ReactTagInput from "@pathofdev/react-tag-input"
import "@pathofdev/react-tag-input/build/index.css"
import { Link } from "react-router-dom"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

import "../../Toys/Components/specification.scss"


const RejectReasonsForm = (props) => {
  const [messageEn, setMessageEn] = useState("")
  const [messageAr, setMessageAr] = useState("")
  const [loading, setLoading] = useState(false)

  function close() {
    // setNameEn("")
    // setNameAr("")
    // setDescriptionEn("")
    // setDescriptionAr("")
    // setPickEn("")
    // setPickAr("")
    // setAge("")
    // setCountry("")
    // setCategory("")
    // setDeal("")
    // setGender("")
    // setStatus("")
    // setImage(null)
    // setPrice("")
    // setboth(false)
    // document.getElementById("toyUpdateForm").reset()
    props.clickHandle ? props.clickHandle() : props.editCoupon()
  }



  // console.log(ids)


  function submitHandler() {
    setLoading(true)
    let ids = []
    ids.push(...props.rejectId)

    const data ={
      ids,
      messageEn,
      messageAr
    }

    axios.post(`${process.env.REACT_APP_API}/admin/toys/reject`,  data , {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
      }
    })
      .then(res => {
        setLoading(false)
        if (res.data.error) {
          return
        }
        props.bindData()
        props.clickHandle()
        setMessageEn("")
        setMessageAr("")
        document.getElementById("toyForm").reset()
        close()
      })
  }

  return (
    <div className={`overlay_coup ${props.active ? "active" : ""}`}>
      <Form id="toyForm" onSubmit={(e) => e.preventDefault()} style={{ paddingBottom: "70px", height: "365px" }}>
        <div className="head_form">
          <div className="log fs-5">
            {/* <i className="fas fa-user fs-5"></i> */}
            Add Toy
          </div>
          <button onClick={close}><i className="dripicons dripicons-cross" /></button>
        </div>
        <div className="inputs__wrap ">
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">
                  Reason (English)
                </Label>
                <Input
                  id="title"
                  name="title"
                  type="text"
                  className="form-control input_cus"
                  placeholder="e.g.Name"
                  value={messageEn}
                  onChange={e => setMessageEn(e.target.value)}
                />
                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">
                  Reason (Arabic)
                </Label>
                <Input
                  id="title"
                  name="title"
                  type="text"
                  className="form-control input_cus"
                  placeholder="مثال. الاسم"
                  dir="rtl"
                  value={messageAr}
                  onChange={e => setMessageAr(e.target.value)}
                />
                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
              </div>
            </Col>
          </Row>

        </div>
        <div className="foot_form">
          <button className="cancel" onClick={close}>Cancel</button>
          <button className="save"
                  onClick={submitHandler}
          >{loading ?
            <PulseLoader color={"#ffffff"} loading={true} size={10} /> : "Save"}</button>
        </div>
      </Form>
    </div>
  )
}

export default RejectReasonsForm
