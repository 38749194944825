/* eslint-disable react/prop-types */
import axios from 'axios';
import React, {useState, useRef, useEffect} from 'react';
import { Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import PulseLoader from "react-spinners/PulseLoader";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import {Link} from "react-router-dom"
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import './specification.scss'

import Select from 'react-select'
import { useSelector } from "react-redux";
const AddUserForm = (props) => {
    const   countries = useSelector(state => state.MainData.countries)
    let p = props 
    const [isOpen, setisOpen] = useState(false);
    const [nameEn, setNameEn] = useState('');
    const [nameAr, setNameAr] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('')
    const [password, setPassword] = useState('')
    const [country, setCountry] = useState('')
    const [role, setRole] = useState('')
    const [gender, setGender] = useState('')
    const [confirmed, setConfirmed] = useState('')
    const [image, setImage] = useState(null)
    const [loading, setLoading] = useState(false)
    const [gen, setGen] = useState(false)
    
    const nameEnRef = useRef(null);
    const nameArRef = useRef(null);
    const emailRef = useRef(null);
    const mobileRef = useRef(null);
    const passwordRef = useRef(null);
    const genderRef = useRef(null);
    const Ref = useRef(null);
    const imageRef = useRef(null);


    function handleAcceptedFiles(files) {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size)
            })
        )
        setImage(files[0])
    }

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }


    function close() {
        setNameEn('')
        setNameAr('')
        setEmail('')
        setMobile('')
        setCountry('')
        setRole('')
        setConfirmed('')
        setGender('')
        setImage(null)
        setGen(false)
        document.getElementById("useraddForm").scrollTo(0,0)
        props.clickHandle(null, false)
    }

    function submitHandler() {
        setLoading(true)
        const formData = new FormData();
        
        let data = {
            name: nameEn,
            name_ar: nameAr,
            email,
            mobile,
            password,
            country,
            gender,
            role,
            confirmed,
        } 

        for ( let key in data ) {
            formData.append(key, data[key]);
        }

        if(image.name) {
            formData.append('image', image);
        }

        axios.post(`${process.env.REACT_APP_API}/admin/users/update/${props.user.key}`, formData, {
            headers: { 
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`, 
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            props.update(res.data.data)
            setLoading(false)
            close()
        })
    }


    useEffect(() => {
        let user = props.user
        if(user) {
            setNameEn(user.nameEn)
            setNameAr(user.nameAr)
            setEmail(user.email)
            setMobile(user.mobile)
            setCountry(user.country)
            setRole(user.role)
            setConfirmed(user.approved)
            setGender(user.gender)
            setImage({preview: user.image})
            setGen(true)
            return;
        }
        // setGen(true)
    }, [props.user])


    return(
        <div className={`overlay_coup ${p.active? "active":""}`}>
            {isOpen ? (
            <Lightbox
                mainSrc={image.preview}
                enableZoom={false}
                onCloseRequest={() => {
                setisOpen(!isOpen);
                }}
            />
            ) : null}
            <Form  onSubmit={(e) => e.preventDefault()} style={{paddingBottom: "70px", height: "580px"}}>
                <div className='head_form'>
                    <div className='log fs-5'>
                        Add User
                    </div>
                    <button onClick={close}><i className='dripicons dripicons-cross'></i></button>
                </div>
                <div id="useraddForm" className='inputs__wrap '>
                <Row>
                        <Col>
                            <div className="mb-3" >
                                <Label htmlFor="productdesc">
                                    Full Name (English)
                                </Label>
                                <Input
                                    
                                    name="title"
                                    type="text"
                                    className="form-control input_cus"
                                    placeholder="e.g.Name"
                                    value={nameEn}
                                    onChange= {e => setNameEn(e.target.value)}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="mb-3" >
                                <Label htmlFor="productdesc">
                                    Full Name (Arabic)
                                </Label>
                                <Input
                                    
                                    name="title"
                                    type="text"
                                    className="form-control input_cus"
                                    placeholder="مثال. الاسم"
                                    dir='rtl'
                                    value={nameAr}
                                    onChange= {e =>setNameAr(e.target.value)}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="mb-3" >
                                <Label htmlFor="productdesc">
                                    Email
                                </Label>
                                <Input
                                    name="title"
                                    type="text"
                                    className="form-control input_cus"
                                    placeholder=""
                                    value={email}
                                    onChange= {e => setEmail(e.target.value)}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="mb-3" >
                                <Label htmlFor="productdesc">
                                    Mobile
                                </Label>
                                <Input
                                    name="title"
                                    type="text"
                                    className="form-control input_cus"
                                    placeholder=""
                                    value={mobile}
                                    onChange= {e => setMobile(e.target.value)}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="mb-3" >
                                <Label htmlFor="productdesc">
                                    Password
                                </Label>
                                <Input
                                    
                                    name="title"
                                    type="password"
                                    className="form-control input_cus" 
                                    placeholder=""
                                    value={password}
                                    onChange= {e => setPassword(e.target.value)}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="mb-3" >
                                <Label htmlFor="productdesc">
                                    Country
                                </Label>
                                <Select
                                    options={countries?countries.map(c => {return {value: c.nameEn, label: c.nameEn} }):null} 
                                    placeholder={country}
                                    onChange={(e) => setCountry(e.value)}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="mb-3" >
                                <Label htmlFor="productdesc">
                                    Role
                                </Label>
                                <select  
                                    className="form-select fs-5 invalid input_cus"
                                    value={role}
                                    onChange= {e => setRole(e.target.value)}
                                >
                                <option>Select</option>
                                <option value="1">Super Admin</option>
                                <option value="2">Admin</option>
                                <option value="3">Editor</option>
                                <option value="4">User</option>
                                </select>
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="mb-3" >
                                <Label htmlFor="productdesc">
                                    Confirmed
                                </Label>
                                <select  
                                    className="form-select fs-5 invalid input_cus"
                                    value={confirmed}
                                    onChange= {e => setConfirmed(e.target.value)}
                                >
                                <option>Select</option>
                                <option value={1}>Approbed</option>
                                <option value={0}>Not Approbed</option>
                                </select>
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    {gen?<Row>
                    <Label htmlFor="productdesc">Gender</Label>
                        <Col xs={2}>
                            <div className="mb-3" >
                                <Label htmlFor="productdesc"  className='radio'>
                                <Input
                                    
                                    name="gender"
                                    type="radio"
                                    className="form-control"
                                    placeholder=""
                                    value="male"
                                    onChange= {e => setGender(e.target.value)}
                                    defaultChecked={gender === "male"}
                                />
                                    Male
                                </Label>
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                        <Col >
                            <div className="mb-3">
                                <Label htmlFor="productdesc" className='radio'>
                                    <Input
                                        
                                        name="gender"
                                        type="radio"
                                        className="form-control"
                                        placeholder=""
                                        value="female"
                                        onChange= {e => setGender(e.target.value)}
                                        defaultChecked={gender === "female"}
                                    />
                                    Female
                                </Label>
                                
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                        <Col >
                            <div className="mb-3">
                                <Label htmlFor="productdesc" className='radio'>
                                    <Input
                                        
                                        name="gender"
                                        type="radio"
                                        className="form-control"
                                        placeholder=""
                                        value="other"
                                        onChange= {e => setGender(e.target.value)}
                                        defaultChecked={!gender}
                                    />
                                    Other
                                </Label>
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>:null}
                    <Row>
                        <Col>
                            <div className="mb-3">
                                <Label htmlFor="metatitle">Personal Image</Label>
                                <div className="mb-3" ref={imageRef}>
                                    <Input 
                                        type="file"
                                        className="form-control form-control-lg "
                                        onChange={e => handleAcceptedFiles(Object.values(e.target.files), e.target.value = '')}
                                        style={{borderRadius: 0}}
                                    />
                                    <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                                </div>
                            </div>
                        </Col>
                        {image? <Row className="align-items-center" style={{paddingLeft: "14px", paddingRight: "0px"}}>
                            <Col className="col-auto">
                                <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={image.name}
                                src={image.preview}
                                style={{objectFit: "cover"}}
                                />
                            </Col>
                            <Col>
                                <Link
                                to="#"
                                className="text-muted "
                                >
                                {image.name}
                                </Link>
                                <p className="mb-0">
                                <strong>{image.formattedSize}</strong>
                                </p>
                            </Col>
                            <Col className='p-0'>
                                <div className="image__options">
                                    <button className="p-0"  onClick={(e) => setisOpen(true)}><i className="far fa-eye  fs-5 p-0"></i></button>
                                    <button className='p-0' style={{color: "#ff3838"}} onClick={(e) => setImage(null)}><i className='bx bx-trash fs-4'></i></button>
                                </div>
                            </Col>
                        </Row>:null}
                    </Row>
                </div>

                <div className='foot_form'>
                    <button className='cancel' onClick={close}>Cancel</button>
                    <button className='save' onClick={submitHandler}>{loading?<PulseLoader color={"#ffffff"} loading={true}  size={10} />:"Save"}</button>
                </div>
            </Form>
        </div>
    );
};

export default AddUserForm;
