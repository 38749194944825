/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Table } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"

import AddCategoryForm from "./AddCategoryForm"
import "./datatables.scss"
import "./specification.scss"
import axios from "axios"


import ReactDragListView from "react-drag-listview/lib/index.js"
import UpdateCategoryForm from "./UpdateCategoryForm"


const Category = (props) => {
  const [active, setActive] = useState(false)
  const [productData, setProductData] = useState([])
  const [category, setCategory] = useState(null)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")


  function clickHandle(category, set) {
    setActive(set)
    setCategory(category)
  }

  function update(data, type) {
    if(type === "add") {
      setProductData(categories => categories.concat({...data, actions: <div className="d-flex">
      <button style={editBtn}  onClick={() => clickHandle(data, true)}><i className='bx bx-edit fs-4 pr-1' style={{marginRight: "10px"}}></i> Edit</button>
      <button style={deleteBtn}  onClick={(e)=>deleteCategory(data.key)}><i className='bx bx-trash fs-4' style={{marginRight: "10px"}}></i> Delete</button>
    </div>}))
        setMsg("Added!")
    }else {
      setProductData(categories => categories.map(c => c.key === data.key? {...data, actions: <div className="d-flex">
            <button style={editBtn}  onClick={() => clickHandle(data, true)}><i className='bx bx-edit fs-4 pr-1' style={{marginRight: "10px"}}></i> Edit</button>
            <button style={deleteBtn}  onClick={(e)=>deleteCategory(data.key)}><i className='bx bx-trash fs-4'   style={{marginRight: "10px"}}></i> Delete</button>
        </div>}: c))
        setMsg("Updated!")
    }
    setsuccess_msg(true)
  }


  function deleteCategory(id) {
    axios.post(`${process.env.REACT_APP_API}/admin/setting/categoryDelete`, { id }, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
      }
    })
      .then(res => {
      })
    setMsg("Deleted!")
    setsuccess_msg(true)
    setProductData(products => products.filter(prod => prod.key !== id))
  }

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const modifiedData = [...productData]
      const item = modifiedData.splice(fromIndex, 1)[0]
      modifiedData.splice(toIndex, 0, item)
      setProductData(modifiedData)
      console.log({
        cats: modifiedData.map(cat => {
          return { nameEn: cat.nameEn, nameAr: cat.nameAr, key: cat.key }
        })
      })
      axios.post(`${process.env.REACT_APP_API}/admin/setting/categoryArrange`, {
        cats: modifiedData.map(cat => {
          return { nameEn: cat.nameEn, nameAr: cat.nameAr, key: cat.key }
        })
      }, {
        headers: {
          "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`,
          "Content-Type": "application/json"
        }
      })
        .then(res => {
          console.log(res.data)
        })
    },
    nodeSelector: "tr",
    handleSelector: "tr"
  }


  useEffect(() => {
    setProductData(props.categories.map(category => {
      return {
        ...category,
        actions: <div className="d-flex">
          <button style={editBtn} onClick={() => clickHandle(category, true)}><i className="bx bx-edit fs-4 pr-1"
                                                                                 style={{ marginRight: "10px" }} /> Edit
          </button>
          <button style={deleteBtn} onClick={(e) => deleteCategory(category.key)}><i className="bx bx-trash fs-4"
                                                                                     style={{ marginRight: "10px" }} /> Delete
          </button>
        </div>
      }
    }))
  }, [props.categories])


  return (
    <React.Fragment>
      <div className="container-fluid p-0">
        {success_msg ? (
          <SweetAlert
            title={msg}
            success
            confirmBtnBsStyle="success"
            onConfirm={() => {
              setsuccess_msg(false)
            }}
            onCancel={() => {
              setsuccess_msg(false)
            }}
          >
          </SweetAlert>
        ) : null}
        <AddCategoryForm active={active} clickHandle={clickHandle} category={category} update={update} eToyCategoryChanges={props.eToyCategoryChanges} />
        {/*<UpdateCategoryForm active={active} clickHandle={clickHandle} category={category} update={update} />*/}
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody className="pb-0">
                <Row>
                  <div className="save__btn top mb-2">
                    <button onClick={() => clickHandle(null, true)}><i className="bx bx-plus-circle fs-4"></i> Add
                      Category
                    </button>
                  </div>
                </Row>
                <div className="table-responsive">
                  <ReactDragListView {...dragProps}>
                    <Table className="table mb-0">
                      <thead>
                      <tr>
                        <th>id</th>
                        <th>Name (English)</th>
                        <th>Name (Arabic)</th>
                        <th># of Toys</th>
                        <th>ِActions</th>
                      </tr>
                      </thead>
                      <tbody>
                      {(productData || []).map((item, index) => (
                        <tr key={index}>
                          <th scope="row" className="drag-pointer">{item.key}</th>
                          <td>{item.nameEn}</td>
                          <td>{item.nameAr}</td>
                          <td>{item.toysCount}</td>
                          <td>{item.actions}</td>
                        </tr>
                      ))}
                      </tbody>
                    </Table>
                  </ReactDragListView>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default Category

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "0px"

}

const editBtn = {
  background: "transparent",
  border: "none",
  display: "flex",
  alignItems: "center",
  gap: "0px"
}


{/* <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField='key'
                    columns={columns}
                    data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField='key'
                        columns={columns}
                        data={productData}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>

                            <Row className="mb-2">
                              <Col>
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative ">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      className="input_cus"
                                    />
                                    <i className="bx bx-search-alt search-icon fs-3 pt-1" />
                                  </div>
                                </div>
                              </Col>
                              <Col >
                                <div className="save__btn top">
                                    <button onClick={() => clickHandle(null, true)}><i className='bx bx-plus-circle fs-4'></i> Add Category</button>
                                </div>
                              </Col>
                            </Row>
                            
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"key"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    
                                    selectRow={selectRow}
                                    classes={
                                      "table align-middle table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                  {productData.length === 0?<div className="emptyStateTable" style={{
                                      width: '100%',
                                      textAlign: 'center',
                                      fontSize: '16px',
                                      padding: '10px',
                                      paddingTop: '0', paddingBottom: "20px"}}>no data available in table</div>:null}
                                </div>
                              </Col>
                            </Row>

                            {productData.length !== 0?<Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>:null}
                          </React.Fragment>
                        )
                        }
                      </ToolkitProvider>
                    )
                    }
                    
                    </PaginationProvider> */
}

// const columns = [{
//   dataField: 'key',
//   text: 'Id',
//   sort: true,
// }, {
//   dataField: 'nameEn',
//   text: 'Name (English)',
//   sort: true
// }, {
//   dataField: 'nameAr',
//   text: 'Name (Arabic)',
//   sort: true
// },{
//   dataField: 'actions',
//   text: 'Actions',
//   sort: true
// }];

// const defaultSorted = [{
//   dataField: 'key',
//   order: 'asc'
// }];

// const pageOptions = {
//   sizePerPage: 10,
//   totalSize: productData.length, // replace later with size(customers),
//   custom: true,
// }


// // Select All Button operation
// const selectRow = {
//   mode: 'checkbox'
// }

// const { SearchBar } = Search;