import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import classnames from "classnames"
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap"
import Sticky from "react-sticky-el"
import Select from "react-select"
import AdminNotifications from "./components/AdminNotifications"
import "../../General/Notifications/configration.scss"
import axios from "axios"
import { useSelector, useDispatch } from "react-redux"
import {
  setTitle,
  updateNotifications
} from "../../../store/actions"
import SweetAlert from "react-bootstrap-sweetalert"
import { useHistory } from "react-router-dom"

const Notifications = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.UserData.user)
  const countries = useSelector(state => state.MainData.countries)
  const notifications = useSelector(state => state.Notifications.notifyCount)
  const history = useHistory()
  // const [category, setCategory] = useState([])
  // const [active, setActive] = useState([])
  // const [unActive, setUnActive] = useState([])
  // const [pending, setPending] = useState([])
  // const [usertouser, setUTU] = useState([])
  // const [admintouser, setATU] = useState([])
  // const [usertoadmin, setUTA] = useState([])
  // const [adminnotification, setAN] = useState([])
  const [noty, setNoty] = useState([])
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")

  // const [currentPage, setCurrentPage] = useState(1)
  // const [filter, setFilter] = useState({
  //   pageNo: 1,
  //   sizePerPage: 5,
  // })
  // const [totalCount, setTotalCount] = useState(0)
  //
  // useEffect(() => {
  //   bindData()
  // }, [])
  //
  // const bindData =() => {
  //   let {
  //     pageNo
  //   } = filter
  //   axios.get(`${process.env.REACT_APP_API}/admin/notifications/getPublishAdminToUsers?page=${pageNo}`, {
  //     headers: {
  //       "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
  //     }
  //   }).then(res => {
  //     console.log("request data", res.data.data.data)
  //     setAN(res.data.data.data)
  //     console.log("request data", res.data.data.total)
  //     setTotalCount(res.data.data.total)
  //     // res.data.data
  //   }).then(res => {
  //     // console.log("admin notification data", res.data.data.data)
  //     // console.log("total pages", res.data.data.total)
  //     // setAN(res.data.data.data)
  //     // setTotalCount(res.data.data.total)
  //   })
  // }
  //
  // const onPageHandler = () => async (type, {
  //   page
  // }) => {
  //   let data = page
  //   let {
  //     filter
  //   } = filter
  //   let tempFilter = { ...filter }
  //   tempFilter["pageNo"] = data
  //   await setFilter(tempFilter)
  //   bindData()
  // }

  // const columns = [{
  //   dataField: "id",
  //   text: "Id",
  //   sort: true
  // }, {
  //   dataField: "from",
  //   text: "From",
  //   sort: true
  // }, {
  //   dataField: "to",
  //   text: "To",
  //   sort: true
  // }, {
  //   dataField: "message_en",
  //   text: "Description (English)",
  //   sort: true
  // }, {
  //   dataField: "message_ar",
  //   text: "Description (Arabic)",
  //   sort: true
  // }, {
  //   dataField: "actions",
  //   text: "Actions",
  //   sort: true
  // }, {
  //   dataField: "created_at",
  //   text: "Created On",
  //   sort: true
  // }]

  // function userChanges(key, type) {
  //   if (type === "utu") {
  //     setUTU(notys => notys.filter(noty => noty.key !== key))
  //     setMsg("Deleted!")
  //     setsuccess_msg(true)
  //   } else if (type === "uta") {
  //     setUTA(notys => notys.filter(noty => noty.key !== key))
  //     setMsg("Deleted!")
  //     setsuccess_msg(true)
  //   } else if (type === "atu") {
  //     setATU(notys => notys.filter(noty => noty.key !== key))
  //     setMsg("Deleted!")
  //     setsuccess_msg(true)
  //   } else {
  //     setAN(notys => notys.filter(noty => noty.key !== key))
  //     setMsg("Deleted!")
  //     setsuccess_msg(true)
  //   }
  // }

  function countryFilter(name) {
    if (name === "All") {
      setUTU(noty.usersToUser)
      setATU(noty.adminToUser)
      setUTA(noty.usersToAdmin)
      return
    }
    setUTU(noty.usersToUser.filter(noty => noty.userfrom.country == name || noty.userto.country == name))
    setUTA(noty.usersToAdmin.filter(noty => noty.userfrom.country == name || noty.userto.country == name))
    setATU(noty.adminToUser.filter(noty => noty.userfrom.country == name || noty.userto.country == name))
  }

  useEffect(() => {
    if (user && user.role == 2) {
      history.push("/")
      return
    }
  }, [user])

  // useEffect(() => {
  //   dispatch(setTitle("Notifications"))
  //   axios.get(`${process.env.REACT_APP_API}/admin/notifications/getPublishAdminToUsers`, {
  //     headers: {
  //       "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
  //     }
  //   })
  //     .then(res => {
  //       // setNoty(res.data.data)
  //       // setUTU(res.data.data.usersToUser)
  //       // setATU(res.data.data.adminToUser)
  //       // setUTA(res.data.data.usersToAdmin)
  //       console.log(res)
  //       setAN(res.data.data.data)
  //       // setAN(res.data.data.publishAdminToUsers)
  //     })
  //   axios.post(`${process.env.REACT_APP_API}/admin/setUnread`, { key: "7" }, {
  //     headers: {
  //       "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
  //     }
  //   }).then(res => {
  //     dispatch(updateNotifications({ unreadNotificationCount: 0 }))
  //   })
  // }, [])
  //
  // useEffect(() => {
  //   if (notifications) {
  //     axios.get(`${process.env.REACT_APP_API}/admin/notifications/getPublishAdminToUsers`, {
  //       headers: {
  //         "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
  //       }
  //     })
  //       .then(res => {
  //         // setNoty(res.data.data)
  //         // setUTU(res.data.data.usersToUser)
  //         // setATU(res.data.data.adminToUser)
  //         // setUTA(res.data.data.usersToAdmin)
  //         setAN(res.data.data.data)
  //         // setAN(res.data.data.publishAdminToUsers)
  //       })
  //     axios.post(`${process.env.REACT_APP_API}/admin/setUnread`, { key: "7" }, {
  //       headers: {
  //         "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
  //       }
  //     }).then(res => {
  //       dispatch(updateNotifications({ unreadNotificationCount: 0 }))
  //     })
  //   }
  // }, [notifications])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Notifications | Etoy App</title>
        </MetaTags>
        <Container fluid>
          {success_msg ? (
            <SweetAlert
              title={msg}
              success
              confirmBtnBsStyle="success"
              onConfirm={() => {
                setsuccess_msg(false)
              }}
              onCancel={() => {
                setsuccess_msg(false)
              }}
            >
            </SweetAlert>
          ) : null}
          <Col>
            <Row className="mb-3">
              <Col>
                <Select
                  options={countries ? [{ value: "All", label: "All" }].concat(countries.map(c => {
                    return { value: c.nameEn, label: c.nameEn + ` (${c.users_count})` }
                  })) : null}
                  placeholder="Country"
                  onChange={(e) => countryFilter(e.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col md="12  mt-2">
                <AdminNotifications />
              </Col>
            </Row>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Notifications


const navsStyle = {
  cursor: "pointer",
  borderRadius: 0

}