/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
// import MetaTags from "react-meta-tags"

// datatable related plugins
// import BootstrapTable from "react-bootstrap-table-next"
// import paginationFactory, {
//   PaginationProvider, PaginationListStandalone,
//   SizePerPageDropdownStandalone
// } from "react-bootstrap-table2-paginator"

// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
// import AddUserForm from "./AddUserForm"
import "./datatables.scss"
import "./specification.scss"
import axios from "axios"
// import { toast } from "react-toastify"
import NotificationsPagination from "../../../AdminSection/AdminNotifications/components/AdminNotificationsPagination"
import { useSelector } from "react-redux"

const UserToAdmin = (props) => {
  const notifications = useSelector(state => state.Notifications.notifyCount)
  const [active, setActive] = useState(false)
  // const[productData, setProductData] = useState([])
  const [userToAdmin, setUserToAdmin] = useState([])
  const [editCop, setEditCop] = useState(null)
  const [ids, setIds] = useState([])
  const columns = [{
    dataField: "key",
    text: "Id",
    sort: true
  }, {
    dataField: "userfrom[nameEn]",
    text: "Username",
    sort: true
  }, {
    dataField: "userfrom[country]",
    text: "Country",
    sort: true
  }, {
    dataField: "userfrom[source]",
    text: "Device",
    sort: true
  }, {
    dataField: "message_en",
    text: "Description",
    sort: true
  }, {
    dataField: "actions",
    text: "Actions",
    sort: true
  }, {
    dataField: "created_at",
    text: "Created On",
    sort: true
  }]

  const [pageNo, setPageNo] = useState(1)
  const sizePerPage = 10
  const [totalCount, setTotalCount] = useState(0)

  useEffect(() => {
    bindData()

  }, [pageNo])

  useEffect(() => {
    if (notifications) {
      bindData()
    }
  }, [pageNo, notifications])

  const bindData = async () => {
    // let { pageNo } = filter
    await axios.get(`${process.env.REACT_APP_API}/admin/notifications/getUserToAdmin?page=${pageNo}`, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
      }
    }).then(res => {
      setUserToAdmin(res.data.data.data.map(user => {
        return {
          ...user,
          image: <img src={user.image} className="usernametabl" alt="" />,
          deal: user.deal ? user.deal : <span className="fs-4">-</span>,
          city: user.city ? user.city : "null",
          from: user["userFrom"] ? user["userFrom"]["nameEn"] : "-",
          to: user["userto"] ? user["userto"]["nameEn"] : "-",
          country: user.country ? user.country : <span className="fs-4">-</span>,
          source: user["userFrom"] ? user["userFrom"]["source"]: <span className="fs-4">-</span>,
          message_en: user.message_en ? user.message_en : <span className="fs-4">-</span>,
          message_ar: user.message_ar ? user.message_ar : <span className="fs-4">-</span>,
          actions: <div className="d-flex gap-2">
            <button style={deleteBtn} onClick={(e) => deleteHandler(user.key, user)}><i
              className="bx bx-trash fs-4" /> Delete
            </button>
          </div>
        }
      }))
      setTotalCount(res.data.data.total)
    })
  }

  const onPageHandler = async (type, { page }) => {
    await setPageNo(page)
  }


  //
  // const pagination = paginationFactory({
  //   page: 2,
  //   sizePerPageList: [{
  //     text: "10", value: 10
  //   }, {
  //     text: "25", value: 25
  //   }, {
  //     text: "50", value: 50
  //   }, {
  //     text: "75", value: 75
  //   }, {
  //     text: "100", value: 100
  //   }, {
  //     text: "All", value: productData.length
  //   }],
  //   showTotal: true
  // })


  // // Select All Button operation
  // const selectRow = {
  //   mode: "checkbox",
  //   onSelect: (row, isSelect, rowIndex, e) => {
  //     if (e.target.localName === "input") {
  //       if (isSelect) {
  //         setIds(ids => ids.concat(row.id))
  //       } else {
  //         setIds(ids => ids.filter(id => id !== row.id))
  //       }
  //     }
  //   }
  // }

  // const { SearchBar } = Search;

  // function addProd(data, type) {
  //   setEditCop(null);
  //   if(type === "add"){
  //     setProductData(products => products.concat({...data,
  //       image: <img src={`${data.image}`} alt="" style={{width: "50px", height: "50px", objectFit: "cover"}}/>,
  //       actions: <div style={{display: "flex", gap: "10px"}}>
  //                 <button style={editBtn}  onClick={() => editCoupon(data)}>
  //                   <i className='bx bx-edit fs-4'></i>
  //                   Edit
  //                 </button>
  //                 <button style={deleteBtn} onClick={(e)=>deleteHandler(data.id)}>
  //                   <i className='bx bx-trash fs-4'></i>
  //                   Delete
  //                   </button>
  //               </div>
  //     }))
  //   }else {
  //     console.log(data);
  //     setProductData(products => products.map(prod => prod.id === data.id? {...prod,...data}:prod))
  //   }
  // }


  // function clickHandle () {
  //   setActive(!active)
  //   setEditCop(null);
  // }
  //
  //
  // function editCoupon(data) {
  //   setEditCop(data)
  //   setActive(true)
  //   console.log(data);
  // }


  function deleteHandler(id, user) {
    props.userChanges(id, "uta")
    axios.post(`${process.env.REACT_APP_API}/admin/notifications/delete/usertoadmin`, { id }, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
      }
    })
      .then(res => {
        bindData()
      })

  }

  // function deleteMultiHandler() {
  //   axios.post(`${process.env.REACT_APP_API}/admin/notifications/delete`, { ids }, {
  //     headers: {
  //       "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
  //     }
  //   })
  //     .then(res => {
  //     })
  //   setProductData(products => products.filter(prod => prod.id !== id))
  //   //props.userChanges(user, "delactive")
  //   setMsg("Deleted!")
  //   setsuccess_msg(true)
  // }

  // useEffect(() => {
  //   setProductData(props.usertoadmin.map(user => { return{...user,
  //     image: <img src={user.image} className='usernametabl' alt=""/>,
  //     deal: user.deal?user.deal:<span className="fs-4">-</span>,
  //     city: user.city?user.city: "null",
  //     from: user["userFrom"]?user["userFrom"]["nameEn"]:"-",
  //     to: user["userto"]?user["userto"]["nameEn"]:"-",
  //     actions: <div className="d-flex gap-2">
  //                 <button style={deleteBtn}  onClick={(e)=>deleteHandler(user.key, user)}><i className='bx bx-trash fs-4'></i> Delete</button>
  //             </div>
  //   }}))
  // }, [props.usertoadmin])


  return (
    <React.Fragment>
      <div className="container-fluid p-0">
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody className="pb-0">

                <NotificationsPagination data={userToAdmin} page={pageNo}
                                         sizePerPage={sizePerPage} totalSize={totalCount}
                                         onTableChange={onPageHandler} columns={columns} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      {/*<div className="container-fluid p-0">*/}
      {/*    <AddUserForm active={active} clickHandle={clickHandle} addProd={addProd} editCop={editCop}/>*/}
      {/*  <Row>*/}
      {/*    <Col className="col-12">*/}
      {/*      <Card >*/}
      {/*        <CardBody className="pb-0">*/}
      {/*              <ToolkitProvider*/}
      {/*                keyField='key'*/}
      {/*                columns={columns}*/}
      {/*                data={productData}*/}
      {/*                search*/}
      {/*              >*/}
      {/*                {props => (*/}
      {/*                  <React.Fragment>*/}
      {/*                    <Row className="mb-2">*/}
      {/*                      <Col>*/}
      {/*                        <div className="search-box me-2 mb-2 d-inline-block">*/}
      {/*                          <div className="position-relative ">*/}
      {/*                            <SearchBar*/}
      {/*                              {...props.searchProps}*/}
      {/*                              className="input_cus"*/}
      {/*                            />*/}
      {/*                            <i className="bx bx-search-alt search-icon fs-3 pt-1" />*/}
      {/*                          </div>*/}
      {/*                        </div>*/}
      {/*                      </Col>*/}
      {/*                    </Row>*/}
      {/*                    */}
      {/*                    <Row>*/}
      {/*                      <Col xl="12">*/}
      {/*                        <div className="table-responsive">*/}
      {/*                          <BootstrapTable*/}
      {/*                            keyField={"key"}*/}
      {/*                            responsive*/}
      {/*                            bordered={false}*/}
      {/*                            striped={false}*/}
      {/*                            pagination={pagination}*/}
      {/*                            selectRow={selectRow}*/}
      {/*                            classes={*/}
      {/*                              "table align-middle table-nowrap"*/}
      {/*                            }*/}
      {/*                            headerWrapperClasses={"thead-light"}*/}
      {/*                            {...props.baseProps}*/}
      {/*                          />*/}
      {/*                          {productData.length === 0?<div className="emptyStateTable" style={{*/}
      {/*                              width: '100%',*/}
      {/*                              textAlign: 'center',*/}
      {/*                              fontSize: '16px',*/}
      {/*                              padding: '10px',*/}
      {/*                              paddingTop: '0', paddingBottom: "20px"}}>no data available in table</div>:null}*/}
      {/*                        </div>*/}
      {/*                      </Col>*/}
      {/*                    </Row>*/}
      {/*                  </React.Fragment>*/}
      {/*                )*/}
      {/*                }*/}
      {/*              </ToolkitProvider>*/}
      {/*        </CardBody>*/}
      {/*      </Card>*/}
      {/*    </Col>*/}
      {/*  </Row>*/}
      {/*</div>*/}
    </React.Fragment>
  )
}

export default UserToAdmin

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px"

}

const editBtn = {
  background: "transparent",
  border: "none",
  color: "green",
  display: "flex",
  alignItems: "center",
  gap: "5px"
}

const rej = {
  background: "transparent",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px",
  width: "150px",
  height: "48px",
  border: "1px solid #D91414",
  textAlign: "center",
  justifyContent: "center"
}