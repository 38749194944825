/* eslint-disable react/prop-types */

import React from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator"
import { Col, Row } from "reactstrap"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

const MySearch = ({setFilter}) => {
  let input;
  const handleClick = (value) => {
    setFilter(value)
    
  };
  return (
    <div>
      <input
        style={{ borderRadius: 'none', padding:'10px 10px 10px 35px',border:'1px solid #CCC' }}
        ref={ n => input = n}
        type="text"
        onKeyUp={e=>handleClick(e.target.value)}
      />
    </div>
  );
};
function AdminPagination({ data, keyFiled, page, sizePerPage, onTableChange, totalSize, columns, setFilter }) {

  return (
    <div>
      <PaginationProvider
        pagination={
          paginationFactory({
            custom: true,
            page,
            sizePerPage,
            totalSize
          })
        }
      >
        {
          ({ 
            paginationProps,
            paginationTableProps
          }) => (
            <ToolkitProvider
            
            keyField={keyFiled}
            columns={columns}
            data={data}

          >
            {props=>(
              <>
              <Row className="mb-2">
                  <Col>
                    <div className="search-box me-2 mb-2 d-inline-block">
                      <div className="position-relative ">
                        <MySearch setFilter={setFilter} />
                        <i className="bx bx-search-alt search-icon fs-3 pt-1" />
                      </div>
                    </div>
                  </Col>
              </Row>
              <Row>
                <Col xl="12">
                  <div className={"table-responsive"}>
                    <BootstrapTable
                    remote
                      {...props.baseProps}
                      onTableChange={onTableChange}
                      classes={
                        "table align-middle table-nowrap"
                      }
                      headerWrapperClasses={"thead-light"}
                      {...paginationTableProps}
                    />
                    {data.length === 0?<div className="emptyStateTable" style={{
                      width: '100%',
                      textAlign: 'center',
                      fontSize: '16px',
                      padding: '10px',
                      paddingTop: '0', paddingBottom: "20px"}}>no data available in table</div>:null}

                    {data.length !== 0 ? <Row className="align-items-md-center mt-30">
                      <Col className="inner-custom-pagination d-flex">
                        <div className="text-md-right ms-auto">
                          <PaginationListStandalone
                            {...paginationProps}
                          />
                        </div>
                      </Col>
                    </Row> : null}
                  </div>
                </Col>
              </Row>
              </>
              
            )}
            
            </ToolkitProvider>
          )
        }
      </PaginationProvider>
    </div>
  )
}

export default AdminPagination