/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"



import "./datatables.scss"
import "./specification.scss"
import axios from "axios"
import PulseLoader from "react-spinners/PulseLoader"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import SweetAlert from "react-bootstrap-sweetalert"
import { useSelector } from "react-redux";
import {  useDispatch } from "react-redux"
import {
  setTitle,
} from "../../../store/actions"

import AdminPagination from "pages/AdminSection/AdminNotifications/components/AdminPagination"

  const RequestAToy = () => {
    const dispatch = useDispatch()
  const notifications = useSelector(state => state.Notifications.notifyCount)
  const [active, setActive] = useState(false)
  const [productData, setProductData] = useState([])
  const [editCop, setEditCop] = useState(null)
  const [isOpen, setisOpen] = useState(false)
  const [toy, setToy] = useState(null)
  const [prev, setPrev] = useState("")
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  const [ids, setIds] = useState([])
  const [pageNo, setPageNo] = useState(1)
  const sizePerPage = 10
  const [totalCount, setTotalCount] = useState(0)
  const [filter, setFilter]= useState('')
  const [loading, setLoading] = useState(false)
  
  const columns = [{
    dataField: "id",
    text: "Id",
    sort: true
  },
  {
    dataField: "name",
    text: "User Name",
    sort: true
  },
  {
    dataField: "email",
    text: "User Email",
    sort: true
  },
  {
    dataField: "country",
    text: "User Country",
    sort: true
  },
  {
    dataField: "gender",
    text: "Gender",
    sort: true
  },
  {
    dataField: "descriptionEn",
    text: "Description",
    sort: true
  },
  {
    dataField: "age",
    text: "Age",
    sort: true
  },
  {
    dataField: "category",
    text: "Category",
    sort: true
  },
  {
    dataField: "status",
    text: "Status",
    sort: true
  },
  {
    dataField: "actions",
    text: "Actions",
    sort: true
  }, 
   {
    dataField: "created_at",
    text: "Created On",
    sort: true
  }]

  useEffect(() => {
    dispatch(setTitle("Request Toy"))
    bindData()
  }, [pageNo, filter, notifications])

 




  const bindData = async() => {
    await axios.get(`${process.env.REACT_APP_API}/admin/requestToys?page=${pageNo}&search=${filter}`, {
          headers: {
              'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`, 
          }
        })
    .then(res => {
        const resData = res.data.data.request_toys.data
        setProductData(resData.map(user => {
          return {
            ...user,
            id: user.id ? user.id : <span className="fs-4">-</span>,
            name: user.name ? user.name : <span className="fs-4">-</span>,
            email: user.email ? user.email : <span className="fs-4">-</span>,
            country: user.country ? user.country : <span className="fs-4">-</span>,
            category: user?.categories.length !== 0? user?.categories.map((c,i)=> <span key={i} className={`${c.nameEn===1?'':'image-opacity'}`}  style={{backgroundColor: 'rgb(216 216 216)',
            color: 'rgb(0, 0, 0)',
            borderRadius: '45px',
            padding: '5px 20px',
            margin: '0px 2px',
            display: 'inline-block'}}>{c.nameEn}</span>
            ):<span className="fs-4">-</span>,

            age: user?.ages.length !== 0? user?.ages.map((c,i)=> <span key={i} className={`${c.nameEn===1?'':'image-opacity'}`}  style={{backgroundColor: 'rgb(216 216 216)',
            color: 'rgb(0, 0, 0)',
            borderRadius: '45px',
            padding: '5px 20px',
            margin: '0px 2px',
            display: 'inline-block'}}>{c.age}</span>
            
            ):<span className="fs-4">-</span>,
            status: user.status == 1 ? <span className=" bg-success" style={{
            color: '#fff',
            borderRadius: '45px',
            padding: '5px 20px',
            margin: '0px 2px',
            fontSize: '1em',
            display: 'inline-block'}}>Sent</span>: <span className="bg-danger" style={{
              color: '#fff',
              borderRadius: '45px',
              padding: '5px 20px',
              margin: '0px 2px',
              fontSize: '1em',
              display: 'inline-block'}}>Pending</span>,
            actions: <div className="d-flex gap-2">
              <button style={deleteBtn} onClick={(e) => deleteHandler(user.id, user)}><i
                className="bx bx-trash fs-4" /> Delete
              </button>
            </div>
          }
        }))
        setTotalCount(res.data.data.request_toys.total)

    })
  }

  const onPageHandler =  (type, { page }) => {
    setPageNo(page)
 }

  function setOpen(img) {
    setisOpen(true)
    setPrev(img)
  }

// Select All Button operation
  const selectRow = {
    mode: "checkbox",

    onSelect: (row, isSelect, rowIndex, e) => {
      if (e.target.localName === "input") {
        if (isSelect) {
          setIds(ids => ids.concat(row.id))
        } else {
          setIds(ids => ids.filter(id => id !== row.id))
        }
      }
    }
  }

  function deleteMultiHandler() {
    axios.post(`${process.env.REACT_APP_API}/admin/toys/delete`, { ids }, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
      }
    })
      .then(res => {
      })
   
    setMsg("Deleted!")
    setsuccess_msg(true)
    setIds([])
  }


  

  
  


  function deleteHandler(id, user) {
    
    axios.post(`${process.env.REACT_APP_API}/admin/requestToys/delete`, { id }, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
      }
    })
      .then(res => {
        bindData()
        setMsg("Deleted!")
        setsuccess_msg(true)
      })
    
  }

  

  
  function mssg() {
    setMsg("Toy Added!")
    setsuccess_msg(true)
  }




  return (
    <React.Fragment>
      <div className="container-fluid p-0">
        {success_msg ? (
          <SweetAlert
            title={msg}
            success
            confirmBtnBsStyle="success"
            onConfirm={() => {
              setsuccess_msg(false)
            }}
            onCancel={() => {
              setsuccess_msg(false)
            }}
          >
          </SweetAlert>
        ) : null}
        {isOpen ? (
          <Lightbox
            mainSrc={prev}
            enableZoom={false}
            onCloseRequest={() => {
              setisOpen(!isOpen);
            }}
          />
        ) : null}
       
       
        <Row style={{ marginTop: "100px" }}>
          <Col className="col-12">
            <Card>
              <CardBody className="pb-0">
              <AdminPagination
                data={productData} page={pageNo}
                keyFiled='id'
                setFilter={setFilter}
                sizePerPage={sizePerPage} totalSize={totalCount}
                onTableChange={onPageHandler} columns={columns} />

              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default RequestAToy

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center"
}

const editBtn = {
  background: "transparent",
  border: "none",
  color: "#000",
  display: "flex",
  alignItems: "center"
}


const rej = {
  background: "transparent",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px",
  width: "150px",
  height: "48px",
  border: "1px solid #D91414",
  textAlign: "center",
  justifyContent: "center"
}