import React, {useEffect, useState} from "react"
import MetaTags from 'react-meta-tags';
import classnames from "classnames";
import {
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from "reactstrap";
import Sticky from 'react-sticky-el';
import Select from 'react-select'
import PendingUsers from './Components/PendingUsers'
import ActiveUsers from './Components/ActiveUsers'
import UnActiveUsers from './Components/UnActiveUsers'
import './configration.scss'
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import {
    setTitle,
    updateNotifications
} from "../../../store/actions";
import { useHistory } from "react-router-dom";

function PaperUsers() {
    const dispatch = useDispatch()
    const   countries = useSelector(state => state.MainData.countries)
    const   user = useSelector(state => state.UserData.user)
    const history = useHistory()
    // const [category, setCategory] = useState([])
    const [activeCount, setActiveCount] = useState([])
    const [unActiveCount, setUnActiveCount] = useState([])
    const [pendingCount, setPendingCount] = useState([])
    const [users, setUsers] = useState([])
    const [isread, setIsRead] = useState(0)
    const [verticalActiveTab, setverticalActiveTab] = useState("1");
    const [country, setCountry] = useState('')

    const toggleVertical = tab => {
        if (verticalActiveTab !== tab) {
            setverticalActiveTab(tab);
        }
        if(tab === "2" && isread === 1) {
            axios.post(`${process.env.REACT_APP_API}/admin/users/redDot`, {}, {
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`, 
                }
            })
            setIsRead(0) 
        }
    };

    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]

    useEffect(() => {
        if(user && user.role == 2) {
            history.push('/dashboard')
            return;
        }
        dispatch(setTitle("Users"))
        axios.post(`${process.env.REACT_APP_API}/admin/setUnread`, {key: "1"},{
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`, 
            }
        }).then(res => {
            dispatch(updateNotifications({unreadUserCount: 0}))
        })
    }, [user])

    function update(user) {
        setActive(users => users.map(t => {
            if(t.key === user.key) {
                return user;
            }
            return t;
        }))
    }


    return (
        <React.Fragment>
            <div className="page-content">
            <MetaTags>
                <title>Users | Etoy App</title>
            </MetaTags>
            <Container fluid>
            <Col>
                <Row className="mb-3">
                    <Col>
                        <Select 
                            options={countries?[{value:"All", label:"All"}].concat(countries.map(c => {return {value: c.nameEn, label: c.nameEn + ` (${c.users_count})`} })):null}
                            placeholder="Country"
                            onChange={(e) => countryFilter(e.value)}
                        />
                    </Col> 
                </Row>
                <Row>
                    <Col md="12  mt-2">
                        <TabContent
                        activeTab={verticalActiveTab}
                        className="text-muted table__content mt-4 mt-md-0"
                        >
                            <TabPane tabId="1">
                                <ActiveUsers setActiveCount={setActiveCount} country={country} update={update}/>
                            </TabPane>
                        </TabContent>
                    </Col>
                </Row>
            </Col>
            </Container>
            </div>
        </React.Fragment>
)
}

export default PaperUsers


const navsStyle = { 
    cursor: "pointer", 
    borderRadius: 0,
}