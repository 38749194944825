/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import AddUserForm from "./AddUserForm"
import "./datatables.scss"
import "./specification.scss"
import axios from "axios"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import SweetAlert from "react-bootstrap-sweetalert"
import { useSelector } from "react-redux"
import AdminPagination from "pages/AdminSection/AdminNotifications/components/AdminPagination"
import NotificationForm from "pages/EToyApp/Reports/NotificationForm"

const ActiveUsers = props => {
  const [active, setActive] = useState(false)
  const [activeNotification, setActiveNotification] = useState(false)
  const [productData, setProductData] = useState([])
  const [editCop, setEditCop] = useState(null)
  const [isOpen, setisOpen] = useState(false)
  const [prev, setPrev] = useState("")
  const [user, setUser] = useState(null)
  const [userId, setUserId] = useState(null)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  const notifications = useSelector(state => state.Notifications.notifyCount)
  const [filter, setFilter] = useState("")

  const columns = [
    {
      dataField: "key",
      text: "Id",
      sort: true,
    },
    {
      dataField: "image",
      text: "Image",
      sort: true,
    },
    {
      dataField: "nameEn",
      text: "Name",
      sort: true,
    },
    {
      dataField: "toys",
      text: "# of toys",
      sort: true,
    },
    {
      dataField: "language",
      text: "Language",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "gender",
      text: "Gender",
      sort: true,
    },
    {
      dataField: "source",
      text: "Device",
      sort: true,
    },
    {
      dataField: "appVersion",
      text: "App Version",
      sort: true,
    },
    {
      dataField: "deviceVersion",
      text: "Device Version",
      sort: true,
    },
    {
      dataField: "country",
      text: "Country",
      sort: true,
    },
    {
      dataField: "city",
      text: "City",
      sort: true,
    },
    {
      dataField: "mobile",
      text: "Mobile",
      sort: true,
    },
    {
      dataField: "longitude",
      text: "Longitude",
      sort: true,
    },
    {
      dataField: "latitude",
      text: "Latitude",
      sort: true,
    },
    {
      dataField: "actions",
      text: "Actions",
      sort: true,
    },
    {
      dataField: "points",
      text: "Points",
      sort: true,
    },
    {
      dataField: "created_at",
      text: "Created On",
      sort: true,
    },
  ]

  function clickHandleNotification(id, type) {
    setUserId({ id, lang: "en" })
    setActiveNotification(type)
    setEditCop(null)
  }

  function setOpen(img) {
    setisOpen(true)
    setPrev(img)
  }

  function addProd(data, type) {
    setEditCop(null)
    if (type === "add") {
      setProductData(products =>
        products.concat({
          ...data,
          actions: (
            <div>
              <button style={editBtn} onClick={() => editCoupon(data)}>
                <i className="bx bx-edit fs-4" />
              </button>
              <button
                style={deleteBtn}
                id={data.id}
                onClick={e => deleteHandler(e.currentTarget.id)}
              >
                <i className="bx bx-trash fs-4" />
              </button>
            </div>
          ),
        })
      )
    } else {
      setProductData(products =>
        products.map(prod =>
          prod.id === data.id ? { ...prod, ...data } : prod
        )
      )
    }
  }

  function editCoupon(data) {
    setEditCop(data)
    setActive(true)
  }

  function deleteHandler(id, user) {
    axios
      .post(
        `${process.env.REACT_APP_API}/admin/users/delete`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).authToken
            }`,
          },
        }
      )
      .then(res => {})
    setProductData(products => products.filter(prod => prod.key !== id))
    setMsg(`${user.nameEn} Deleted!`)
    setsuccess_msg(true)
  }

  function deActiveHandler(id, user) {
    axios
      .post(
        `${process.env.REACT_APP_API}/admin/users/deactive`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).authToken
            }`,
          },
        }
      )
      .then(res => {
        setMsg(`Deactive ${user.nameEn}`)
        setsuccess_msg(true)
        bindData()
        props.updateState(id + "de")
      })
  }

  function edit(user, type) {
    setUser(user)
    setActive(type)
  }

  function update(user) {
    setProductData(users =>
      users.map(t => {
        if (t.key === user.key) {
          return {
            ...user,
            image: (
              <img
                src={user.image}
                onClick={e => setOpen(e.target.src)}
                className="usernametabl"
                alt=""
              />
            ),
            city: user.city ? user.city : <span className="fs-4">-</span>,
            nameEn: user.nameEn ? user.nameEn : <span className="fs-4">-</span>,
            appVersion: user.appVersion ? (
              user.appVersion
            ) : (
              <span className="fs-4">-</span>
            ),
            deviceVersion:
              user.deviceVersion != null ? (
                user.deviceVersion
              ) : (
                <span className="fs-4">-</span>
              ),
            email: user.email ? user.email : <span className="fs-4">-</span>,
            gender: user.gender ? user.gender : <span className="fs-4">-</span>,
            country: user.country ? (
              user.country
            ) : (
              <span className="fs-4">-</span>
            ),
            longitude: user.longitude ? (
              user.longitude
            ) : (
              <span className="fs-4">-</span>
            ),
            latitude: user.latitude ? (
              user.latitude
            ) : (
              <span className="fs-4">-</span>
            ),
            points: user.points ? user.points : <span className="fs-4">-</span>,
            toys: user.toysCount ? (
              user.toysCount
            ) : (
              <span className="fs-4">-</span>
            ),
            language: user.language ? (
              user.language
            ) : (
              <span className="fs-4">-</span>
            ),
            actions: (
              <div className="d-flex gap-2">
                <button
                  style={editBtn}
                  onClick={e => clickHandleNotification(user.key, true)}
                >
                  <i className="bx bx-bell fs-4" />
                  Send Notification
                </button>
                <button style={editBtn} onClick={() => edit(user, true)}>
                  <i
                    className="bx bx-edit fs-4 pr-1"
                    style={{ marginRight: "10px" }}
                  />{" "}
                  Edit
                </button>
                <button
                  style={deleteBtn}
                  onClick={e => deleteHandler(user.key, user)}
                >
                  <i
                    className="bx bx-trash fs-4"
                    style={{ marginRight: "10px" }}
                  />{" "}
                  Delete
                </button>
                <button
                  style={{ ...deleteBtn }}
                  onClick={e => deActiveHandler(user.key, user)}
                >
                  <i
                    className="fas fa-times fs-5"
                    style={{ marginRight: "10px" }}
                  />{" "}
                  Deactive
                </button>
              </div>
            ),
          }
        }
        return t
      })
    )
  }

  const [pageNo, setPageNo] = useState(1)
  const sizePerPage = 10
  const [totalCount, setTotalCount] = useState(0)

  useEffect(() => {
    bindData()
  }, [pageNo, filter, notifications, props.activeState])

  useEffect(() => {
    setFilter(props.country)
  }, [props.country])

  const bindData = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API}/admin/activeusers?page=${pageNo}&search=${filter}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).authToken
            }`,
          },
        }
      )
      .then(res => {
        const resData = res.data.data.active.data
        setProductData(
          resData.map(user => {
            return {
              ...user,
              image: (
                <img
                  src={user.image}
                  onClick={e => setOpen(e.target.src)}
                  className="usernametabl"
                  alt=""
                />
              ),
              city: user.city ? user.city : <span className="fs-4">-</span>,
              nameEn: user.nameEn ? (
                user.nameEn
              ) : (
                <span className="fs-4">-</span>
              ),
              appVersion: user.appVersion ? (
                user.appVersion
              ) : (
                <span className="fs-4">-</span>
              ),
              deviceVersion:
                user.deviceVersion != null ? (
                  user.deviceVersion
                ) : (
                  <span className="fs-4">-</span>
                ),
              email: user.email ? user.email : <span className="fs-4">-</span>,
              gender: user.gender ? (
                user.gender
              ) : (
                <span className="fs-4">-</span>
              ),
              country: user.country ? (
                user.country
              ) : (
                <span className="fs-4">-</span>
              ),
              longitude: user.longitude ? (
                user.longitude
              ) : (
                <span className="fs-4">-</span>
              ),
              latitude: user.latitude ? (
                user.latitude
              ) : (
                <span className="fs-4">-</span>
              ),
              points: user.points ? (
                user.points
              ) : (
                <span className="fs-4">-</span>
              ),
              toys: user.toysCount ? (
                user.toysCount
              ) : (
                <span className="fs-4">-</span>
              ),
              actions: (
                <div className="d-flex gap-2">
                  <button
                    style={editBtn}
                    onClick={e => clickHandleNotification(user.key, true)}
                  >
                    <i
                      className="bx bx-bell fs-4 pr-1"
                      style={{ marginRight: "10px" }}
                    />{" "}
                    Send Notification
                  </button>
                  <button style={editBtn} onClick={() => edit(user, true)}>
                    <i
                      className="bx bx-edit fs-4 pr-1"
                      style={{ marginRight: "10px" }}
                    />{" "}
                    Edit
                  </button>
                  <button
                    style={deleteBtn}
                    onClick={e => deleteHandler(user.key, user)}
                  >
                    <i
                      className="bx bx-trash fs-4"
                      style={{ marginRight: "10px" }}
                    />{" "}
                    Delete
                  </button>
                  <button
                    style={{ ...deleteBtn }}
                    onClick={e => deActiveHandler(user.key, user)}
                  >
                    <i
                      className="fas fa-times fs-5"
                      style={{ marginRight: "10px" }}
                    />{" "}
                    Deactive
                  </button>
                </div>
              ),
            }
          })
        )
        setTotalCount(res.data.data.active.total)
        props.setActiveCount(res.data.data.counter)
      })
  }

  const onPageHandler = (type, { page }) => {
    setPageNo(page)
  }

  return (
    <React.Fragment>
      <div className="container-fluid p-0">
        {success_msg ? (
          <SweetAlert
            title={msg}
            success
            confirmBtnBsStyle="success"
            onConfirm={() => {
              setsuccess_msg(false)
            }}
            onCancel={() => {
              setsuccess_msg(false)
            }}
          ></SweetAlert>
        ) : null}
        {isOpen ? (
          <Lightbox
            mainSrc={prev}
            enableZoom={false}
            onCloseRequest={() => {
              setisOpen(!isOpen)
            }}
          />
        ) : null}
        <NotificationForm
          clickHandle={clickHandleNotification}
          active={activeNotification}
          userData={userId}
        />
        <AddUserForm
          active={active}
          clickHandle={edit}
          addProd={addProd}
          editCop={editCop}
          user={user}
          update={update}
        />
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody className="pb-0">
                <AdminPagination
                  data={productData}
                  page={pageNo}
                  setFilter={setFilter}
                  keyFiled="key"
                  sizePerPage={sizePerPage}
                  totalSize={totalCount}
                  onTableChange={onPageHandler}
                  columns={columns}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default ActiveUsers

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
}

const editBtn = {
  background: "transparent",
  border: "none",
  color: "#000",
  display: "flex",
  alignItems: "center",
}

const rej = {
  background: "transparent",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px",
  width: "150px",
  height: "48px",
  border: "1px solid #D91414",
  textAlign: "center",
  justifyContent: "center",
}
