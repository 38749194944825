/* eslint-disable react/prop-types */
import axios from "axios"
import React, { useState, useRef, useEffect } from "react"
import { Col, Form, FormFeedback, Input, Label, Row } from "reactstrap"
import PulseLoader from "react-spinners/PulseLoader"
import ReactTagInput from "@pathofdev/react-tag-input"
import "@pathofdev/react-tag-input/build/index.css"
import { Link } from "react-router-dom"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

import "../../../EToyApp/Toys/Components/specification.scss"
import Select from "react-select"
import { useSelector } from "react-redux"


const AddForm = (props) => {
  const categories = useSelector(state => state.MainData.categories)
  let p = props
  const [isOpen, setisOpen] = useState(false)
  const [name_en, setNameEn] = useState("")
  const [name_ar, setNameAr] = useState("")
  const [description_en, setDescriptionEn] = useState("")
  const [description_ar, setDescriptionAr] = useState("")
  const [age, setAge] = useState("")
  // const [status, setStatus] = useState('')
  const [gender, setGender] = useState("")
  const [deal, setDeal] = useState("")
  const [price, setPrice] = useState("")
  const [country, setCountry] = useState("")
  const [category_id, setCategory] = useState({ value: "select", label: "Select" })
  const [placeholder, setHolder] = useState("Select")
  const [pickup_address_en, setPickEn] = useState("")
  const [pickup_address_ar, setPickAr] = useState("")
  const [image, setImage] = useState(null)
  const [loading, setLoading] = useState(false)

  const imageRef = useRef(null)


  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size)
      })
    )
    setImage(files[0])
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  function submitHandler() {
    setLoading(true)
    const formData = new FormData()
    let data = {
      name_en,
      name_ar,
      description_en,
      description_ar,
      gender,
      // status,
      deal,
      category_id: category_id.value,
      age,
      image
    }
    for (let key in data) {
      formData.append(key, data[key])
    }
    axios.post(`${process.env.REACT_APP_API}/admin/toys/store`, formData, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`,
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        setLoading(false)
        if (res.data.error) {
          return
        }
        props.addProd(res.data.data, "add")
        props.mssg()
        props.clickHandle()
        setNameEn("")
        setNameAr("")
        setDescriptionEn("")
        setDescriptionAr("")
        setPickEn("")
        setPickAr("")
        setAge("")
        setCountry("")
        setCategory({ value: "select", label: "Select" })
        setDeal("")
        setGender("")
        // setStatus('')
        setImage(null)
        setPrice("")
        setHolder("Select")
        document.getElementById("toyForm").reset()
      })
  }

  return (
    <div className={`overlay_coup ${p.active ? "active" : ""}`}>
      {isOpen ? (
        <Lightbox
          mainSrc={image.preview}
          enableZoom={false}
          onCloseRequest={() => {
            setisOpen(!isOpen)
          }}
        />
      ) : null}
      <Form id="toyForm" onSubmit={(e) => e.preventDefault()} style={{ paddingBottom: "70px", height: "580px" }}>
        <div className="head_form">
          <div className="log fs-5">
            {/* <i className="fas fa-user fs-5"></i> */}
            Add Toy
          </div>
          <button onClick={() => p.clickHandle()}><i className="dripicons dripicons-cross"></i></button>
        </div>
        <div className="inputs__wrap ">
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">
                  Toy Title (English)
                </Label>
                <Input
                  id="title"
                  name="title"
                  type="text"
                  className="form-control input_cus"
                  placeholder="e.g.Name"
                  value={name_en}
                  onChange={e => setNameEn(e.target.value)}
                />
                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">
                  Toy Title (Arabic)
                </Label>
                <Input
                  id="title"
                  name="title"
                  type="text"
                  className="form-control input_cus"
                  placeholder="مثال. الاسم"
                  dir="rtl"
                  value={name_ar}
                  onChange={e => setNameAr(e.target.value)}
                />
                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">
                  Description (English)
                </Label>
                <Input
                  name="text"
                  type="textarea"
                  value={description_en}
                  onChange={e => setDescriptionEn(e.target.value)}
                />
                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">
                  Description (Arabic)
                </Label>
                <Input
                  name="text"
                  type="textarea"
                  value={description_ar}
                  onChange={e => setDescriptionAr(e.target.value)}
                  dir="rtl"
                />
                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
              </div>
            </Col>
          </Row>
          {props.admin ? null : <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">
                  Pickup address (English)
                </Label>
                <Input
                  name="text"
                  type="textarea"
                  value={pickup_address_en}
                  onChange={e => setPickEn(e.target.value)}
                />
                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
              </div>
            </Col>
          </Row>}
          {props.admin ? null : <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">
                  Pickup address (Arabic)
                </Label>
                <Input
                  name="text"
                  type="textarea"
                  value={pickup_address_ar}
                  onChange={e => setPickAr(e.target.value)}
                  dir="rtl"
                />
                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
              </div>
            </Col>
          </Row>}
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">
                  Age
                </Label>
                <select
                  className="form-select fs-5 invalid input_cus"
                  value={age}
                  onChange={e => setAge(e.target.value)}
                >
                  <option>Select</option>
                  <option value="+0">0 - 2</option>
                  <option value="+2">2 - 4</option>
                  <option value="+4">4 - 6</option>
                  <option value="+6">6 - 8</option>
                  <option value="+8">8 - 10</option>
                  <option value="+8">8 - 10</option>
                </select>
                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
              </div>
            </Col>
          </Row>
          {props.admin ? null : <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">
                  Country
                </Label>
                <Select
                  options={countries ? countries.map(c => {
                    return { value: c.id, label: c.nameEn }
                  }) : null}
                  placeholder={"Country"}
                  onChange={(e) => setCountry(e.value)}
                />
                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
              </div>
            </Col>
          </Row>}
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">
                  Toy category
                </Label>
                <Select
                  options={categories ? [{ value: "select", label: "Select" }].concat(categories.map(c => {
                    return { value: c.key, label: c.nameEn }
                  })) : null}
                  value={category_id.label}
                  placeholder={category_id.label}
                  onChange={(e) => setCategory(e)}
                />
                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
              </div>
            </Col>
          </Row>
          {/*<Row>*/}
          {/*    <Col>*/}
          {/*        <div className="mb-3" >*/}
          {/*            <Label htmlFor="productdesc">*/}
          {/*                Status*/}
          {/*            </Label>*/}
          {/*            <select  */}
          {/*                className="form-select fs-5 invalid input_cus"*/}
          {/*                value={status}*/}
          {/*                onChange= {e => setStatus(e.target.value)}*/}
          {/*            >*/}
          {/*                <option>Select</option>*/}
          {/*                <option value="new">New</option>*/}
          {/*                <option value="used">Used</option>*/}
          {/*            </select>*/}
          {/*            <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>*/}
          {/*        </div>*/}
          {/*    </Col>*/}
          {/*</Row>*/}
          {props.admin ? null : <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">
                  Purpose
                </Label>
                <select
                  className="form-select fs-5 invalid input_cus"
                  value={deal}
                  onChange={e => setDeal(e.target.value)}
                >
                  <option>Select</option>
                  <option value="swap">swap</option>
                  <option value="sale">sale</option>
                  <option value="free">free</option>
                </select>
                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
              </div>
            </Col>
          </Row>}
          {deal === "sale" && <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">
                  Price
                </Label>
                <Input
                  id="title"
                  name="title"
                  type="text"
                  className="form-control input_cus"
                  placeholder="e.g.Name"
                  value={price}
                  onChange={e => setPrice(e.target.value)}
                />
                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
              </div>
            </Col>
          </Row>}

          <Row>
            <Label htmlFor="productdesc mb-2">Gender</Label>
            <Col xs={4}>
              <div className="mb-3">
                <Label htmlFor="productdesc" className="radio">
                  <Input
                    id="title"
                    name="gender"
                    type="radio"
                    className="form-control"
                    placeholder=""
                    value="male"
                    onChange={e => setGender(e.target.value)}
                  />
                  Male
                </Label>
                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
              </div>
            </Col>
            <Col xs={4}>
              <div className="mb-3">
                <Label htmlFor="productdesc" className="radio">
                  <Input
                    id="title"
                    name="gender"
                    type="radio"
                    className="form-control"
                    placeholder=""
                    value="female"
                    onChange={e => setGender(e.target.value)}
                  />
                  Female
                </Label>

                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
              </div>
            </Col>
            <Col xs={4}>
              <div className="mb-3">
                <Label htmlFor="productdesc" className="radio">
                  <Input
                    id="title"
                    name="gender"
                    type="radio"
                    className="form-control"
                    placeholder=""
                    value="both"
                    onChange={e => setGender(e.target.value)}
                  />
                  Both
                </Label>

                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
              </div>
            </Col>
          </Row>


          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="metatitle">Toy Image</Label>
                <div className="mb-3" ref={imageRef}>
                  <Input
                    type="file"
                    className="form-control form-control-lg "
                    onChange={e => handleAcceptedFiles(Object.values(e.target.files), e.target.value = "")}
                    style={{ borderRadius: 0 }}
                  />
                  <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                </div>
              </div>
            </Col>
            {image ? <Row className="align-items-center" style={{ paddingLeft: "14px", paddingRight: "0px" }}>
              <Col className="col-auto">
                <img
                  data-dz-thumbnail=""
                  height="80"
                  className="avatar-sm rounded bg-light"
                  alt={image.name}
                  src={image.preview}
                  style={{ objectFit: "cover" }}
                />
              </Col>
              <Col>
                <Link
                  to="#"
                  className="text-muted "
                >
                  {image.name}
                </Link>
                <p className="mb-0">
                  <strong>{image.formattedSize}</strong>
                </p>
              </Col>
              <Col className="p-0">
                <div className="image__options">
                  <button className="p-0" onClick={(e) => setisOpen(true)}><i className="far fa-eye  fs-5 p-0" />
                  </button>
                  <button className="p-0" style={{ color: "#ff3838" }} onClick={(e) => setImage(null)}><i
                    className="bx bx-trash fs-4" /></button>
                </div>
              </Col>
            </Row> : null}
          </Row>
        </div>
        <div className="foot_form">
          <button className="cancel" onClick={() => p.clickHandle()}>Cancel</button>
          <button className="save" onClick={submitHandler}>{loading ?
            <PulseLoader color={"#ffffff"} loading={true} size={10} /> : "Save"}</button>
        </div>
      </Form>
    </div>
  )
}

export default AddForm
