/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Form, Label, Input, FormFeedback, Collapse, Container } from "reactstrap"
import classnames from "classnames"
import MetaTags from "react-meta-tags"
import Select from "react-select"

import { Link } from "react-router-dom"
import axios from "axios"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import { useDispatch } from "react-redux"
import {
  setTitle
} from "../../../store/actions"
import SweetAlert from "react-bootstrap-sweetalert"
import ReactLoading from "react-loading"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

const Advertises = (props) => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.UserData.user)
  const history = useHistory()
  const countries = useSelector(state => state.MainData.countries)
  const [rows2, setrows2] = useState([])
  const [active, setActive] = useState(false)
  const [deleteLoad, setDelete] = useState(false)
  const [image, setImage] = useState(null)
  const [isOpen, setisOpen] = useState(false)
  const [col, setCol] = useState([])
  const [data, setData] = useState([])
  const [news, setNews] = useState(null)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  const [activeBtn, setActiveBtn] = useState(false)
  const [prev, setPrev] = useState(null)


  const t_col1 = (id) => {
    setCol(col => col.map((c, i) => {
      if (i === id) {
        return !c
      }
      return false
    }))
    setData(data => data.concat({
      name: "",
      link: "",
      country: "",
      status: 0,
      description: "",
      arrange: "",
      start_date: "",
      end_date: "",
      image: null
    }))
    setCol(state => state.concat(false))
  }

  function handleChange(value, key, type) {
    switch (type) {
      case "name":
        setData(data => data.map((obj, i) => {
          if (i === key) {
            return {
              ...obj,
              name: value
            }
          }
          return obj
        }))
        break
      case "link":
        setData(data => data.map((obj, i) => {
          if (i === key) {
            return {
              ...obj,
              link: value
            }
          }
          return obj
        }))
        break
      case "country":
        setData(data => data.map((obj, i) => {
          if (i === key) {
            return {
              ...obj,
              country: value
            }
          }
          return obj
        }))
        break
      case "desc":
        setData(data => data.map((obj, i) => {
          if (i === key) {
            return {
              ...obj,
              description: value
            }
          }
          return obj
        }))
        break
      case "start":
        setData(data => data.map((obj, i) => {
          if (i === key) {
            return {
              ...obj,
              start_date: value
            }
          }
          return obj
        }))
        break
      case "end":
        setData(data => data.map((obj, i) => {
          if (i === key) {
            return {
              ...obj,
              end_date: value
            }
          }
          return obj
        }))
        break
      case "status":
        setData(data => data.map((obj, i) => {
          if (i === key) {
            return {
              ...obj,
              status: value
            }
          }
          return obj
        }))
        break
      case "arr":
        setData(data => data.map((obj, i) => {
          if (i === key) {
            return {
              ...obj,
              arrange: value
            }
          }
          return obj
        }))
        break
      default:
        break
    }
  }


  function handleAddRowNested() {
    const modifiedRows = [...rows2]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setrows2(modifiedRows)
    setData(data => data.concat({
      name: "",
      link: "",
      status: "",
      description: "",
      arrange: "",
      start_date: "",
      end_date: "",
      image: null
    }))
    setCol(state => state.concat(false))
    setNews(modifiedRows.length)
    modifiedRows.length > 10 ? setActiveBtn(true) : setActiveBtn(false)
  }


  function handleRemoveRow(id) {
    if (id !== 0) {
      var modifiedRows = [...rows2]
      modifiedRows = modifiedRows.filter(x => x["id"] !== id)
      setrows2(modifiedRows)
      setData(data => data.filter((d, i) => i !== id))
      data.length <= 10 ? setActiveBtn(true) : setActiveBtn(false)
    }
  }

  function handleAcceptedFiles(files, key, e) {
    e.target.value = ""
    // console.log(e.target);
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size)
      })
    )
    setData(data => data.map((obj, i) => {
      if (i === key) {
        return {
          ...obj,
          image: files[0]
        }
      }
      return obj
    }))
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }


  function setOpen(e, img) {
    e.preventDefault()
    setPrev(img)
    setisOpen(true)

  }

  function deleteImage(key) {
    setData(data => data.map((obj, i) => {
      if (i === key) {
        return {
          ...obj,
          image: null
        }
      }
      return obj
    }))
  }

  function submit(e, id) {
    e.preventDefault()
    setActive(true)
    const formData = new FormData()
    let d = data[id]
    let image = data[id].image
    delete d["image"]

    for (let key in d) {
      formData.append(key, d[key])
    }
    if (d.key) {
      formData.append("id", d.key)
    }

    if (image && image.name) {
      formData.append("image", image)
    }

    axios.post(!d.key ? `${process.env.REACT_APP_API}/admin/advertises/store` : `${process.env.REACT_APP_API}/admin/advertises/update`, formData, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
      }
    })
      .then(res => {
        if (res.data.data) {
          setData(data => data.map((d, i) => i === id ? {
            ...res.data.data,
            image: { preview: res.data.data.image }
          } : d))
          setMsg(`Saved!`)
          setsuccess_msg(true)
        }
        setActive(false)
      })
    news === id + 1 ? setNews(null) : null
  }


  function deleteAds(e, id) {
    e.preventDefault()
    setDelete(true)
    axios.post(`${process.env.REACT_APP_API}/admin/advertises/delete`, { id: data[id].key }, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
      }
    })
      .then(res => {
        handleRemoveRow(id)
        setDelete(false)
        setMsg(`Deleted!`)
        setsuccess_msg(true)
      })
  }

  useEffect(() => {
    if (user && user.role === 2) {
      history.push("/")
      return
    }
  }, [user])

  useEffect(() => {
    dispatch(setTitle("Advertises"))
    axios.get(`${process.env.REACT_APP_API}/admin/advertises`, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
      }
    })
      .then((res) => {
        if (res.data.data) {
          if (res.data.data.length === 0) {
            setData([{ name: "", link: "", description: "", arrange: "", start_date: "", end_date: "", image: null }])
            setrows2([{ id: 1 }])
            return
          }
          if (res.data.data.length >= 10) {
            setActiveBtn(true)
          }
          setData(res.data.data.map(c => {
            return { ...c, image: { preview: c.image } }
          }))
          setrows2(res.data.data.map((c, i) => {
            return { id: i + 1 }
          }))
          res.data.data.forEach(props => {
            setCol(state => state.concat(false))
          })
        }
      })
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Starter Page | Skote - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid>
          <Card>
            <CardBody>
              {success_msg ? (
                <SweetAlert
                  title={msg}
                  success
                  confirmBtnBsStyle="success"
                  onConfirm={() => {
                    setsuccess_msg(false)
                  }}
                  onCancel={() => {
                    setsuccess_msg(false)
                  }}
                >
                </SweetAlert>
              ) : null}
              <div className="accordion" id="accordion">
                {(rows2 || []).map((formRow, key) => (
                  <div className="accordion-item" key={key}>
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className={classnames(
                          "accordion-button",
                          "fw-medium",
                          "fw-bold",
                          "fs-5",
                          { collapsed: !col[key] }
                        )}
                        type="button"
                        onClick={() => t_col1(key)}
                        style={{ cursor: "pointer" }}
                      >
                        {`${data[key].name} - (${data[key].country})`}
                      </button>
                    </h2>
                    <Collapse isOpen={col[key]} className="accordion-collapse">
                      <div className="accordion-body">
                        <Form className="border-0 p-0">
                          <Row className="mb-3">
                            <Col>
                              <Label htmlFor="productdesc">
                                Country
                              </Label>
                              <Select
                                options={countries ? [{ value: "all", label: "All" }].concat(countries.map(c => {
                                  return { value: c.nameEn, label: c.nameEn }
                                })) : null}
                                placeholder={data[key].country}
                                onChange={(e) => handleChange(e.value, key, "country")}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="mb-3">
                                <Label htmlFor="productdesc">
                                  Add Name
                                </Label>
                                <Input
                                  id="title"
                                  name="title"
                                  type="text"
                                  className="form-control input_cus"
                                  placeholder="e.g.Name"
                                  value={data[key].name}
                                  onChange={(e) => handleChange(e.target.value, key, "name")}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="mb-3">
                                <Label htmlFor="productdesc">
                                  Add Link
                                </Label>
                                <Input
                                  id="title"
                                  name="title"
                                  type="text"
                                  className="form-control input_cus"
                                  placeholder="e.g. example.com"
                                  value={data[key].link}
                                  onChange={(e) => handleChange(e.target.value, key, "link")}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="mb-3">
                                <Label htmlFor="productdesc">
                                  Description
                                </Label>
                                <Input
                                  id="title"
                                  name="title"
                                  type="text"
                                  className="form-control input_cus"
                                  placeholder="e.g. example.com"
                                  value={data[key].description}
                                  onChange={(e) => handleChange(e.target.value, key, "desc")}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="mb-3">
                                <Label htmlFor="productdesc">
                                  Arrange
                                </Label>
                                <Input
                                  id="title"
                                  name="title"
                                  type="text"
                                  className="form-control input_cus"
                                  placeholder="e.g. example.com"
                                  value={data[key].arrange}
                                  onChange={(e) => handleChange(e.target.value, key, "arr")}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="mb-3">
                                <Label htmlFor="productdesc">
                                  Status
                                </Label>
                                <select
                                  className="form-control input_cus"
                                  value={data[key].status}
                                  onChange={(e) => handleChange(e.target.value, key, "status")}
                                >
                                  <option>Select</option>
                                  <option value={0}>deactive</option>
                                  <option value={1}>Active</option>
                                </select>
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                              </div>
                            </Col>
                          </Row>

                          {data[key].status != 0 ? <Row>
                            <Col lg={6} className="pr-2">
                              <div className="mb-3">
                                <Label htmlFor="productdesc">
                                  Start Date
                                </Label>
                                <Input
                                  id="keynumber"
                                  name="keynumber"
                                  type="date"
                                  className="form-control text-lowercase input_cus"
                                  placeholder="e.g. 834UGEO8Y3"
                                  value={data[key].start_date}
                                  onChange={(e) => handleChange(e.target.value, key, "start")}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                              </div>
                            </Col>
                            <Col lg={6} className="pl-2">
                              <div className="mb-3">
                                <Label htmlFor="productdesc">
                                  End Date
                                </Label>
                                <Input
                                  id="keynumber"
                                  name="keynumber"
                                  type="date"
                                  className="form-control text-lowercase input_cus"
                                  placeholder="e.g. 834UGEO8Y3"
                                  value={data[key].end_date}
                                  onChange={(e) => handleChange(e.target.value, key, "end")}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                              </div>
                            </Col>
                          </Row> : null}

                          <Row>
                            <Col>
                              <div className="mb-3">
                                <Label htmlFor="metatitle">Ads Image</Label>
                                <div className="mb-3">
                                  <Input
                                    type="file"
                                    className="form-control form-control-   lg "
                                    onChange={e => handleAcceptedFiles(Object.values(e.target.files), key, e)}
                                    style={{ borderRadius: 0 }}
                                  />
                                  <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                                </div>
                              </div>
                            </Col>
                            {data[key].image ?
                              <Row className="align-items-center" style={{ paddingLeft: "14px", paddingRight: "0px" }}>
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={data[key].image.name}
                                    src={data[key].image.preview}
                                    style={{ objectFit: "cover" }}
                                  />
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted "
                                  >
                                    {data[key].image.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{data[key].image.formattedSize}</strong>
                                  </p>
                                </Col>
                                <Col className="p-0">
                                  <div className="image__options">
                                    <button className="p-0" onClick={(e) => setOpen(e, data[key].image.preview)}><i
                                      className="far fa-eye  fs-5 p-0" /></button>
                                    <button className="p-0" style={{ color: "#ff3838" }}
                                            onClick={(e) => deleteImage(key)}><i className="bx bx-trash fs-4" />
                                    </button>
                                  </div>
                                </Col>
                              </Row> : null}
                          </Row>
                          <Row>
                            <Col className="d-flex justify-content-end gap-2">
                              <div className="save__btn top m-0 mt-2">
                                <button onClick={(e) => submit(e, key)}>{active ?
                                  <ReactLoading type={"spin"} color={"#ffffff"} height={30}
                                                width={30} /> : "Save"}</button>
                              </div>
                              <div className="delete__btn top m-0 mt-2">
                                <button onClick={(e) => deleteAds(e, key)}>{deleteLoad ?
                                  <ReactLoading type={"spin"} color={"#ffffff"} height={30}
                                                width={30} /> : "Delete Ads"}</button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </Collapse>
                  </div>
                ))}
              </div>
              <div className="">
                <div className="add__btn top m-0 mt-2">
                  <button onClick={handleAddRowNested} className="d-block pl-2 pr-2"><i
                    className="bx bx-plus-circle" /> Add Ads
                  </button>
                </div>
              </div>
              {isOpen ? (
                <Lightbox
                  mainSrc={prev}
                  enableZoom={false}
                  onCloseRequest={() => {
                    setisOpen(!isOpen)
                  }}
                />
              ) : null}

            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Advertises

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px"
}

const editBtn = {
  background: "transparent",
  border: "none",
  color: "green",
  display: "flex",
  alignItems: "center",
  gap: "5px"
}