/* eslint-disable react/prop-types */

import React from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator"
import { Col, Row } from "reactstrap"

function PaperToysPagination({ data, page, sizePerPage, onTableChange, totalSize, columns }) {
  // const defaultSorted = [{
  //   dataField: 'id',
  //   order: 'desc'
  // }];
  return (
    <div>
      <PaginationProvider
        pagination={
          paginationFactory({
            custom: true,
            page,
            sizePerPage,
            totalSize
          })
        }
      >
        {
          ({
             paginationProps,
             paginationTableProps
           }) => (
            <Row>
              <Col xl="12">
                <div className={"table-responsive"}>
                  <BootstrapTable
                    remote
                    keyField="key"
                    data={data}
                    columns={columns}
                    onTableChange={onTableChange}
                    classes={
                      "table align-middle table-nowrap"
                    }
                    headerWrapperClasses={"thead-light"}
                    {...paginationTableProps}
                  />
                  {data.length === 0?<div className="emptyStateTable" style={{
                    width: '100%',
                    textAlign: 'center',
                    fontSize: '16px',
                    padding: '10px',
                    paddingTop: '0', paddingBottom: "20px"}}>no data available in table</div>:null}

                  {data.length !== 0 ? <Row className="align-items-md-center mt-30">
                    <Col className="inner-custom-pagination d-flex">
                      <div className="text-md-right ms-auto">
                        <PaginationListStandalone
                          {...paginationProps}
                        />
                      </div>
                    </Col>
                  </Row> : null}
                </div>
              </Col>
            </Row>
          )
        }
      </PaginationProvider>
    </div>
  )
}

export default PaperToysPagination