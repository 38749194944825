import React, {useEffect, useState} from "react"
import MetaTags from 'react-meta-tags';
import classnames from "classnames";
import {
  
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from "reactstrap";
import Sticky from 'react-sticky-el';
import Select from 'react-select'
import UserToAdmin from './Components/UserToAdmin'
import UserToUser from './Components/UserToUser'
import AdminToUser from './Components/AdminToUser'
import AdminNotifications from './Components/AdminNotifications'
import './configration.scss'
import axios from "axios";
import { useSelector, useDispatch } from "react-redux"
import {
    setTitle,
    updateNotifications
} from "../../../store/actions";
import SweetAlert from "react-bootstrap-sweetalert"
import { useHistory } from "react-router-dom";

const Notifications = () => {
    const dispatch = useDispatch()
    const   user = useSelector(state => state.UserData.user)
    const   countries = useSelector(state => state.MainData.countries)
    const notifications = useSelector(state => state.Notifications.notifyCount)
    const history = useHistory()
    const [usertouser, setUTU] = useState([])
    const [admintouser, setATU] = useState([])
    const [usertoadmin, setUTA] = useState([])
    // const [adminnotification, setAN] = useState([])
    const [noty, setNoty] = useState([])
    const [verticalActiveTab, setverticalActiveTab] = useState("2");
    const [success_msg, setsuccess_msg] = useState(false)
    const [msg, setMsg] = useState("")


    const toggleVertical = tab => {
        if (verticalActiveTab !== tab) {
            setverticalActiveTab(tab);
        }
    };
    
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]
    function userChanges(key, type) {
        if(type === "utu"){
            setUTU(notys => notys.filter(noty =>   noty.key !== key))
            setMsg("Deleted!")
            setsuccess_msg(true)
        } else if(type === "uta"){
            setUTA(notys => notys.filter(noty =>   noty.key !== key))
            setMsg("Deleted!")
            setsuccess_msg(true)
        }else if(type === "atu") {
            setATU(notys => notys.filter(noty =>   noty.key !== key))
            setMsg("Deleted!")
            setsuccess_msg(true)
        }else {
            // setAN(notys => notys.filter(noty =>   noty.key !== key))
            setMsg("Deleted!")
            setsuccess_msg(true)
        }
    }

    function countryFilter(name) {
        if(name === "All") {
            setUTU(noty.usersToUser)
            setATU(noty.adminToUser)
            setUTA(noty.usersToAdmin)
            return;
        }
        setUTU(noty.usersToUser.filter(noty =>  noty.userfrom.country == name || noty.userto.country == name))
        setUTA(noty.usersToAdmin.filter(noty => noty.userfrom.country == name || noty.userto.country == name))
        setATU(noty.adminToUser.filter(noty =>  noty.userfrom.country == name || noty.userto.country == name))
    }

    useEffect(() => {
        if(user && user.role == 2) {
            history.push('/')
            return;
        } 
    }, [user])

    useEffect(() => {
        dispatch(setTitle("Notifications"))

        axios.post(`${process.env.REACT_APP_API}/admin/setUnread`, {key: "7"},{
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`,
            }
        }).then(res => {
            dispatch(updateNotifications({unreadNotificationCount: 0}))
        })
    }, [])

    useEffect(() => {
        if(notifications) {
            axios.post(`${process.env.REACT_APP_API}/admin/setUnread`, {key: "7"},{
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`,
                }
            }).then(res => {
                dispatch(updateNotifications({unreadNotificationCount: 0}))
            })
        }
    }, [notifications])
    
    return (
        <React.Fragment>
            <div className="page-content">
            <MetaTags>
                <title>Notifications | Etoy App</title>
            </MetaTags>
            <Container fluid>
            {success_msg ? (
                    <SweetAlert
                    title={msg}
                    success
                    confirmBtnBsStyle="success"
                    onConfirm={() => {
                        setsuccess_msg(false)
                    }}
                    onCancel={() => {
                        setsuccess_msg(false)
                    }}
                    >
                    </SweetAlert>
                ) : null}
            <Col>
                <Row className="mb-3">
                    <Col>
                        <Select 
                            options={countries?[{value:"All", label:"All"}].concat(countries.map(c => {return {value: c.nameEn, label: c.nameEn + ` (${c.users_count})`} })):null}
                            placeholder="Country"
                            onChange={(e) => countryFilter(e.value)}
                        />
                    </Col> 
                </Row>
                <Row>
                <Col md="12">
                <Sticky topOffset={-70} style={{top: "70px"}}>
                    <Nav pills className="flex-row justify-content-between navs_aside">
                        
                    <NavItem className=" nav__item_not border_right">
                        <NavLink
                        style={{ cursor: "pointer", borderRadius: 0}}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "2",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("2");
                        }}
                        >
                                User To Admin
                            {/*{usertoadmin?`(${usertoadmin.length})`:null}*/}
                        </NavLink>
                    </NavItem>

                    <NavItem className="nav__item_not border_right">
                        <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "1",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("1");
                        }}
                        >
                        User To User
                            {/*{usertouser?`(${usertouser.length})`:null}*/}
                        </NavLink>
                    </NavItem>

                    <NavItem className="nav__item_not border_right">
                        <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "3",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("3");
                        }}
                        >
                            Admin To User
                            {/*{admintouser?`(${admintouser.length})`:null}*/}
                        </NavLink>
                    </NavItem>
                    </Nav>
                    </Sticky>
                </Col>
                <Col md="12  mt-2">
                    <TabContent
                    activeTab={verticalActiveTab}
                    className="text-muted table__content mt-4 mt-md-0"
                    >
                        <TabPane tabId="1">
                            <UserToUser userChanges={userChanges}/>
                        </TabPane>
                        <TabPane tabId="2">
                            <UserToAdmin userChanges={userChanges}/>
                        </TabPane>
                        <TabPane tabId="3">
                            <AdminToUser userChanges={userChanges}/>
                        </TabPane>
                        </TabContent>
                </Col>
                </Row>
            </Col>
            </Container>
            </div>
        </React.Fragment>
)
}

export default Notifications



const navsStyle = { 
    cursor: "pointer", 
    borderRadius: 0,

}