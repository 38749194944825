import React, {useEffect, useState} from "react"
import MetaTags from 'react-meta-tags';
import classnames from "classnames";
import {
    Accordion,
    AccordionHeader,
    AccordionItem,
    Card,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Collapse,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from "reactstrap";
import Sticky from 'react-sticky-el';
import Select from 'react-select'
import Country from './Components/Country'
import Points from './Components/Points'
import Avatar from './Components/Avatar'
import Category from './Components/Category'
import EmailShot from './Components/EmailShot'
import CharacterPdf from './Components/CharacterPdf'
import Ads from './Components/Ads'
import './configration.scss'
import axios from "axios";
import { useSelector, useDispatch } from "react-redux"
import {
    setTitle
} from "../../store/actions";
import { useHistory } from "react-router-dom"
import PaperCategories from "./Components/PaperCategories"
import SEOApp from "./Components/SEOApp"
import VersionPopView from "./Components/VersionPop"

const Options = () => {
    const dispatch = useDispatch()
    const   user = useSelector(state => state.UserData.user)
    const history = useHistory()

    const [data, setData] = useState([])
    const [countries, setCountries] = useState([])
    const [points, setPoints] = useState(null)
    const [avatar, setAvatar] = useState([])
    const [categories, setCategories] = useState([])
    const [paperCategories, setPaperCategories] = useState([])
    const [SEO, setSEO] = useState(null)
    const [VersionPop, setVersionPop] = useState(null)
    const [email, setEmail] = useState(null)
    const [verticalActiveTab, setverticalActiveTab] = useState("1");

    const toggleVertical = tab => {
        if (verticalActiveTab !== tab) {
            setverticalActiveTab(tab);
        }
    };
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]
    function eToyCategoryChanges(eToyCategory, type) {
        if (type === "addEToyCategory") {
            setCategories(categories => [eToyCategory].concat(categories))
        }
    }

    function paperCategoryChanges(paperCategory, type) {
        if (type === "addPaperCategory") {
            setPaperCategories(paperCategories => [paperCategory].concat(paperCategories))
        }
    }

    useEffect(() => {
        if(user && user.role === 2) {
            history.push('/')

        } 
    }, [user])
    
    useEffect(() => {
        
        dispatch(setTitle("Configurations"))
        axios.get(`${process.env.REACT_APP_API}/admin/setting`, {
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`, 
            }
        })
        .then(res => {
            setData(res.data.data)
            setCountries(res.data.data.countries)
            setCategories(res.data.data.categories)
            setPoints(res.data.data.points)
            setSEO(res.data.data.feedbacks)
            setEmail(res.data.data.emailshot)
            setAvatar(res.data.data.maleAvatars.concat(res.data.data.femaleAvatars));
            setVersionPop(res.data.data.VersionPop)
        })
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API}/etoypaper-dashboard/toyspapercat`, {
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`,
            }
        }).then(res => {
            // console.log(res.data.data)
            setPaperCategories(res.data.data)
        })
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
            <MetaTags>
                <title>Settings | Etoy App</title>
            </MetaTags>
            <Container fluid>
            <Col>
                <Row>
                <Col md="3">
                <Sticky topOffset={-70} style={{top: "70px"}}>
                    <Nav pills className="flex-column justify-content-between navs_aside">
                        <NavItem className="nav___item border_bottom">
                            <NavLink
                            style={{ cursor: "pointer", borderRadius: 0}}
                            className={classnames({
                                "": true,
                                active: verticalActiveTab === "1",
                                "tab_link":true
                            })}
                            onClick={() => {
                                toggleVertical("1");
                            }}
                            >
                            Country
                            </NavLink>
                        </NavItem>

                        <NavItem className="nav___item border_bottom">
                            <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                "": true,
                                active: verticalActiveTab === "2",
                                "tab_link":true
                            })}
                            onClick={() => {
                                toggleVertical("2");
                            }}
                            >
                            Points
                            </NavLink>
                        </NavItem>
                        <NavItem className="nav___item border_bottom">
                            <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                "": true,
                                active: verticalActiveTab === "3",
                                "tab_link":true
                            })}
                            onClick={() => {
                                toggleVertical("3");
                            }}
                            >
                                Email Shot
                            </NavLink>
                        </NavItem>
                        <NavItem className="nav___item border_bottom">
                            <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                "": true,
                                active: verticalActiveTab === "4",
                                "tab_link":true
                            })}
                            onClick={() => {
                                toggleVertical("4");
                            }}
                            >
                                Character Pdf
                            </NavLink>
                        </NavItem>
                        <NavItem className="nav___item border_bottom">
                            <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                "": true,
                                active: verticalActiveTab === "5",
                                "tab_link":true
                            })}
                            onClick={() => {
                                toggleVertical("5");
                            }}
                            >
                                Avatar
                            </NavLink>
                        </NavItem>
                        <NavItem className="nav___item border_bottom">
                            <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                "": true,
                                active: verticalActiveTab === "6",
                                "tab_link":true
                            })}
                            onClick={() => {
                                toggleVertical("6");
                            }}
                            >
                                eToy Categories
                            </NavLink>
                        </NavItem>
                        <NavItem className="nav___item border_bottom">
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                  "": true,
                                  active: verticalActiveTab === "7",
                                  "tab_link":true
                              })}
                              onClick={() => {
                                  toggleVertical("7");
                              }}
                            >
                                Paper Categories
                            </NavLink>
                        </NavItem>
{/* 
                        <NavItem className="nav___item">
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                  "": true,
                                  active: verticalActiveTab === "8",
                                  "tab_link":true
                              })}
                              onClick={() => {
                                  toggleVertical("8");
                              }}
                            >
                                SEO App
                            </NavLink>
                        </NavItem> */}

                        <NavItem className="nav___item">
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                  "": true,
                                  active: verticalActiveTab === "9",
                                  "tab_link":true
                              })}
                              onClick={() => {
                                  toggleVertical("9");
                              }}
                            >
                                Version POP
                            </NavLink>
                        </NavItem>
                    </Nav>
                    </Sticky>
                </Col>
                <Col md="9">
                    <TabContent
                    activeTab={verticalActiveTab}
                    className="text-muted table__content mt-4 mt-md-0"
                    >
                        <TabPane tabId="1">
                            <Country countries={countries}/>
                        </TabPane>
                        <TabPane tabId="2">
                            <Points points={points}/>
                        </TabPane>
                        <TabPane tabId="3">
                            <EmailShot   email={email}/>
                        </TabPane>
                        <TabPane tabId="4">
                            <CharacterPdf   email={email}/>
                        </TabPane>
                        <TabPane tabId="5">
                            <Avatar  avatar={avatar}/>
                        </TabPane>
                        <TabPane tabId="6">
                            <Category categories={categories} eToyCategoryChanges={eToyCategoryChanges}/>
                        </TabPane>
                        <TabPane tabId="7">
                            <PaperCategories paperCategories={paperCategories} paperCategoryChanges={paperCategoryChanges}/>
                        </TabPane>
                        <TabPane tabId="8">
                            <SEOApp  seo={SEO}/>
                        </TabPane>
                        <TabPane tabId="9">
                            <VersionPopView  VersionPop={VersionPop}/>
                        </TabPane>
                        </TabContent>
                </Col>
                </Row>
            </Col>
            </Container>
            </div>
        </React.Fragment>
)
}

export default Options



const navsStyle = { 
    cursor: "pointer", 
    borderRadius: 0,

}
