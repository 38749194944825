/* eslint-disable react/prop-types */
import axios from 'axios';
import React, {useState, useRef, useEffect} from 'react';
import { Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import PulseLoader from "react-spinners/PulseLoader";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import {Link} from "react-router-dom"
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import './specification.scss'


const PdfForm = (props) => {
    let p = props 
    const [isOpen, setisOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [file, setFile] = useState('');
    const [link, setLink] = useState('');
    const [loading, setLoading] = useState(false)
    
    const categoryEnRef = useRef(null);
    const categoryArRef = useRef(null);
    const tagRef = useRef(null);
    const colorRef = useRef(null);
    const imageRef = useRef(null);
    

    function submitHandler() {
        setLoading(true)
        const formData = new FormData();
        
        let data = {
            email,
            link
        } 
        for ( let key in data ) {
            formData.append(key, data[key]);
        }
        axios.post(`${process.env.REACT_APP_API}/admin/toys/sendPdf`, formData, {
            headers: { 
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`, 
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            setLoading(false)
            props.clickHandle(null, false)
            setEmail('')
            setLink('')
            document.getElementById('pdfForm').reset()
        })
    }



    return(
        <div className={`overlay_coup ${p.active? "active":""}`}>
            {isOpen ? (
            <Lightbox
                mainSrc={image.preview}
                enableZoom={false}
                onCloseRequest={() => {
                setisOpen(!isOpen);
                }}
            />
            ) : null}
            <Form id="pdfForm" onSubmit={(e) => e.preventDefault()} style={{paddingBottom: "70px", height: "320px"}}>
                <div className='head_form'>
                    <div className='log'>
                    <i className='bx bx-envelope fs-3'></i>
                        Send PDF
                    </div>
                    <button onClick={() => p.clickHandle(null, false)}><i className='dripicons dripicons-cross'></i></button>
                </div>
                <div className='inputs__wrap '>
                    <Row>
                        <Col xs={12}>
                            <div className="mb-3" ref={categoryEnRef}>
                                <Label htmlFor="productdesc">
                                    Email
                                </Label>
                                <Input
                                    id="title"
                                    name="email"
                                    type="email"
                                    className="form-control input_cus"
                                    placeholder="e.g. ex@ex.ex"
                                    value={email}
                                    onChange= {e => setEmail(e.target.value)}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className="mb-3" ref={categoryEnRef}>
                                <Label htmlFor="productdesc">
                                    Link
                                </Label>
                                <Input
                                    id="title"
                                    name="link"
                                    type="text"
                                    className="form-control input_cus"
                                    placeholder="e.g. ex@ex.ex"
                                    value={link}
                                    onChange= {e => setLink(e.target.value)}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className='foot_form'>
                    <button className='cancel' onClick={() => p.clickHandle(null, false)}>Cancel</button>
                    <button className='save' onClick={submitHandler}>{loading?<PulseLoader color={"#ffffff"} loading={true}  size={10} />:"Send"}</button>
                </div>
            </Form>
        </div>
    );
};

export default PdfForm;
