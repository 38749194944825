import React, {useEffect, useState} from "react"
import MetaTags from 'react-meta-tags';
import classnames from "classnames";
import {
    Accordion,
    AccordionHeader,
    AccordionItem,
    Card,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Collapse,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from "reactstrap";
import Sticky from 'react-sticky-el';
import OnBoardingPage from './Components/OnBoardingPage'
import Contact from './Components/Contact'
import About from './Components/About'
import GideLine from './Components/GideLine'
import Covid from './Components/Covid'
import Info from './Components/Info'
import PointPolicy from './Components/PointPolicy'
import Terms from './Components/Terms'
import Popup from './Components/Popup'
import './configration.scss'
import axios from "axios";
import { useSelector, useDispatch } from "react-redux"
import {
    setTitle
} from "../../../store/actions";
import { useHistory } from "react-router-dom";
import Features from "./Components/Features"
const Pages = () => {
    const dispatch = useDispatch()
    const history  = useHistory()
    const   user = useSelector(state => state.UserData.user)
    const [pording, setPording] = useState([])
    const [point, setPoint] = useState([])
    const [terms, setTerms] = useState([])
    const [covid, setCovid] = useState([])
    const [gide, setGide] = useState([])
    const [about, setAbout] = useState([])
    const [contact, setContact] = useState([])
    // const [features, setFeatures] = useState([])
    const [verticalActiveTab, setverticalActiveTab] = useState("1");
    const toggleVertical = tab => {
        if (verticalActiveTab !== tab) {
            setverticalActiveTab(tab);
        }
    };


    useEffect(() => {
        if(user && user.role === 2) {
            history.push('/dashboard')
            return;
        } 
    }, [user])

    useEffect(() => {   
        dispatch(setTitle("eToy App Pages"))
        axios.get(`${process.env.REACT_APP_API}/admin/pages`, {
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`, 
            }
        })
        .then(res => {
            setPording(res.data.data[0])
            setPoint(res.data.data[1]) 
            setTerms(res.data.data[2])
            setCovid(res.data.data[3])
            setGide(res.data.data[4])
            setAbout(res.data.data[5])
            setContact(res.data.data[6])
        }) 
    }, [])

    return (
        <React.Fragment>
        <div className="page-content">
        <MetaTags>
            <title>Pages | Etoy App</title>
        </MetaTags>
        <Container fluid>
        <Col>
            <Row>
            <Col md="3">
            <Sticky topOffset={-70} style={{top: "70px"}}>
                <Nav pills className="flex-column justify-content-between navs_aside">
                    <NavItem  className="nav___item border_bottom">
                        <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "1",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("1");
                        }}
                        >
                        On-boarding page 
                        </NavLink>
                    </NavItem>

                    <NavItem className="nav___item border_bottom">
                        <NavLink
                        style={{ cursor: "pointer", borderRadius: 0}}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "2",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("2");
                        }}
                        >
                        Points policy 
                        </NavLink>
                    </NavItem>

                    <NavItem className="nav___item border_bottom">
                        <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "3",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("3");
                        }}
                        >
                        Terms & conditions 
                        </NavLink>
                    </NavItem>
                    <NavItem className="nav___item border_bottom">
                        <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "4",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("4");
                        }}
                        >
                            Covid-19 policy 
                        </NavLink>
                    </NavItem>
                    <NavItem className="nav___item border_bottom">
                        <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "5",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("5");
                        }}
                        >
                            Photo guidelines 
                        </NavLink>
                    </NavItem>
                    <NavItem className="nav___item border_bottom">
                        <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "6",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("6");
                        }}
                        >
                            About us 
                        </NavLink>
                    </NavItem>
                    <NavItem className="nav___item border_bottom">
                        <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "7",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("7");
                        }}
                        >
                            Contact us 
                        </NavLink>
                    </NavItem>
                    <NavItem className="nav___item border_bottom">
                        <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "8",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("8");
                        }}
                        >
                            Features (Add a Toy)
                        </NavLink>
                    </NavItem>
                </Nav>
                </Sticky>
            </Col>
            <Col md="9">
                <TabContent
                activeTab={verticalActiveTab}
                className="text-muted table__content mt-4 mt-md-0"
                >
                    <TabPane tabId="1">
                        <OnBoardingPage data={pording.pages}/>
                    </TabPane>
                    <TabPane tabId="2" >
                        <PointPolicy data={point.pages}/>
                    </TabPane>
                    <TabPane tabId="3">
                        <Terms data={terms.pages}/>
                    </TabPane>
                    <TabPane tabId="4">
                        <Covid data={covid.pages}/>
                    </TabPane>
                    <TabPane tabId="5">
                        <GideLine data={gide.pages}/>
                    </TabPane>
                    <TabPane tabId="6">
                        <About data={about.pages}/>
                    </TabPane>
                    <TabPane tabId="7" >
                        <Contact data={contact.pages}/>
                    </TabPane>
                    <TabPane tabId="8" >
                        <Features data={pording.pages}/>
                    </TabPane>
                    </TabContent>
            </Col>
            </Row>
        </Col>
        </Container>
        </div>
    </React.Fragment>
)
}

export default Pages



const navsStyle = { 
    cursor: "pointer", 
    borderRadius: 0,

}