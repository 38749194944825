import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
// import Account from "./auth/register/reducer"
// import ForgetPassword from "./auth/forgetpwd/reducer"
// import Profile from "./auth/profile/reducer"

//MainData 
import MainData from "./main/reducer";

//MainData 
import Notifications from "./notifications/reducer";

//MainData 
import UserData from "./user/reducer";

//Dashboard
import Dashboard from "./dashboard/reducer"

//Dashboard Title
import Title from "./title/reducer";



const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Dashboard,
  // Account,
  // ForgetPassword,
  // Profile,
  MainData,
  Notifications,
  Title,
  UserData
})

export default rootReducer
