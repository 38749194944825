/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Collapse,
} from "reactstrap"
import classnames from "classnames"
import "./specification.scss"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import axios from "axios"
import SweetAlert from "react-bootstrap-sweetalert"
import ReactLoading from "react-loading"
import editorStyle from "./editor.scss"
const Terms = props => {
  const [rows2, setrows2] = useState([{ id: 1 }])
  const [active, setActive] = useState(false)
  const [deleteLoad, setDelete] = useState(false)
  const [image, setImage] = useState(null)
  const [isOpen, setisOpen] = useState(false)
  const [col, setCol] = useState([false])
  const [data, setData] = useState([
    {
      title_en: "",
      title_ar: "",
      description_en: "",
      description_ar: "",
      image: null,
    },
  ])
  const [news, setNews] = useState(null)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")

  const t_col1 = id => {
    setCol(col =>
      col.map((c, i) => {
        if (i == id) {
          return !c
        }
        return false
      })
    )
  }

  function handleAcceptedFiles(files, key) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setData(data =>
      data.map((obj, i) => {
        if (i === key) {
          return {
            ...obj,
            image: files[0],
          }
        }
        return obj
      })
    )
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  function setOpen(e, key) {
    e.preventDefault()
    setImage(data[key].image)
    setisOpen(true)
  }

  function handleAddRowNested() {
    const modifiedRows = [...rows2]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setrows2(modifiedRows)
    setData(data =>
      data.concat({
        title_en: "",
        title_ar: "",
        description_en: "",
        description_ar: "",
        image: null,
      })
    )
    setCol(state => state.concat(false))
    setNews(modifiedRows.length)
  }

  function handleRemoveRow(id) {
    if (id === 0) {
      return setData(data => [
        {
          title_en: "",
          title_ar: "",
          description_en: "",
          description_ar: "",
          image: null,
        },
      ])
    }
    var modifiedRows = [...rows2]
    modifiedRows = modifiedRows.filter(x => x["id"] !== id)
    setrows2(modifiedRows)
    setData(data => data.filter((d, i) => i != id))
  }

  function handleChange(value, key, type) {
    switch (type) {
      case "ten":
        setData(data =>
          data.map((obj, i) => {
            if (i === key) {
              return {
                ...obj,
                title_en: value,
              }
            }
            return obj
          })
        )
        break
      case "tar":
        setData(data =>
          data.map((obj, i) => {
            if (i === key) {
              return {
                ...obj,
                title_ar: value,
              }
            }
            return obj
          })
        )
        break
      case "den":
        setData(data =>
          data.map((obj, i) => {
            if (i === key) {
              return {
                ...obj,
                description_en: value,
              }
            }
            return obj
          })
        )
        break
      case "dar":
        setData(data =>
          data.map((obj, i) => {
            if (i === key) {
              return {
                ...obj,
                description_ar: value,
              }
            }
            return obj
          })
        )
        break
      default:
        break
    }
  }

  function submit(e, key) {
    e.preventDefault()
    setActive(true)
    const formData = new FormData()
    let d = data[key]
    let photo = data[key].image
    delete d["photo"]

    for (let key in d) {
      formData.append(key, d[key])
    }
    d.key && formData.append("id", d.key)
    formData.append("pageparent_id", 3)
    if (photo && photo.name) {
      formData.append("photo", photo)
    }

    axios
      .post(
        news == key + 1
          ? `${process.env.REACT_APP_API}/etoypaper-dashboard/toypaperpages/store`
          : `${process.env.REACT_APP_API}/etoypaper-dashboard/toypaperpages/update`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).authToken
            }`,
          },
        }
      )
      .then(res => {
        axios.get(`${process.env.REACT_APP_API}/etoypaper-dashboard/toypaperpages`, {
          headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`,
          }
        })
        .then(res => {
          setData(res.data?.data[1]?.pages.map(c => {return {...c, image: {preview: c.photo}}}))
          setrows2(res.data?.data[1]?.pages.map((c,i) => {return {id: i+1}}))
          res.data?.data[1]?.pages.forEach(props => {
              setCol(state => state.concat(false))
          })
        })
      
        setActive(false)
        news == key + 1 ? setNews(null) : null
        setMsg(`Saved!`)
        setsuccess_msg(true)
      })
  }

  function deletePage(e, key) {
    e.preventDefault()
    setDelete(true)
    axios
      .post(
        `${process.env.REACT_APP_API}/etoypaper-dashboard/toypaperpages/delete`,
        { id: data[key].key },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).authToken
            }`,
          },
        }
      )
      .then(res => {
        setDelete(false)
        handleRemoveRow(key)
        setMsg(`Deleted!`)
        setsuccess_msg(true)
      })
  }

  useEffect(() => {
    if (props.data) {
      setData(
        props.data.map(c => {
          return { ...c, image: { preview: c.photo } }
        })
      )
      setrows2(
        props.data.map((c, i) => {
          return { id: i + 1 }
        })
      )
      props.data.forEach(props => {
        setCol(state => state.concat(false))
      })
    }
  }, [props.data])

  return (
    <React.Fragment>
      <div className="container-fluid p-0">
        <Card>
          <CardBody>
            {success_msg ? (
              <SweetAlert
                title={msg}
                success
                confirmBtnBsStyle="success"
                onConfirm={() => {
                  setsuccess_msg(false)
                }}
                onCancel={() => {
                  setsuccess_msg(false)
                }}
              ></SweetAlert>
            ) : null}
            <div className="accordion" id="accordion">
              {data && rows2
                ? (rows2 || []).map((formRow, key) => (
                    <div className="accordion-item" key={key}>
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className={classnames(
                            "accordion-button",
                            "fw-medium",
                            "fw-bold",
                            "fs-5",
                            { collapsed: !col[key] }
                          )}
                          type="button"
                          onClick={() => t_col1(key)}
                          style={{ cursor: "pointer" }}
                        >
                          {data[key].title_en}
                        </button>
                      </h2>
                      <Collapse
                        isOpen={col[key]}
                        className="accordion-collapse"
                      >
                        <div className="accordion-body">
                          <Form className="border-0 p-0">
                            <Row>
                              <Col>
                                <div className="mb-3">
                                  <Label htmlFor="productdesc">
                                    Title (English)
                                  </Label>
                                  <Input
                                    id="title"
                                    name="title"
                                    type="text"
                                    className="form-control input_cus"
                                    placeholder="e.g.Name"
                                    value={data[key].title_en}
                                    onChange={e =>
                                      handleChange(e.target.value, key, "ten")
                                    }
                                  />
                                  <FormFeedback className="fs-6">
                                    This Fileld Is Required!
                                  </FormFeedback>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="mb-3">
                                  <Label htmlFor="productdesc">
                                    Title (Arabic)
                                  </Label>
                                  <Input
                                    id="title"
                                    name="title"
                                    type="text"
                                    className="form-control input_cus"
                                    placeholder="مثال. الاسم"
                                    dir="rtl"
                                    value={data[key].title_ar}
                                    onChange={e =>
                                      handleChange(e.target.value, key, "tar")
                                    }
                                  />
                                  <FormFeedback className="fs-6">
                                    This Fileld Is Required!
                                  </FormFeedback>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="mb-3">
                                  <Label htmlFor="productdesc">
                                    Description (english)
                                  </Label>
                                  <CKEditor
                                    color="#000"
                                    style={editorStyle}
                                    editor={ClassicEditor}
                                    data={data[key].description_en}
                                    onChange={(event, editor) => {
                                      const data = editor.getData()
                                      handleChange(data, key, "den")
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="mb-3">
                                  <Label htmlFor="productdesc">
                                    Description (arabic)
                                  </Label>
                                  <CKEditor
                                    color="#000"
                                    style={editorStyle}
                                    editor={ClassicEditor}
                                    data={data[key].description_ar}
                                    onChange={(event, editor) => {
                                      const data = editor.getData()
                                      handleChange(data, key, "dar")
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col className="d-flex justify-content-end gap-2">
                                <div className="save__btn top m-0 mt-2">
                                  <button onClick={e => submit(e, key)}>
                                    {active ? (
                                      <ReactLoading
                                        type={"spin"}
                                        color={"#ffffff"}
                                        height={30}
                                        width={30}
                                      />
                                    ) : (
                                      "Save"
                                    )}
                                  </button>
                                </div>
                                <div className="delete__btn top m-0 mt-2">
                                  <button onClick={e => deletePage(e, key)}>
                                    {deleteLoad ? (
                                      <ReactLoading
                                        type={"spin"}
                                        color={"#ffffff"}
                                        height={30}
                                        width={30}
                                      />
                                    ) : (
                                      "Delete"
                                    )}
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </Collapse>
                    </div>
                  ))
                : null}
            </div>
            <div className="">
              <div className="add__btn top m-0 mt-2">
                <button onClick={handleAddRowNested}>
                  <i className="bx bx-plus-circle"></i>Add
                </button>
              </div>
            </div>
            {isOpen ? (
              <Lightbox
                mainSrc={image.preview}
                enableZoom={false}
                onCloseRequest={() => {
                  setisOpen(!isOpen)
                }}
              />
            ) : null}
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}

export default Terms

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px",
}

const editBtn = {
  background: "transparent",
  border: "none",
  color: "green",
  display: "flex",
  alignItems: "center",
  gap: "5px",
}
