/* eslint-disable react/prop-types */
import React, {useState, useEffect} from "react"
import ReactApexChart from "react-apexcharts"

const Spinearea = (props) => {
  let [data, setData]  = useState([]) 
  

  const options = {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    
    colors: ["#83c29d", "#ec7964", "#727272", "#00f"],
    xaxis: {
      type: 'category',
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      labels: {
          show: true,
          rotate: -45,
          rotateAlways: false,
          hideOverlappingLabels: true,
          showDuplicates: false,
          trim: false,
          minHeight: undefined,
          maxHeight: 120,
          style: {
              colors: [],
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
          },
          offsetX: 0,
          offsetY: 0,
          format: undefined,
          formatter: undefined,
          datetimeUTC: true,
          datetimeFormatter: {
              year: 'yyyy',
              month: "MMM 'yy",
              day: 'dd MMM',
              hour: 'HH:mm',
          },
      },
    },
  }
  useEffect(() => {
    setData(props.chart);
  }, [props.chart])
  return (
    <ReactApexChart
      options={options}
      series={data}
      type="area"
      height="500"
    />
  )
}

export default Spinearea
