/* eslint-disable react/prop-types */
import axios from 'axios';
import React, {useState, useRef, useEffect} from 'react';
import { Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import PulseLoader from "react-spinners/PulseLoader";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import {Link} from "react-router-dom"
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import './specification.scss'


const NotificationForm = (props) => {
    let p = props 
    const [isOpen, setisOpen] = useState(false);
    const [messEn, setMessEn] = useState('');
    const [messAr, setMessAr] = useState('');
    const [link, setLink] = useState('');
    const [loading, setLoading] = useState(false)
    
    const categoryEnRef = useRef(null);
    const categoryArRef = useRef(null);
    const tagRef = useRef(null);
    const colorRef = useRef(null);
    const imageRef = useRef(null);
    

    function submitHandler() {
        setLoading(true)
        
        let data = {
            id: props.userData.id,
            message: props.userData.lang === "en"? messEn:messAr
        } 

        axios.post(`${process.env.REACT_APP_API}/admin/reports/notify-user`, data, {
            headers: { 
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`, 
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            setLoading(false)
            props.clickHandle(null, false)
            setMessEn('')
            setMessAr('')
        })
    }



    return(
        <div className={`overlay_coup ${p.active? "active":""}`}>
            {isOpen ? (
            <Lightbox
                mainSrc={image.preview}
                enableZoom={false}
                onCloseRequest={() => {
                setisOpen(!isOpen);
                }}
            />
            ) : null}
            <Form id="pushForm" onSubmit={(e) => e.preventDefault()} style={{paddingBottom: "70px", height: "250px"}}>
                <div className='head_form'>
                    <div className='log'>
                    <i className='bx bx-bell fs-3'></i>
                        Add Notification
                    </div>
                    <button onClick={() => p.clickHandle()}><i className='dripicons dripicons-cross'></i></button>
                </div>
                <div className='inputs__wrap '>
                {props.lang !== "ar"? <Row>
                        <Col>
                            <div className="mb-3" ref={categoryEnRef}>
                                <Label htmlFor="productdesc">
                                    Message (English)
                                </Label>
                                <Input
                                    id="title"
                                    name="title"
                                    type="text"
                                    className="form-control input_cus"
                                    placeholder="e.g.title"
                                    value={messEn}
                                    onChange= {e => setMessEn(e.target.value)}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>:<Row>
                        <Col>
                            <div className="mb-3" ref={categoryArRef}>
                                <Label htmlFor="productdesc">
                                    Message (Arabic)
                                </Label>
                                <Input
                                    id="title"
                                    name="title"
                                    type="text"
                                    className="form-control input_cus"
                                    placeholder="مثال. عنوان"
                                    dir='rtl'
                                    value={messAr}
                                    onChange= {e => setMessAr(e.target.value)}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    }
                </div>

                <div className='foot_form'>
                    <button className='cancel' onClick={() => p.clickHandle()}>Cancel</button>
                    <button className='save' onClick={submitHandler}>{loading?<PulseLoader color={"#ffffff"} loading={true}  size={10} />:"Send"}</button>
                </div>
            </Form>
        </div>
    );
};

export default NotificationForm;
