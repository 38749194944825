/* eslint-disable react/prop-types */
import axios from 'axios';
import React, {useState, useRef, useEffect} from 'react';
import { Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import PulseLoader from "react-spinners/PulseLoader";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import {Link} from "react-router-dom"
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import './specification.scss'


const UpdateAvatarForm = (props) => {
    let p = props 
    const [isOpen, setisOpen] = useState(false);
    const [gender, setGender] = useState('')
    const [image, setImage] = useState(null)
    const [loading, setLoading] = useState(false)
    const [maleCheck, setMaleCheck] = useState(false)
    const [femaleCheck, setFemaleCheck] = useState(false)
    const [gen, setGen] = useState(false)
    const imageRef = useRef(null);

    function handleAcceptedFiles(files) {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size)
            })
        )
        setImage(files[0])
    }

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }


    function submitHandler() {
        setLoading(true)
        const formData = new FormData();
        
        let data = {
            gender,
        } 

        for ( let key in data ) {
            formData.append(key, data[key]);
        }
        if(image.name) {
            formData.append("image", image);
        }
        if(props.avatar) {
            formData.append("id", props.avatar.key);
        }
        var config = {
            method: 'post',
            url: p.avatar?`${process.env.REACT_APP_API}/admin/setting/avatarUpdate`:`${process.env.REACT_APP_API}/admin/setting/avatarAdd`,
            headers: { 
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`, 
                'Content-Type': 'application/json'
            },
            data : formData
        };
        axios(config)
        .then(response => {
            let res = response.data;
            if(res.error) {
                return;
            }
            setLoading(false)
            p.avatar?props.update(response.data.data, "update"):props.update(response.data.data, "add")
            document.getElementById("avatarForm").reset()
            close()
            setImage(null)
        })
        .catch(function (error) {
            console.log(error);
        });
    }
    

    function close () {
        setGender('')
        setImage(null) 
        setGen(false)
        p.clickHandle(null, false)
    }

    useEffect(() => {
        if(props.avatar) {
            console.log(props.avatar);
            setGender(props.avatar.gender)
            props.avatar.gender === "male"? setMaleCheck(true):setFemaleCheck(true)
            setImage({preview: props.avatar.image})
            setGen(true)
        }
    }, [props.avatar])

    return(
        <div className={`overlay_coup ${p.active? "active":""}`}>
            {isOpen ? (
            <Lightbox
                mainSrc={image.preview}
                enableZoom={false}
                onCloseRequest={() => {
                setisOpen(!isOpen);
                }}
            />
            ) : null}
            <Form id="avatarForm" onSubmit={(e) => e.preventDefault()} style={{paddingBottom: "70px", height: "350px"}}>
                <div className='head_form'>
                    <div className='log fs-5'>
                        {props.avatar?"update":"add"} Avatar
                    </div>
                    <button onClick={close}><i className='dripicons dripicons-cross'></i></button>
                </div>
                <div className='inputs__wrap '>
                    
                    {gen?<Row>
                    <Label htmlFor="productdesc">Gender</Label>
                        <Col xs={2}>
                            <div className="mb-3" >
                                <Label htmlFor="productdesc"  className='radio'>
                                <Input
                                    name="gender"
                                    type="radio"
                                    className="form-control"
                                    placeholder=""
                                    value="male"
                                    onChange= {e => setGender(e.currentTarget.value)}
                                    defaultChecked={gender === "male"}
                                />
                                    Male
                                </Label>
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                        <Col >
                            <div className="mb-3">
                                <Label htmlFor="productdesc" className='radio'>
                                    <Input
                                        name="gender"
                                        type="radio"
                                        className="form-control"
                                        placeholder=""
                                        value="female"
                                        onChange= {e => setGender(e.currentTarget.value)}
                                        defaultChecked={gender === "female"}
                                    />
                                    Female
                                </Label>
                                
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>:null}
                    <Row>
                        <Col>
                            <div className="mb-3">
                                <Label htmlFor="metatitle">Avatar Image</Label>
                                <div className="mb-3" ref={imageRef}>
                                    <Input 
                                        type="file"
                                        className="form-control form-control-lg "
                                        onChange={e => handleAcceptedFiles(Object.values(e.target.files), e.target.value = '')}
                                        style={{borderRadius: 0}}
                                    />
                                    <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                                </div>
                            </div>
                        </Col>
                        {image? <Row className="align-items-center" style={{paddingLeft: "14px", paddingRight: "0px"}}>
                            <Col className="col-auto">
                                <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={image.name}
                                src={image.preview}
                                style={{objectFit: "cover"}}
                                />
                            </Col>
                            <Col>
                                <Link
                                to="#"
                                className="text-muted "
                                >
                                {image.name}
                                </Link>
                                <p className="mb-0">
                                <strong>{image.formattedSize}</strong>
                                </p>
                            </Col>
                            <Col className='p-0'>
                                <div className="image__options">
                                    <button className="p-0"  onClick={(e) => setisOpen(true)}><i className="far fa-eye  fs-5 p-0"></i></button>
                                    <button className='p-0' style={{color: "#ff3838"}} onClick={(e) => setImage(null)}><i className='bx bx-trash fs-4'></i></button>
                                </div>
                            </Col>
                        </Row>:null}
                    </Row>
                </div>

                <div className='foot_form'>
                    <button className='cancel' onClick={close}>Cancel</button>
                    <button className='save' onClick={submitHandler}>{loading?<PulseLoader color={"#ffffff"} loading={true}  size={10} />:"Save"}</button>
                </div>
            </Form>
        </div>
    );
};

export default UpdateAvatarForm;
