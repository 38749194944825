import { GET_DASHBOARD_DATA_FAIL, GET_DASHBOARD_DATA_SUCCESS } from "./actionsTypes"


const initialState = {
  data: {},
  countries:[],
  latestUsers: [],
  dataMAle:[],
  dataFemale: [],
  dataOther: [],
  dataAndroid: [],
  dataIphone: [],
  dataAll: [],
  error: {}
}

const Dashboard = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        countries: action.payload.countries,
        latestUsers: action.payload.latestUsers,
        dataMAle: action.payload.dataMAle,
        dataFemale: action.payload.dataFemale,
        dataOther: action.payload.dataOther,
        dataAndroid: action.payload.dataAndroid,
        dataIphone: action.payload.dataIphone,
        dataAll: action.payload.dataAll,
      }

    case GET_DASHBOARD_DATA_FAIL:
      return {
        ...state,
        error: action.payload
      }

    default:
      return state
  }
}

export default Dashboard