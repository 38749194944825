import usFlag from "../assets/images/flags/us.jpg"
import arabic from "../assets/images/flags/arabic.png"

const languages = {
  en: {
    label: "English",
    flag: usFlag,
  },
  ar: {
    label: "Arabic",
    flag: arabic,
  }
}

export default languages
