import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { get, map } from "lodash";
import { withTranslation } from "react-i18next";

//i18n
import i18n from "../../../i18n";
import languages from "common/languages";

const LanguageDropdown = () => {
  // Declare a new state variable, which we'll call "menu"
  const [selectedLang, setSelectedLang] = useState("");
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    const currentLanguage = localStorage.getItem("I18N_LANGUAGE");
    setSelectedLang(currentLanguage);
  }, [])

  const changeLanguageAction = lang => {
    //set language as i18n
    i18n.changeLanguage(lang);
    localStorage.setItem("I18N_LANGUAGE", lang);
    setSelectedLang(lang);
  }

  const toggle = () => {
    setMenu(!menu);
  }

  return (
    <>
      <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
        <DropdownToggle className="btn header-item " tag="button">
          <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
            <span>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 3V16.5H7.5V21H21V7.5H16.5V3H3ZM4.5 4.5H15V7.92188L7.92188 15H4.5V4.5ZM8.25 6V6.75H6V8.25H9.70312C9.60645 9.11133 9.34277 9.79102 8.90625 10.2656C8.64844 10.1807 8.41699 10.0752 8.22656 9.96094C7.69922 9.64746 7.5 9.31348 7.5 9H6C6 9.89355 6.55078 10.6377 7.28906 11.1328C6.91992 11.2119 6.49219 11.25 6 11.25V12.75C7.33008 12.75 8.4375 12.4453 9.28125 11.8828C9.67383 11.9502 10.0723 12 10.5 12V10.5938C10.9072 9.91113 11.124 9.11426 11.2031 8.25H12V6.75H9.75V6H8.25ZM16.0781 9H19.5V19.5H9V16.0781L16.0781 9ZM15 10.3828L14.2969 12.5156L12.7969 17.0156L12.75 17.1328V18H14.25V17.3438L14.2734 17.25H15.7266L15.75 17.3438V18H17.25V17.1328L17.2031 17.0156L15.7031 12.5156L15 10.3828ZM15 15.0938L15.2109 15.75H14.7891L15 15.0938Z" fill="black"/>
              </svg>
            </span>
            <span  className="fs-5" style={{color: "#000000"}}>Languages</span>
          </div>
        </DropdownToggle>
        <DropdownMenu className="language-switch dropdown-menu-end">
          {map(Object.keys(languages), key => (
            <DropdownItem
              key={key}
              onClick={() => changeLanguageAction(key)}
              className={`notify-item ${selectedLang === key ? "active" : "none"
                }`}
            >
              <img
                src={get(languages, `${key}.flag`)}
                alt="Skote"
                className="me-1"
                height="12"
              />
              <span className="align-middle">
                {get(languages, `${key}.label`)}
              </span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default withTranslation()(LanguageDropdown)
