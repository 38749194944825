import {
  GET_DASHBOARD_DATA_FAIL,
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_DATA_SUCCESS
} from "./actionsTypes"

export const getDashboardData = () => ({
  type: GET_DASHBOARD_DATA,
})

export const getDashboardDataSuccess = data => ({
  type: GET_DASHBOARD_DATA_SUCCESS,
  payload: data
})

export const getDashboardDataFailed = (error) => ({
  type: GET_DASHBOARD_DATA_FAIL,
  payload: error
})