import { call, put, takeEvery } from "redux-saga/effects"
import { getDashboardDataFailed, getDashboardDataSuccess } from "./actions"
import { GET_DASHBOARD_DATA } from "./actionsTypes"
import { getDashboardData } from '../../helpers/urlHelpers'


function* fetchDashboardData() {
  try {
    const response = yield call(getDashboardData)
    yield put(getDashboardDataSuccess(response.data))
  } catch (error) {
    yield put(getDashboardDataFailed(error?.response?.message))
  }
}

function* dashboardSaga() {
  yield takeEvery(GET_DASHBOARD_DATA, fetchDashboardData)
}

export default dashboardSaga;