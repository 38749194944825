/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Form, Label, Input, FormFeedback } from "reactstrap"
import MetaTags from 'react-meta-tags'
import PulseLoader from "react-spinners/PulseLoader";

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import AddUserForm from './AddAvatarForm'
import "./datatables.scss"
import './specification.scss'
import axios from "axios";
import { toast } from "react-toastify";
import SweetAlert from "react-bootstrap-sweetalert"

const SEOApp = (props) => {
  const [active, setActive] = useState(false)
  const[feedbackTime, setFeedbackTime] = useState("")
  const[userNoticePeriod, setUserNoticePeriod] = useState("")
  const[toyNoticePeriod, setToyNoticePeriod] = useState("")
  const [loading, setLoading] = useState(false)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")

  function submit(e) {
    e.preventDefault();
    setLoading(true)
    let data = {
      feedback_notice_period: feedbackTime,
      user_notice_period: userNoticePeriod,
      toy_notice_period: toyNoticePeriod
    }
    axios.post(`${process.env.REACT_APP_API}/admin/setting/timeUpdate`, data, {
      headers: {
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`,
      }
    })
      .then((res) => {
        if(res.data.error) {
          console.log(res.data.error);
        }
        setMsg("Updated!")
        setsuccess_msg(true)
        setLoading(false)
      })
  }


  useEffect(() => {
      if (props.seo) {
        setFeedbackTime(props.seo.feedback_notice_period)
        setToyNoticePeriod(props.seo.toy_notice_period)
        setUserNoticePeriod(props.seo.user_notice_period)
      }
  }, [props.seo])



  return (
    <React.Fragment>
      <div className="container-fluid p-0">
        {success_msg ? (
          <SweetAlert
            title={msg}
            success
            confirmBtnBsStyle="success"
            onConfirm={() => {
              setsuccess_msg(false)
            }}
            onCancel={() => {
              setsuccess_msg(false)
            }}
          >
          </SweetAlert>
        ) : null}
        <Card>
          <CardBody>
            <Form className="border-0 p-0">
              <Row>
                <Col>
                  <div className="mb-3" >
                    <Label htmlFor="productdesc">
                      Feedback Time
                    </Label>
                    <Input

                      name="title"
                      type="text"
                      className="form-control input_cus"
                      placeholder="e.g.Name"
                      value={feedbackTime}
                      onChange = {e => setFeedbackTime(e.target.value)}
                    />
                    <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                  </div>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <div className="mb-1" >
                    <Label htmlFor="productdesc">
                      Update Toy Time
                    </Label>
                    <Input

                      name="title"
                      type="text"
                      className="form-control input_cus"
                      placeholder="e.g.Name"
                      value={userNoticePeriod}
                      onChange = {e => setUserNoticePeriod(e.target.value)}
                    />
                    <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                  </div>
                </Col>
              </Row>

              <Row className="mb-1">
                <Col>
                  <div className="mb-1" >
                    <Label htmlFor="productdesc">
                      Add a Toy Time
                    </Label>
                    <Input

                      name="title"
                      type="text"
                      className="form-control input_cus"
                      placeholder="e.g.Name"
                      value={toyNoticePeriod}
                      onChange= {e => setToyNoticePeriod(e.target.value)}
                    />
                    <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="save__btn top m-0">
                    <button onClick={e => submit(e)}>{loading?<PulseLoader color={"#ffffff"} loading={true}  size={10} />:"Save"}</button>
                  </div>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}

export default SEOApp

const deleteBtn = {
  background: "transparent",
  border: "none",
  color:"#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px"

}

const editBtn = {
  background: "transparent",
  border: "none",
  color:"green",
  display: "flex",
  alignItems: "center",
  gap: "5px"
}