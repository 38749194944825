import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"

import { Card, CardBody, Col, Row, Container } from "reactstrap"
import Cards from "./Cards"
import DashTable from "./DashTable"
import SplineArea from "./SplineArea"
import SplineAreaMobile from "./SplineAreaMobile"

import axios from "axios"
import Select from "react-select"
import { useSelector, useDispatch } from "react-redux"
import {
  setTitle
} from "../../../../store/actions"

function EToyPaperDashboard() {
  const dispatch = useDispatch()
  const categories = useSelector(state => state.MainData.categories)
  const   countries = useSelector(state => state.MainData.countries)
  const DataSelector = useSelector(state => state.Dashboard.data)
  const latestUsersSelector = useSelector(state => state.Dashboard.latestUsers)
  const dataMAleSelector = useSelector(state => state.Dashboard.dataMAle)
  const dataFemaleSelector = useSelector(state => state.Dashboard.dataFemale)
  const dataOtherSelector = useSelector(state => state.Dashboard.dataOther)
  const dataAndroidSelector = useSelector(state => state.Dashboard.dataAndroid)
  const dataIphoneSelector = useSelector(state => state.Dashboard.dataIphone)
  const dataAllSelector = useSelector(state => state.Dashboard.dataAll)

  const [data, setData] = useState(null)
  const [users, setUsers] = useState([])
  const [info, setInfo] = useState([])
  const [info2, setInfo2] = useState([])
  const [chart, setChart] = useState([])
  const [chart2, setChart2] = useState([])
  const columns = [{
    dataField: "id",
    text: "Id",
    sort: true
  }, {
    dataField: "title",
    text: "Title",
    sort: true
  }, {
    dataField: "value",
    text: "Value",
    sort: true
  }]
  const userColumns = [{
    dataField: "id",
    text: "Id",
    sort: true
  }, {
    dataField: "nameEn",
    text: "Name",
    sort: true
  }, {
    dataField: "email",
    text: "Email",
    sort: true
  }, {
    dataField: "toysCount",
    text: "Toys",
    sort: true
  }]


  function countryGet(value) {
    axios.get(`${process.env.REACT_APP_API}/admin/dashboard?country=${value}`, {
      headers: {
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`,
      }
    })
      .then(res => {
        let {
          addAToy,
          reviewToys,
          closeDealFree,
          closeDealPaid,
          closeDealSwap,
          favouriteToy,
          points,
          sendMessage,
          appRate,
          suggestionDrawerMenu,
          suggestionToSetting,
          continueAsGuest,
          resetPassword,
          updateLocation,
          updatePictureProfile,
          updateEmail,
          search,
          deleteToys,
          cancelPendingRequest,
          usingArabic,
          usingEnglish,
          share,
        } = res.data.data
        let {
          newToysCount,
          usedToysCount,
          boyToysCount,
          girlToysCount,
          bothToysCount,
        } = res.data.data
        let info = [
          {id:1,  title: "Add a Toy",           value: addAToy},
          {id:2,  title: "Review Another Toys", value: reviewToys},
          {id:3,  title: "Close Deal as Swap",  value: closeDealSwap},
          {id:4,  title: "Close Deal as Free",  value: closeDealFree},
          {id:5,  title: "Close Deal as Paid",  value: closeDealPaid},
          {id:6,  title: "Favourite Toys",      value: favouriteToy},
          {id:7,  title: "Points",              value: points},
          {id:8,  title: "Send a Message",      value: sendMessage},
          {id:9,  title: "Suggestion Drawer Menu",  value: suggestionDrawerMenu},
          {id:10, title: "Suggestion To Setting",   value: suggestionToSetting},
          {id:11, title: "Reset password",      value: resetPassword},
          {id:12, title: "Update location",     value: updateLocation},
          {id:13, title: "Update picture profile",   value: updatePictureProfile},
          {id:14, title: "Update email",        value: updateEmail},
          {id:15, title: "Search",              value: search},
          {id:17, title: "Delete toys",         value: deleteToys},
          {id:18, title: "Cancel pending request",   value: cancelPendingRequest},
          {id:19, title: "Using Arabic",        value: usingArabic},
          {id:20, title: "Using English",       value: usingEnglish},
          {id:21, title: "Share",               value: share},
          {id:22, title: "Continue As Guest",   value: continueAsGuest},
        ]
        let info2 = [
          {id:1,  title: "New Toys",           value: newToysCount},
          {id:2,  title: "Used Toys", value: usedToysCount},
          {id:3,  title: "Toys for boy",  value: boyToysCount},
          {id:4,  title: "Toys for girl",  value: girlToysCount},
          {id:5,  title: "Toys for both gender",  value: bothToysCount},
        ]
        let cat =(categories||[]).map((c, i) => {return {id: i+1+5, title: c.nameEn, value: c.toyCount}})
        setData(res.data.data)
        // setCountries(res.data.data.countries)
        setUsers(res.data.data.latestUSers.map(c => {return{...c, id: c.key}}))
        setInfo(info)
        setInfo2(info2.concat(cat))
        const male =  res.data.data.dataMAle
        const female =  res.data.data.dataFemale
        const others =  res.data.data.dataOther
        const all = res.data.data.dataMAle.map((c,i) => male[i]+female[i]+others[i])
        setChart([{name: `Male(${male.reduce(add, 0)})`, data: res.data.data.dataMAle},{name: `Female(${female.reduce(add, 0)})`, data: res.data.data.dataFemale},{name: `Others(${others.reduce(add, 0)})`, data: res.data.data.dataOther},{name: `All(${all.reduce(add, 0)})`, data: all}])
        setChart2([{name: `Android(${res.data.data.dataAndroid.reduce(add, 0)})`, data: res.data.data.dataAndroid},{name: `Iphone (${res.data.data.dataIphone.reduce(add, 0)})`, data: res.data.data.dataIphone},{name: `All(${res.data.data.dataAll.reduce(add, 0)})`, data: res.data.data.dataAll}])
      })
  }

  function add(accumulator, a) {
    return accumulator + a
  }

  useEffect(() => {
    dispatch(setTitle("Dashboard"))
    let info = [
      { id: 1, title: "Add a Toy", value: DataSelector.addAToy },
      { id: 2, title: "Review Another Toys", value: DataSelector.reviewToys },
      { id: 3, title: "Close Deal as Swap", value: DataSelector.closeDealSwap },
      { id: 4, title: "Close Deal as Free", value: DataSelector.closeDealFree },
      { id: 5, title: "Close Deal as Paid", value: DataSelector.closeDealPaid },
      { id: 6, title: "Favourite Toys", value: DataSelector.favouriteToy },
      { id: 7, title: "Points", value: DataSelector.points },
      { id: 8, title: "Send a Message", value: DataSelector.sendMessage },
      { id: 9, title: "Suggestion Drawer Menu", value: DataSelector.suggestionDrawerMenu },
      { id: 10, title: "Suggestion To Setting", value: DataSelector.suggestionToSetting },
      { id: 11, title: "Reset password", value: DataSelector.resetPassword },
      { id: 12, title: "Update location", value: DataSelector.updateLocation },
      { id: 13, title: "Update picture profile", value: DataSelector.updatePictureProfile },
      { id: 14, title: "Update email", value: DataSelector.updateEmail },
      { id: 15, title: "Search", value: DataSelector.search },
      { id: 17, title: "Delete toys", value: DataSelector.deleteToys },
      { id: 18, title: "Cancel pending request", value: DataSelector.cancelPendingRequest },
      { id: 19, title: "Using Arabic", value: DataSelector.usingArabic },
      { id: 20, title: "Using English", value: DataSelector.usingEnglish },
      { id: 21, title: "Share", value: DataSelector.share },
      { id: 22, title: "Continue As Guest", value: DataSelector.continueAsGuest }
    ]
    let info2 = [
      {id:1,  title: "New Toys",           value: DataSelector.newToysCount},
      {id:2,  title: "Used Toys", value: DataSelector.usedToysCount},
      {id:3,  title: "Toys for boy",  value: DataSelector.boyToysCount},
      {id:4,  title: "Toys for girl",  value: DataSelector.girlToysCount},
      {id:5,  title: "Toys for both gender",  value: DataSelector.bothToysCount},
    ]

    let cat = (categories || []).map((c, i) => {
      return { id: i + 1 + 5, title: c.nameEn, value: c.toyCount }
    })
    setData(DataSelector)
    setUsers(latestUsersSelector.map(c => {
      return { ...c, id: c.key }
    }))
    setInfo(info)
    setInfo2(info2.concat(cat))
    const male =  dataMAleSelector
    const female =  dataFemaleSelector
    const others =  dataOtherSelector
    const all = dataMAleSelector.map((c,i) => male[i]+female[i]+others[i])
    setChart([{name: `Male(${male.reduce(add, 0)})`, data: dataMAleSelector},{name: `Female(${female.reduce(add, 0)})`, data: dataFemaleSelector},{name: `Others(${others.reduce(add, 0)})`, data: dataOtherSelector},{name: `All(${all.reduce(add, 0)})`, data: all}])
    setChart2([{name: `Android(${dataAndroidSelector.reduce(add, 0)})`, data: dataAndroidSelector},{name: `Iphone (${dataIphoneSelector.reduce(add, 0)})`, data: dataIphoneSelector},{name: `All(${dataAllSelector.reduce(add, 0)})`, data: dataAllSelector}])
  }, [categories])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | Etoy App</title>
        </MetaTags>
        <Container fluid>
          <Row className="mb-3">
            <Col>
              <Select
                options={countries ? [{ value: "All", label: "All" }].concat(countries.map(c => {
                  return { value: c.nameEn, label: c.nameEn }
                })) : null}
                placeholder="Country"
                onChange={(e) => countryGet(e.value)}
              />
            </Col>
          </Row>

          {/*{console.log('inside return',dashboardData.countries)}*/}
          <Cards data={data} />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h5 className="b-block ml-1" style={{ marginLeft: "15px" }}>Gender</h5>
                  <SplineArea chart={chart} />
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card>
                <CardBody>
                  <h5 className="b-block ml-1" style={{ marginLeft: "15px" }}>Mobile Platform</h5>
                  <SplineAreaMobile chart={chart2} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <DashTable title={"User activities"} columns={columns} data={info}/>
            </Col>
            <Col>
              <DashTable title={"Toy activities"} columns={columns} data={info2}/>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default EToyPaperDashboard
