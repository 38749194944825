import {
  GET_USER_DATA
} from "./actionTypes"


export const getUserData = data => ({
  type: GET_USER_DATA,
  payload: data,
})

