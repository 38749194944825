import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef()
  const notifications = useSelector(state => state.Notifications)
  const MainData = useSelector(state => state.MainData)
  const [notify, setNotify] = useState({
    notifications: [],
    userCount: 0,
    toyCount: 0,
    notifyCount: 0,
    suggestionCount: 0,
    feedbackCount: 0,
    reviewCount: 0,
    reportCount: 0
  })
  const user = useSelector(state => state.UserData.user)
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  useEffect(() => {
    setNotify(notifications)
  }, [notifications])


  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("General")} </li>
            <li>
              <Link to="/dashboard" className="has-arrow">
                <i className="bx bx-grid-alt" />
                <span style={{ fontSize: "14px" }}>{props.t("Dashboards")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/etoyapp-dashboard">{props.t("eToy App")}</Link>
                </li>
                <li>
                  <Link to="/etoypaper-dashboard"> {props.t("eToy Paper")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/analysis" className="has-arrow">
                <i className="bx bx-pie-chart-alt-2" />
                <span>{props.t("Analysis")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/etoyapp-analysis">{props.t("eToy App")}</Link>
                </li>
                <li>
                  <Link to="/etoypaper-analysis">
                    {props.t("eToy Paper")}
                  </Link>
                </li>
              </ul>
            </li>

            {user && user.role === 2 ? null : <>
              

              <li>
                <Link to="/request-toy">
                    <span className="badge rounded-pill bg-danger float-end">
                    </span>
                  <i className="bx bx-dish" />
                  <span style={{ fontSize: "14px" }}>{props.t("Request Toy")}</span>
                </Link>
              </li>

              <li className="menu-title">{props.t("eToy App")} </li>
              <li>
                <Link to="/users">
                  <span className="badge rounded-pill bg-danger float-end">
                    {notifications.userCount !== 0 && notifications.userCount <= 9 ? 0 : null}{notifications.userCount === 0 ? null : notifications.userCount}
                  </span>
                  <i className="bx bx-group" />
                  <span>{props.t("Users")}</span>
                </Link>
              </li>

             
              <li>
                <Link to="/etoyapp-toys">
                    <span className="badge rounded-pill bg-danger float-end">
                      {notifications.toyCount !== 0 && notifications.toyCount <= 9 ? 0 : null}{notifications.toyCount === 0 ? null : notifications.toyCount}
                    </span>
                  <i className="bx bx-extension" />
                  <span style={{ fontSize: "14px" }}>{props.t("Toys")}</span>
                </Link>
              </li>
              <li>
                <Link to="/notifications">
                    <span className="badge rounded-pill bg-danger float-end">
                      {notifications.notifyCount !== 0 && notifications.notifyCount <= 9 ? 0 : null}{notifications.notifyCount === 0 ? null : notifications.notifyCount}
                    </span>
                  <i className="bx bx-dish" />
                  <span style={{ fontSize: "14px" }}>{props.t("Notifications")}</span>
                </Link>
              </li>
          

              <li>
                <Link to="/etoyapp-suggestion">
                    <span className="badge rounded-pill bg-danger float-end">
                      {notifications.suggestionCount !== 0 && notifications.suggestionCount <= 9 ? 0 : null}{notifications.suggestionCount === 0 ? null : notifications.suggestionCount}
                    </span>
                  <i className="bx bx-message-alt-add" />
                  <span style={{ fontSize: "14px" }}>{props.t("Suggestion")}</span>
                </Link>
              </li>

              <li>
                <Link to="/etoyapp-feedback">
                    <span className="badge rounded-pill bg-danger float-end">
                      {notifications.feedbackCount !== 0 && notifications.feedbackCount <= 9 ? 0 : null}{notifications.feedbackCount === 0 ? null : notifications.feedbackCount}
                    </span>
                  <i className="bx bx-comment-add" />
                  <span style={{ fontSize: "14px" }}>{props.t("Feedback")}</span>
                </Link>
              </li>

              <li>
                <Link to="/etoyapp-reports">
                    <span className="badge rounded-pill bg-danger float-end">
                      {notifications.reportCount !== 0 && notifications.reportCount <= 9 ? 0 : null}{notifications.reportCount === 0 ? null : notifications.reportCount}
                    </span>
                  <i className="bx bx-receipt" />
                  <span style={{ fontSize: "14px" }}>{props.t("Reports")}</span>
                </Link>
              </li>
        
              <li>
                <Link to="/etoyapp-newAdvertise">
                  <i className="bx bx-dice-1" />
                  <span style={{ fontSize: "14px" }}>{props.t("Advertising")}</span>
                </Link>
              </li>

              <li>
                <Link to="/etoyapp-pages">
                  <i className="bx bx-coin-stack" />
                  <span style={{ fontSize: "14px" }}>{props.t("Pages")}</span>
                </Link>
              </li>


              <li className="menu-title">{props.t("eToy Store")} </li>
              <li>
                <Link to="/etoystore-toys">
                  <i className="bx bx-store-alt" />
                  <span style={{ fontSize: "14px" }}>{props.t("Toys")}</span>
                </Link>
              </li>
              <li>
                <Link to="/etoystore-feedback">
                  <i className="bx bx-comment-add" />
                  <span style={{ fontSize: "14px" }}>{props.t("Feedback")}</span>
                </Link>
              </li>


              <li className="menu-title">{props.t("eToy Paper")} </li>
              <li>
                <Link to='/etoyapp-users'>
                  <i className="bx bx-group" />
                  <span style={{ fontSize: "14px" }}>{props.t("Users")}</span>

                </Link>
              </li>

              <li>
                <Link to="/etoypaper-toys">
                  <i className="bx bx-message-square-x" />
                  <span style={{ fontSize: "14px" }}>{props.t("Toys")}</span>
                </Link>
              </li>

              <li>
                <Link to="/etoypaper-suggestions">
                  <i className="bx bx-dice-4" />
                  <span style={{ fontSize: "14px" }}>{props.t("Suggestions")}</span>
                </Link>
              </li>

              <li>
                <Link to="/etoypaper-feedback">
                  <i className="bx bx-comment-add" />
                  <span style={{ fontSize: "14px" }}>{props.t("Feedback")}</span>
                </Link>
              </li>
              

              <li>
                <Link to="/etoypaper-advertises">
                  <i className="bx bx-dice-1" />
                  <span style={{ fontSize: "14px" }}>{props.t("Advertises")}</span>
                </Link>
              </li>

              <li>
                <Link to="/etoypaper-pages">
                  <i className="bx bx-coin-stack" />
                  <span style={{ fontSize: "14px" }}>{props.t("Pages")}</span>
                </Link>
              </li>

              <li className="menu-title">{props.t("Admin Section")} </li>
              <li>
                <Link to="/etoyapp-admintoys">
                  <i className="bx bx-coin-stack" />
                  <span style={{ fontSize: "14px" }}>{props.t("Admin Toys")}</span>
                </Link>
              </li>

              <li>
                <Link to="/admin-notifications">
                  <i className="bx bx-coin-stack" />
                  <span style={{ fontSize: "14px" }}>{props.t("Admin Notification")}</span>
                </Link>
              </li>

              <li>
                <Link to="/admin-settings">
                  <i className="bx bx-coin-stack" />
                  <span style={{ fontSize: "14px" }}>{props.t("Admin settings")}</span>
                </Link>
              </li>


              <li className="menu-title">{props.t("Settings")} </li>
              <li>
                <Link to="/configurations">
                  <i className="bx bx-cog" />
                  <span style={{ fontSize: "14px" }}>{props.t("Configurations")}</span>
                </Link>
              </li>
            </>}
          </ul>




        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any
}

export default withRouter(withTranslation()(SidebarContent))
