/* eslint-disable react/prop-types */
import axios from 'axios';
import React, {useState, useRef, useEffect} from 'react';
import { Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import PulseLoader from "react-spinners/PulseLoader";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import {Link} from "react-router-dom"
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import './specification.scss'


const AddCategoryForm = (props) => {
    let p = props 
    const [isOpen, setisOpen] = useState(false);
    const [name_en, setNameEn] = useState('');
    const [name_ar, setNameAr] = useState('');  
    const [id, setId] = useState('');  
    const [loading, setLoading] = useState(false)
    



    

    function submitHandler() {
        setLoading(true)
        
        let data = {
            name_en,
            name_ar,
            id
        } 

        axios.post(props.category?`${process.env.REACT_APP_API}/admin/setting/categoryUpdate`:`${process.env.REACT_APP_API}/admin/setting/categoryAdd`, data, {
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`, 
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            // props.eToyCategoryChanges(res.data.data, "addEToyCategory")
            props.clickHandle()
            props.category? props.update(res.data.data, "update"):props.update(res.data.data, "add")
            setLoading(false)
            document.getElementById("categForm").reset()
        })
    }


    useEffect(() => {
        if(p.category) {
            setNameEn(p.category.nameEn)
            setNameAr(p.category.nameAr)
            setId(p.category.key)
            return;
        }
    }, [p.category])


    return(
        <div className={`overlay_coup ${p.active? "active":""}`}>
            <Form id="categForm" onSubmit={(e) => e.preventDefault()} style={{paddingBottom: "70px", height: "320px"}}>
                <div className='head_form'>
                    <div className='log fs-5'>
                    {/* <i className="fas fa-user fs-5"></i> */}
                        Add Category
                    </div>
                    <button onClick={() => p.clickHandle()}><i className='dripicons dripicons-cross'></i></button>
                </div>
                <div className='inputs__wrap '>
                <Row>
                        <Col>
                            <div className="mb-3" >
                                <Label htmlFor="productdesc">
                                    Category Name (English)
                                </Label>
                                <Input
                                    
                                    name="title"
                                    type="text"
                                    className="form-control input_cus"
                                    placeholder="e.g.Name"
                                    value={name_en}
                                    onChange= {e => setNameEn(e.target.value)}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="mb-3" >
                                <Label htmlFor="productdesc">
                                    Category Name (Arabic)
                                </Label>
                                <Input
                                    
                                    name="title"
                                    type="text"
                                    className="form-control input_cus"
                                    placeholder="مثال. الاسم"
                                    dir='rtl'
                                    value={name_ar}
                                    onChange= {e =>setNameAr(e.target.value)}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className='foot_form'>
                    <button className='cancel' onClick={() => p.clickHandle()}>Cancel</button>
                    <button className='save' onClick={submitHandler}>{loading?<PulseLoader color={"#ffffff"} loading={true}  size={10} />:"Save"}</button>
                </div>
            </Form>
        </div>
    );
};

export default AddCategoryForm;
