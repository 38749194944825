import React from "react"
import { Redirect } from "react-router-dom"

// Profile
// import UserProfile from "../pages/Authentication/user-profile"



// Authentication related pages
import Login from "../pages/Authentication/Login"

/********************** General **********************/
// Dashboard
import Dashboard from "../pages/General/Dashboard/eToyApp"
//dashboard - eToy App
import EToyAppDashboard from "../pages/General/Dashboard/eToyApp"
//dashboard - eToy paper
import EToyPaperDashboard from "../pages/General/Dashboard/eToyPaper"
// Analysis
import Analysis from "../pages/General/Analysis/eToyPaper"
// Analysis - eToy App
import EToyAppAnalysis from "../pages/General/Analysis/eToyApp"
// Analysis - eToy Paper
import EToyPaperAnalysis from "../pages/General/Analysis/eToyPaper"
//Users
import Users from '../pages/General/Users/Users'
//Notifications
import Notifications from '../pages/General/Notifications/Notifications'
//Request A Toy
import RequestAToy from "../pages/General/RequestAToy/RequestAToy"

// -------------------------------------------------------------------------------------

/********************** Etoy Store **********************/
// Feedback
import EToyStoreFeedback from "../pages/EToy Store/Feedback/Feedback"
// Toys
import StoreToys from "../pages/EToy Store/Toys/Toys"

// -------------------------------------------------------------------------------------

/********************** eToy App **********************/
//Users
import PaperUsers from '../pages/EToyPaper/Users/Users'
//Advertises
import Advertises from '../pages/EToyApp/Advertises/Advertises'
import NewAdvertises from '../pages/EToyApp/NewAdvertises/NewAdvertises'
//Feedback
import Feedback from '../pages/EToyApp/Feedback/Feedback'
//Pages
import Pages from '../pages/EToyApp/Pages/Pages'
//Reports
import Reports from '../pages/EToyApp/Reports/Reports'
//Reviews
import Reviews from '../pages/EToyApp/Reviews/Reviews'
//Suggestions
import Suggestions from '../pages/EToyApp/Suggestions/Suggestions'
//Toys
import Toys from '../pages/EToyApp/Toys/Toys'

// -------------------------------------------------------------------------------------

/********************** eToy Paper **********************/
// Paper Advertises
import PaperAdvertises from "../pages/EToyPaper/NewAdvertises/NewAdvertises"
// EToy Paper Toys
import EToyPaperToys from "../pages/EToyPaper/EToyPaperToys/EToyPaperToys"
// Paper Pages
import PagesPaper from "../pages/EToyPaper/PagesPaper/PagesPaper"
// Paper Suggestions
import PaperSuggestions from "../pages/EToyPaper/Suggestions/Suggestions"
// Paper Feedback
import EToyPaperFeedback from "../pages/EToyPaper/Feedback/Feedback"

// -------------------------------------------------------------------------------------

/********************** Admin Section **********************/
//Admin Toys
import EToyAppToys from "../pages/AdminSection/AdminToys/EToyAppToys"
import AdminNotifications from "../pages/AdminSection/AdminNotifications/index"
import AdminSettings from "../pages/AdminSection/AdminSettings/AdminSettings"

// -------------------------------------------------------------------------------------

/********************** Configuration **********************/
//Configuration
import Options from '../pages/Options/Options'

// -------------------------------------------------------------------------------------

const authProtectedRoutes = [
  /**
   * General
   */
  //dashboard
  { path: "/dashboard", component: Dashboard },
  //dashboard - eToy App
  { path: "/etoyapp-dashboard", component: EToyAppDashboard },
  //dashboard - eToy paper
  { path: "/etoypaper-dashboard", component: EToyPaperDashboard },
  //Analysis
  { path: "/analysis", component: Analysis },
  //Analysis - eToy App
  { path: "/etoyapp-analysis", component: EToyAppAnalysis },
  //Analysis - eToy Paper
  { path: "/etoypaper-analysis", component: EToyPaperAnalysis },
  //Users
  { path: "/users", component: Users },
  //Notifications
  { path: "/notifications", component: Notifications },
  //Request a toy
  { path: "/request-toy", component: RequestAToy },

  // ---------------------------------------------------------------------

  /**
   * EToy App
   */
  //Toys
  { path: "/etoyapp-toys", component: Toys },
  //Suggestion
  { path: "/etoyapp-suggestion", component: Suggestions },
  // Feedback
  { path: "/etoyapp-feedback", component: Feedback},
  // Reviews
  { path: "/etoyapp-reviews", component: Reviews},
  // Reports
  { path: "/etoyapp-reports", component: Reports},
  // Advertise
  { path: "/etoyapp-advertise", component: Advertises},
  { path: "/etoyapp-newAdvertise", component: NewAdvertises},
  // Pages
  { path: "/etoyapp-pages", component: Pages},

  // ------------------------------------------------------------------------

  /**
   * EToy Store
   */
  //Toys
  { path: "/etoystore-toys", component: StoreToys},
  { path: "/etoystore-feedback", component: EToyStoreFeedback},

  // ------------------------------------------------------------------------

  /**
   * EToy Paper
   */
  //Toys
  { path: "/etoyapp-users", component: PaperUsers},
  { path: "/etoypaper-toys", component: EToyPaperToys},
  { path: "/etoypaper-suggestions", component: PaperSuggestions},
  { path: "/etoypaper-advertises", component: PaperAdvertises},
  { path: "/etoypaper-pages", component: PagesPaper},
  { path: "/etoypaper-feedback", component: EToyPaperFeedback},

  // ------------------------------------------------------------------------

  /**
   * Admin Section
   */
  // admin Toys
  { path: "/etoyapp-admintoys", component: EToyAppToys },
  { path: "/admin-notifications", component: AdminNotifications },
  { path: "/admin-settings", component: AdminSettings },

  // ------------------------------------------------------------------------

  /**
   * Configurations
   */
  //configurations
  { path: "/configurations", component: Options},

  // ------------------------------------------------------------------------

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/login", component: Login },
]

export { publicRoutes, authProtectedRoutes }
