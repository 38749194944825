import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import { withRouter } from "react-router-dom"
import {
  changeLayout,
  changeSidebarTheme,
  changeSidebarThemeImage,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
  showRightSidebarAction, getDashboardData
} from "../../store/actions"

// Layout Related Components
import Header from "./Header"
import Sidebar from "./Sidebar"
import Footer from "./Footer"
import RightSidebar from "../CommonForBoth/RightSidebar"
import CategoryForm from "./CategoryForm"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

//redux
import { useSelector, useDispatch } from "react-redux"
import { getMainData, getUserData, getNotifications, updateNotifications } from "../../store/actions"
import axios from "axios"
import SweetAlert from "react-bootstrap-sweetalert"

const Layout = props => {
  const dispatch = useDispatch()
  const [active, setActive] = useState(false)
  const mainData = useSelector(state => state.MainData)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  const {
    isPreloader,
    leftSideBarThemeImage,
    layoutWidth,
    leftSideBarType,
    topbarTheme,
    showRightSidebar,
    leftSideBarTheme
  } = useSelector(state => ({
    isPreloader: state.Layout.isPreloader,
    leftSideBarThemeImage: state.Layout.leftSideBarThemeImage,
    leftSideBarType: state.Layout.leftSideBarType,
    layoutWidth: state.Layout.layoutWidth,
    topbarTheme: state.Layout.topbarTheme,
    showRightSidebar: state.Layout.showRightSidebar,
    leftSideBarTheme: state.Layout.leftSideBarTheme
  }))
  const notify = useSelector(state => state.Notifications.notifications)

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)


  function clickHandle(type) {
    if (type === "push") {
      setMsg("Send Notification Successfully!")
      setsuccess_msg(true)

    }
    setActive(!active)
  }

  const toggleMenuCallback = () => {
    if (leftSideBarType === "default") {
      dispatch(changeSidebarType("condensed", isMobile))
    } else if (leftSideBarType === "condensed") {
      dispatch(changeSidebarType("default", isMobile))
    }
  }

  //hides right sidebar on body click
  const hideRightbar = (event) => {
    var rightbar = document.getElementById("right-bar")
    //if clicked in inside right bar, then do nothing
    if (rightbar && rightbar.contains(event.target)) {
      return
    } else {
      //if clicked in outside of rightbar then fire action for hide rightbar
      dispatch(showRightSidebarAction(false))
    }
  }

  /*
  layout  settings
  */

  useEffect(() => {
    //init body click event fot toggle rightbar
    document.body.addEventListener("click", hideRightbar, true)

    if (isPreloader === true) {
      document.getElementById("preloader").style.display = "block"
      document.getElementById("status").style.display = "block"

      setTimeout(function() {
        document.getElementById("preloader").style.display = "none"
        document.getElementById("status").style.display = "none"
      }, 2500)
    } else {
      document.getElementById("preloader").style.display = "none"
      document.getElementById("status").style.display = "none"
    }
  }, [isPreloader])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    dispatch(changeLayout("vertical"))
  }, [dispatch])

  useEffect(() => {
    if (leftSideBarTheme) {
      dispatch(changeSidebarTheme(leftSideBarTheme))
    }
  }, [leftSideBarTheme, dispatch])

  useEffect(() => {
    if (leftSideBarThemeImage) {
      dispatch(changeSidebarThemeImage(leftSideBarThemeImage))
    }
  }, [leftSideBarThemeImage, dispatch])

  useEffect(() => {
    if (layoutWidth) {
      dispatch(changeLayoutWidth(layoutWidth))
    }
  }, [layoutWidth, dispatch])

  useEffect(() => {
    if (leftSideBarType) {
      dispatch(changeSidebarType(leftSideBarType))
    }
  }, [leftSideBarType, dispatch])

  useEffect(() => {
    if (topbarTheme) {
      dispatch(changeTopbarTheme(topbarTheme))
    }
  }, [topbarTheme, dispatch])


  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("authUser"))
    dispatch(getDashboardData())
    if (mainData && !mainData.countries) {
      axios.get(`${process.env.REACT_APP_API}/admin/main-data`, {
        headers: {
          "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
        }
      })
        .then(res => {
          dispatch(getMainData(res.data.data))
          if (notify.length === 0) {
            dispatch(getNotifications(res.data.data.notifications.map(n => {
              return { title: "Etoy App", body: n.description, date: n.date, type: n.type }
            })))
            dispatch(updateNotifications(res.data.data.countUnread))
          }
        })
    }
    dispatch(getUserData(userData))
  }, [mainData])

  return (
    <React.Fragment>
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
          </div>
        </div>
      </div>

      <div id="layout-wrapper">
        <Header toggleMenuCallback={toggleMenuCallback} clickHandle={clickHandle} />
        <CategoryForm clickHandle={clickHandle} active={active} />
        <Sidebar
          theme={leftSideBarTheme}
          type={leftSideBarType}
          isMobile={isMobile}
        />
        {success_msg ? (
          <SweetAlert
            title={msg}
            success
            confirmBtnBsStyle="success"
            onConfirm={() => {
              setsuccess_msg(false)
            }}
            onCancel={() => {
              setsuccess_msg(false)
            }}
          >
          </SweetAlert>
        ) : null}
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <div className="main-content">{props.children}</div>
        <Footer />
      </div>
      {showRightSidebar ? <RightSidebar /> : null}
    </React.Fragment>
  )
}

Layout.propTypes = {
  changeLayoutWidth: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarThemeImage: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.object,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarThemeImage: PropTypes.any,
  leftSideBarType: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any
}

export default withRouter(Layout)
