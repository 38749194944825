import {
  GET_MAIN_DATA
} from "./actionTypes"


export const getMainData = data => ({
  type: GET_MAIN_DATA,
  payload: data,
})

