/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Container, UncontrolledTooltip, Tooltip } from "reactstrap"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import "../../../EToyApp/Toys/Components/datatables.scss"
import "../../../EToyApp/Toys/Components/specification.scss"
import axios from "axios"

import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import SweetAlert from "react-bootstrap-sweetalert"
import AddForm from "../../../AdminSection/AdminToys/Components/AddForm"
import UpdateForm from "./EStoreUpdateForm"
// import PdfForm from "../../Toys/Components/PdfForm"
import EToyPaperAddToyForm from "./EStoreAddForm"
import UpdateEToyPaperForm from "./EStoreUpdateForm"
import MetaTags from "react-meta-tags"
import NotificationsPagination from "../../../AdminSection/AdminNotifications/components/AdminNotificationsPagination"
import EStoreToysPagination from "./EStoreToysPagination"

const EStore = (props) => {
  const [active, setActive] = useState(false)
  const [productData, setProductData] = useState([])
  const [editCop, setEditCop] = useState(null)
  const [isOpen, setisOpen] = useState(false)
  const [toy, setToy] = useState(null)
  const [prev, setPrev] = useState("")
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  const [ids, setIds] = useState([])
  const [id, setId] = useState(null)
  // const [images, setImages] = useState(props.images)


  const columns = [{
    dataField: "key",
    text: "Id",
    sort: true
  }, {
    dataField: "images",
    text: "Images",
    sort: true
  }, {
    dataField: "titleEn",
    text: "Toy Name (English)",
    sort: true
  }, {
    dataField: "titleAr",
    text: "Toy Name (Arabic)",
    sort: true
  }, {
    dataField: "catNameEn",
    text: "Categories",
    sort: true
  }, {
    dataField: "descriptionEn",
    text: "Ages",
    sort: true
  }, {
    dataField: "descriptionAr",
    text: "Gender",
    sort: true
  },{
    dataField: "catNameEn",
    text: "Deal",
    sort: true
  },{
    dataField: "catNameAR",
    text: "Status",
    sort: true
  }, {
    dataField: "age",
    text: "Description (English)",
    sort: true
  },{
    dataField: "age",
    text: "Description (Arabic)",
    sort: true
  },{
    dataField: "age",
    text: "Country",
    sort: true
  }, {
    dataField: "actions",
    text: "Actions",
    sort: true
  }, {
    dataField: "downloadLink",
    text: "Created On",
    sort: true
  }]

  const pagination = paginationFactory({
    page: 2,
    sizePerPageList: [{
      text: "10", value: 10
    }, {
      text: "25", value: 25
    }, {
      text: "50", value: 50
    }, {
      text: "75", value: 75
    }, {
      text: "100", value: 100
    }, {
      text: "All", value: productData.length
    }],
    showTotal: true
  })

  function setOpen(img) {
    setisOpen(true)
    setPrev(img)
  }


  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect, rowIndex, e) => {
      if (e.target.localName === "input") {
        if (isSelect) {
          setIds(ids => ids.concat(row.id))
        } else {
          setIds(ids => ids.filter(id => id !== row.id))
        }
      }
    }
  }

  const { SearchBar } = Search


  function clickHandle() {
    setActive(!active)
  }


  function editCoupon(data) {
    setEditCop(!edit)
  }


  function deleteHandler(id) {
    let ids = []
    ids.push(id)
    const data = {
      ids: ids
    }
    axios.post(`${process.env.REACT_APP_API}/etoypaper-dashboard/toyspaper/deleteToyPaper`, data, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
      }
    })
      .then(res => {
      })
    setProductData(products => products.filter(prod => prod.key !== id))
    // props.userChanges(paper, "delactive")
    setMsg("Deleted!")
    setsuccess_msg(true)
  }

  function deleteMultiHandler() {
    axios.post(`${process.env.REACT_APP_API}/etoypaper-dashboard/toyspaper/deleteToyPaper`, { ids }, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
      }
    })
      .then(res => {
      })
    //setProductData(products => products.filter(prod => prod.id != id))
    //props.userChanges(user, "delactive")
    setMsg("Deleted!")
    setsuccess_msg(true)
  }


  function edit(toy) {
    setEditCop(true)
    setToy(toy)
  }

  function mssg() {
    setMsg("Toy Added!")
    setsuccess_msg(true)
  }

  // useEffect(() => {
  //   setProductData(props.active.map(paper => {
  //     return {
  //       ...paper,
  //       // key: paper.key ? paper.key : <span className="fs-4">-</span>,
  //       titleEn: paper.titleEn ? paper.titleEn : <span className="fs-4">-</span>,
  //       titleAr: paper.titleAr ? paper.titleAr : <span className="fs-4">-</span>,
  //       descriptionEn: paper.descriptionEn ? paper.descriptionEn : <span className="fs-4">-</span>,
  //       descriptionAr: paper.descriptionAr ? paper.descriptionAr : <span className="fs-4">-</span>,
  //       catNameEn: paper.catNameEn ? paper.catNameEn : <span className="fs-4">-</span>,
  //       catNameAR: paper.catNameAR ? paper.catNameAR : <span className="fs-4">-</span>,
  //       age: paper.age ? paper.age : <span className="fs-4">-</span>,
  //       downloadLink: paper.downloadLink ? <a href={paper.downloadLink} style={editBtn} rel="noreferrer" target="_blank"><i className="bx bx-link-external" /> Link</a> : <span className="fs-4">-</span>,
  //       toypapercatId: paper.toypapercatId ? paper.toypapercatId : <span className="fs-4">-</span>,
  //       images: paper.images ? paper.images.map((image) => <img src={image.image} key={image.key}
  //                                                               onClick={(e) => setOpen(e.target.src)}
  //                                                               className="usernametabl" alt="" />) : <span className="fs-4">-</span>,
  //       actions: <div className="d-flex gap-2">
  //         <button style={editBtn} onClick={(e) => edit(paper)}><i className="bx bx-edit fs-4" /> Edit</button>
  //         <button style={deleteBtn} onClick={(e) => deleteHandler(paper.key)}><i
  //           className="bx bx-trash fs-4" /> Delete
  //         </button>
  //       </div>
  //     }
  //   }))
  //   // setImages(props.images.map(images => images.image))
  //   // console.log("images", images)
  // }, [props.active, props.userChanges])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>eToy Paper</title>
        </MetaTags>
        <Container fluid>
          {success_msg ? (
            <SweetAlert
              title={msg}
              success
              confirmBtnBsStyle="success"
              onConfirm={() => {
                setsuccess_msg(false)
              }}
              onCancel={() => {
                setsuccess_msg(false)
              }}
            >
            </SweetAlert>
          ) : null}
          {isOpen ? (
            <Lightbox
              mainSrc={prev}
              enableZoom={false}
              onCloseRequest={() => {
                setisOpen(!isOpen)
              }}
            />
          ) : null}
          {/*<EToyPaperAddToyForm active={active} clickHandle={clickHandle} addPaper={props.paperChange} mssg={mssg} paperCategories={props.paperCategories}*/}
          {/*  admin={true}*/}
          {/*/>*/}
          {/*<UpdateEToyPaperForm active={editCop} editCoupon={editCoupon} addPaper={props.paperChange} toy={toy} mssg={mssg} update={props.update} paperCategories={props.paperCategories}/>*/}

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody className="pb-0">
                <Row className="mb-2">
                  <Col>
                    <div className="save__btn top">
                      {ids.length > 1 ?
                        <div className="d-flex delet justify-content-end align-items-center fs-5">
                          <button style={{ ...rej, marginRight: "10px" }}
                                  onClick={(e) => deleteMultiHandler()}><i
                            className="bx bx-trash fs-4" /> Delete
                          </button>
                        </div> : null}
                      <button onClick={clickHandle}><i className="bx bx-plus-circle fs-4" /> Add Toy
                      </button>
                    </div>
                  </Col>
                </Row>

                <EStoreToysPagination data={productData} page={props.pageNo}
                                      sizePerPage={props.sizePerPage} totalSize={props.totalSize}
                                      onTableChange={props.onTableChange} columns={columns} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        </Container>
      </div>
      {/*<div className="page-content">*/}
      {/*  <MetaTags>*/}
      {/*    <title>eToy Paper</title>*/}
      {/*  </MetaTags>*/}
      {/*  <Container fluid>*/}
      {/*  {success_msg ? (*/}
      {/*    <SweetAlert*/}
      {/*      title={msg}*/}
      {/*      success*/}
      {/*      confirmBtnBsStyle="success"*/}
      {/*      onConfirm={() => {*/}
      {/*        setsuccess_msg(false)*/}
      {/*      }}*/}
      {/*      onCancel={() => {*/}
      {/*        setsuccess_msg(false)*/}
      {/*      }}*/}
      {/*    >*/}
      {/*    </SweetAlert>*/}
      {/*  ) : null}*/}
      {/*  {isOpen ? (*/}
      {/*    <Lightbox*/}
      {/*      mainSrc={prev}*/}
      {/*      enableZoom={false}*/}
      {/*      onCloseRequest={() => {*/}
      {/*        setisOpen(!isOpen)*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  ) : null}*/}
      {/*  <EToyPaperAddToyForm active={active} clickHandle={clickHandle} addPaper={props.paperChange} mssg={mssg}*/}
      {/*                       // admin={true}*/}
      {/*  />*/}
      {/*  <UpdateEToyPaperForm active={editCop} editCoupon={editCoupon} addPaper={props.paperChange} toy={toy} mssg={mssg} update={props.update} />*/}
      {/*  <Row>*/}
      {/*    <Col className="col-12">*/}
      {/*      <Card>*/}
      {/*        <CardBody className="pb-0">*/}
      {/*          <ToolkitProvider*/}
      {/*            keyField="key"*/}
      {/*            columns={columns}*/}
      {/*            data={productData}*/}
      {/*            search*/}
      {/*          >*/}
      {/*            {props => (*/}
      {/*              <React.Fragment>*/}
      {/*                <Row className="mb-2">*/}
      {/*                  <Col>*/}
      {/*                    <div className="search-box me-2 mb-2 d-inline-block">*/}
      {/*                      <div className="position-relative ">*/}
      {/*                        <SearchBar*/}
      {/*                          {...props.searchProps}*/}
      {/*                          className="input_cus"*/}
      {/*                        />*/}
      {/*                        <i className="bx bx-search-alt search-icon fs-3 pt-1" />*/}
      {/*                      </div>*/}
      {/*                    </div>*/}
      {/*                  </Col>*/}
      {/*                  <Col>*/}
      {/*                    <div className="save__btn top">*/}
      {/*                      {ids.length > 1 ?*/}
      {/*                        <div className="d-flex delet justify-content-end align-items-center fs-5">*/}
      {/*                          <button style={{ ...rej, marginRight: "10px" }}*/}
      {/*                                  onClick={(e) => deleteMultiHandler()}><i*/}
      {/*                            className="bx bx-trash fs-4" /> Delete*/}
      {/*                          </button>*/}
      {/*                        </div> : null}*/}
      {/*                      <button onClick={clickHandle}><i className="bx bx-plus-circle fs-4" /> Add Toy*/}
      {/*                      </button>*/}
      {/*                    </div>*/}
      {/*                  </Col>*/}
      {/*                </Row>*/}

      {/*                <Row>*/}
      {/*                  <Col xl="12">*/}
      {/*                    <div className="table-responsive">*/}
      {/*                      <BootstrapTable*/}
      {/*                        keyField={"id"}*/}
      {/*                        responsive*/}
      {/*                        bordered={false}*/}
      {/*                        striped={false}*/}
      {/*                        pagination={pagination}*/}
      {/*                        selectRow={selectRow}*/}
      {/*                        classes={*/}
      {/*                          "table align-middle table-nowrap"*/}
      {/*                        }*/}
      {/*                        headerWrapperClasses={"thead-light"}*/}
      {/*                        {...props.baseProps}*/}
      {/*                      />*/}
      {/*                      {productData.length === 0 ? <div className="emptyStateTable" style={{*/}
      {/*                        width: "100%",*/}
      {/*                        textAlign: "center",*/}
      {/*                        fontSize: "16px",*/}
      {/*                        padding: "10px",*/}
      {/*                        paddingTop: "0", paddingBottom: "20px"*/}
      {/*                      }}>no data available in table</div> : null}*/}
      {/*                    </div>*/}
      {/*                  </Col>*/}
      {/*                </Row>*/}
      {/*              </React.Fragment>*/}
      {/*            )*/}
      {/*            }*/}
      {/*          </ToolkitProvider>*/}
      {/*        </CardBody>*/}
      {/*      </Card>*/}
      {/*    </Col>*/}
      {/*  </Row>*/}
      {/*  </Container>*/}
      {/*</div>*/}
    </React.Fragment>
  )
}

export default EStore

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px"
}

const editBtn = {
  background: "transparent",
  border: "none",
  color: "#000",
  display: "flex",
  alignItems: "center",
  gap: "5px"

}

const rej = {
  background: "transparent",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px",
  width: "150px",
  height: "48px",
  border: "1px solid #D91414",
  textAlign: "center",
  justifyContent: "center"
}