/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
// import MetaTags from "react-meta-tags"
// datatable related plugins
// import BootstrapTable from "react-bootstrap-table-next"
// import paginationFactory from "react-bootstrap-table2-paginator"
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import AddUserForm from "./AddUserForm"
import "./datatables.scss"
import "./specification.scss"
import axios from "axios"
// import { toast } from "react-toastify"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import SweetAlert from "react-bootstrap-sweetalert"
import AdminPagination from "pages/AdminSection/AdminNotifications/components/AdminPagination"
import { useSelector } from "react-redux";


const UnActiveUsers = (props) => {

  const notifications = useSelector(state => state.Notifications.notifyCount)
  const [active, setActive] = useState(false)
  const [productData, setProductData] = useState([])
  const [editCop, setEditCop] = useState(null)
  const [isOpen, setisOpen] = useState(false)
  const [prev, setPrev] = useState("")
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  const [filter, setFilter] = useState('')
  const columns = [{
    dataField: "key",
    text: "Id",
    sort: true
  }, {
    dataField: "image",
    text: "Image",
    sort: true
  }, {
    dataField: "nameEn",
    text: "Name",
    sort: true
  }, {
    dataField: "toys",
    text: "# of toys",
    sort: true
  }, {
    dataField: "email",
    text: "Email",
    sort: true
  }, {
    dataField: "gender",
    text: "Gender",
    sort: true
  }, {
    dataField: "source",
    text: "Device",
    sort: true
  },  {
    dataField: "appVersion",
    text: "App Version",
    sort: true
  }, {
    dataField: "deviceVersion",
    text: "Device Version",
    sort: true
  },{
    dataField: "country",
    text: "Country",
    sort: true
  },{
    dataField: "city",
    text: "City",
    sort: true
  }, {
    dataField: "mobile",
    text: "Mobile",
    sort: true
  }, {
    dataField: "longitude",
    text: "Longitude",
    sort: true
  }, {
    dataField: "latitude",
    text: "Latitude",
    sort: true
  }, {
    dataField: "actions",
    text: "Actions",
    sort: true
  }, {
    dataField: "points",
    text: "Points",
    sort: true
  }, {
    dataField: "created_at",
    text: "Created On",
    sort: true
  }]


  // const defaultSorted = [{
  //   dataField: "key",
  //   order: "asc"
  // }]

  

  function setOpen(img) {
    setisOpen(true)
    setPrev(img)
  }

// Custom Pagination Toggle
  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
    { text: "All", value: (productData).length }]


  // Select All Button operation
  const selectRow = {
    mode: "checkbox"
  }

  // const { SearchBar } = Search

  function addProd(data, type) {
    setEditCop(null)
    if (type === "add") {
      setProductData(products => products.concat({
        ...data,
        actions: <div>
          <button style={editBtn} onClick={() => editCoupon(data)}><i className="bx bx-edit fs-4" /></button>
          <button style={deleteBtn} id={data.id} onClick={(e) => deleteHandler(e.currentTarget.id)}><i
            className="bx bx-trash fs-4" /></button>
        </div>
      }))
    } else {
      setProductData(products => products.map(prod => prod.id === data.id ? { ...prod, ...data } : prod))
    }
  }


  function clickHandle() {
    setActive(!active)
    setEditCop(null)
  }

  function editCoupon(data) {
    setEditCop(data)
    setActive(true)
  }

  function ActiveHandler(id, user) {
    axios.post(`${process.env.REACT_APP_API}/admin/users/active`, { id }, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
      }
    })
      .then(res => {
        setMsg(`active ${user.nameEn}`)
        setsuccess_msg(true)
        bindData()   
        props.updateState(id+"ac")
      })
    // setProductData(products => products.filter(prod => prod.id !== id))
    // props.userChanges(user, "active")
    // setMsg(`active ${user.nameEn}`)
    // setsuccess_msg(true)
  }

  function deleteHandler(id) {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API}/admin/coupons/delete/${id}`,
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
      }
    }

    axios(config)
      .then(function(response) {
      })
      .catch(function(error) {
      })
    setProductData(products => products.filter(prod => prod.id !== id))
  }

  const [pageNo, setPageNo] = useState(1)
  const sizePerPage = 10
  const [totalCount, setTotalCount] = useState(0)

  useEffect(() => {
    bindData()
  }, [pageNo,filter,notifications, props.activeState])


  useEffect(() => {
      setFilter(props.country)
  }, [props.country])
 

  const bindData = async() => {
    await axios.get(`${process.env.REACT_APP_API}/admin/unactiveusers?page=${pageNo}&search=${filter}`, {
      headers: {
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`, 
      }
    })
    .then(res => {
    const resData = res.data.data.unactive.data
    setProductData(resData.map(user => {
      return {
        ...user,
        image: <img src={user.image} onClick={(e) => setOpen(e.target.src)} className="usernametabl" alt="" />,
        city: user.city ? user.city : <span className="fs-4">-</span>,
        nameEn: user.nameEn ? user.nameEn : <span className="fs-4">-</span>,
        appVersion: user.appVersion ? user.appVersion : <span className="fs-4">-</span>,
        deviceVersion: user.deviceVersion ? user.deviceVersion : <span className="fs-4">-</span>,
        email: user.email ? user.email : <span className="fs-4">-</span>,
        gender: user.gender ? user.gender : <span className="fs-4">-</span>,
        country: user.country ? user.country : <span className="fs-4">-</span>,
        longitude: user.longitude ? user.longitude : <span className="fs-4">-</span>,
        latitude: user.latitude ? user.latitude : <span className="fs-4">-</span>,
        points: user.points ? user.points : <span className="fs-4">-</span>,
        toys: user.toysCount ? user.toysCount : <span className="fs-4">-</span>,
        actions: <div className="d-flex">
          <button style={editBtn} onClick={() => ActiveHandler(user.key, user)}><i className="fas fa-check" /> Active
          </button>
        </div>
    }
  }))
  setTotalCount(res.data.data.unactive.total)
  props.setUnActiveCount(res.data.data.counter)
})}

const onPageHandler = async (type, { page }) => {
  await setPageNo(page)
}
  return (
    <React.Fragment>
      <div className="container-fluid p-0">
        {success_msg ? (
          <SweetAlert
            title={msg}
            success
            confirmBtnBsStyle="success"
            onConfirm={() => {
              setsuccess_msg(false)
            }}
            onCancel={() => {
              setsuccess_msg(false)
            }}
          >
          </SweetAlert>
        ) : null}
        {isOpen ? (
          <Lightbox
            mainSrc={prev}
            enableZoom={false}
            onCloseRequest={() => {
              setisOpen(!isOpen)
            }}
          />
        ) : null}
        <AddUserForm active={active} clickHandle={clickHandle} addProd={addProd} editCop={editCop} />
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody className="pb-0">
              <AdminPagination
                data={productData} 
                page={pageNo}
                setFilter={setFilter}
                keyFiled='key'
                sizePerPage={sizePerPage} 
                totalSize={totalCount}
                onTableChange={onPageHandler} 
                columns={columns} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default UnActiveUsers

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414"
}

const editBtn = {
  background: "transparent",
  border: "none",
  color: "green"
}