import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setTitle } from "../../../store/title/actions"
import { useHistory } from "react-router-dom"
import axios from "axios"
import EToyApp from "./components/index"


function AdminSettings(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector(state => state.UserData.user)
  const [admin, setAdmin] = useState([])


  useEffect(() => {
    if(user && user.role === 2) {
      history.push('/')
    }
  }, [user])

  useEffect(() => {

    dispatch(setTitle("Admin Settings"))
    axios.get(`${process.env.REACT_APP_API}/admin/get_admins`, {
      headers: {
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`,
      }
    })
      .then(res => {
        setAdmin(res.data.data)
      })
  }, [])

  return (
        <EToyApp admin={admin}/>
  )
}


export default AdminSettings