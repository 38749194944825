import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "reactstrap"
// import EToyApp from "../../AdminToys/Components/EToyApp"
import EToyPaper from "./components/EToyPaper"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { setTitle } from "../../../store/title/actions"

function EToyPaperToys(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector(state => state.UserData.user)
  const [paperCategories, setPaperCategories] = useState([])
  // const notifications = useSelector(state => state.Notifications.toyCount)

  useEffect(() => {
    dispatch(setTitle("eToy App Paper"))
    if (user && user.role === 2) {
      history.push("/")
      return
    }
    axios.get(`${process.env.REACT_APP_API}/etoypaper-dashboard/toyspapercat`, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
      }
    }).then(res => {
      setPaperCategories(res.data.data)
    })

  }, [])

  
  
  // useEffect(() => {
  //   dispatch(setTitle("eToy App Paper"))
  //   // if (notifications) {

  //   axios.get(`${process.env.REACT_APP_API}/etoypaper-dashboard/toyspaper`, {
  //     headers: {
  //       "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
  //     }
  //   })
  //     .then(res => {
  //       console.log(res.data.data)
  //       setPaperToys(res.data.data)
  //       // setImages(res.data.data.map(imgs => imgs.images))
  //     }
  //     )
  // }, [user])

  
     

  // useEffect(() => {
  //   // if (notifications) {

  //   axios.get(`${process.env.REACT_APP_API}/etoypaper-dashboard/toyspaper`, {
  //     headers: {
  //       "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
  //     }
  //   })
  //     .then(res => {
  //       console.log(res.data.data)
  //       setPaperToys(res.data.data)
  //       // setImages(res.data.data.map(imgs => imgs.images))

  //       // setToys(res.data.data)
  //     })
  //   // axios.post(`${process.env.REACT_APP_API}/admin/setUnread`, { key: "2" }, {
  //   //   headers: {
  //   //     "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
  //   //   }
  //   // }).then(res => {
  //   //   dispatch(updateNotifications({ unreadToyCount: 0 }))
  //   // })
  //   // }
  //   setImages(paperToys.map(data => data.images))
  //   console.log(images)
  //   console.log('first page', paperToys)
  // }, [])

  return (
    <>
      {/* <EToyPaper paperChange={addPaper} paperToys={paperToys} paperCategories={paperCategories}
      //  images={images} 
      update={update} setPageNo={setPageNo} columns={columns} toy={toy} 
                 sizePerPage={sizePerPage} totalSize={totalCount} onTableChange={onPageHandler} /> */}
        <EToyPaper paperCategories={paperCategories}/>
    </>
  )
}

export default EToyPaperToys


