/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
// import MetaTags from "react-meta-tags"

// datatable related plugins
// import BootstrapTable from "react-bootstrap-table-next"
// import paginationFactory from "react-bootstrap-table2-paginator"

// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import UpdateForm from "./UpdateForm"
import "./datatables.scss"
import "./specification.scss"
import axios from "axios"
// import { toast } from "react-toastify"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import SweetAlert from "react-bootstrap-sweetalert"
import RejectReasonsForm from "./RejectReasonsForm"
import { useSelector } from "react-redux";
import AdminPagination from "pages/AdminSection/AdminNotifications/components/AdminPagination"
// import { users } from "../../../../common/data"

  const PendingToys = (props) => {

  const notifications = useSelector(state => state.Notifications.notifyCount)
  const [active, setActive] = useState(false)
  const [rejectActive, setRejectActive] = useState(false)
  const [productData, setProductData] = useState([])
  const [pendingUsers, setPendingUsers] = useState([])
  const [editCop, setEditCop] = useState(null)
  const [isOpen, setisOpen] = useState(false)
  const [prev, setPrev] = useState("")
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  const [ids, setIds] = useState([])
  const [rejectIds, setRejectIds] = useState([])
  const [selectdata, setData] = useState([])
  const [pageNo, setPageNo] = useState(1)
  const sizePerPage = 10
  const [totalCount, setTotalCount] = useState(0)
  const [filter, setFilter] = useState('')

  const columns = [{
    dataField: "id",
    text: "Id",
    sort: true
  }, {
    dataField: "ownerName",
    text: "User Name",
    sort: true
  }, {
    dataField: "deal",
    text: "Deal",
    sort: true
  }, {
    dataField: "image",
    text: "Image",
    sort: true
  }, {
    dataField: "nameEn",
    text: "Toy Title(English)",
    sort: true
  }, {
    dataField: "nameAr",
    text: "Toy Title(Arabic)",
    sort: true
  }, {
    dataField: "category",
    text: "Category",
    sort: true
  },{
    dataField: "ages",
    text: "Age",
    sort: true
  }, 
  {
    dataField: "descriptionEn",
    text: "Description(English)",
    sort: true
  }, {
    dataField: "descriptionAr",
    text: "Description(Arabic)",
    sort: true
  }, {
    dataField: "pickup_address_en",
    text: "Pickup Address(English)",
    sort: true
  }, {
    dataField: "pickup_address_ar",
    text: "Pickup Address(Arabic)",
    sort: true
  }, {
    dataField: "country",
    text: "Country",
    sort: true
  },{
    dataField: "city",
    text: "City",
    sort: true
  }, {
    dataField: "actions",
    text: "Actions",
    sort: true
  }, {
    dataField: "status",
    text: "Status",
    sort: true
  }, {
    dataField: "gender",
    text: "Gender",
    sort: true
  }, {
    dataField: "created_at",
    text: "Created On",
    sort: true
  }]


  useEffect(() => {
    bindData()
  }, [pageNo, filter, notifications, props.activeState])



 
  useEffect(() => {
    setFilter(props.country)
  }, [props.country])

  
  const bindData = async() => {
    await axios.get(`${process.env.REACT_APP_API}/admin/pendingtoys?page=${pageNo}&search=${filter}`, {
          headers: {
              'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`, 
          }
        })
    .then(res => {
        const resData = res.data.data.pending_toys.data
        setProductData(resData.map(user => {
          return {
                  ...user,
                  image: <img src={user.image} onClick={(e) => setOpen(e.target.src)} className="usernametabl" alt="" />,
                  nameEn: user.nameEn ? user.nameEn : <span className="fs-4">-</span>,
                  nameAr: user.nameAr? user.nameAr:<span className="fs-4">-</span>,
                  category: user?.categories.length !== 0? user?.categories.map((c,i)=> <span key={i} className={`${c.nameEn===1?'':'image-opacity'}`}  style={{backgroundColor: 'rgb(216 216 216)',
                  color: 'rgb(0, 0, 0)',
                  borderRadius: '45px',
                  padding: '5px 20px',
                  margin: '0px 2px',
                  display: 'inline-block'}}>{c.nameEn}</span>
                  ):<span className="fs-4">-</span>,
                  deal: user.deal === "sale" ? user.price + " " + user.currency : user.deal,
                  price: user.price ? user.price : <span className="fs-4">-</span>,
                  country: user.country ? user.country : <span className="fs-4">-</span>,
                  ages: user.maxAge ?  user.minAge ?  <span className="fs-4" style={{backgroundColor: 'rgb(216 216 216)',
                  color: 'rgb(0, 0, 0)',
                  borderRadius: '45px',
                  padding: '5px 20px',
                  margin: '0px 2px',
                  display: 'inline-block'}}>{ user.minAge } To  { user.maxAge }</span>:<span className="fs-4" style={{backgroundColor: 'rgb(216 216 216)',
                  color: 'rgb(0, 0, 0)',
                  borderRadius: '45px',
                  padding: '5px 20px',
                  margin: '0px 2px',
                  display: 'inline-block'}}>{ user.maxAge }</span> :<span className="fs-4">-</span>,
                  
                  status: user.status ? user.status : <span className="fs-4">-</span>,
                  gender: user.gender ? user.gender : <span className="fs-4">-</span>,
                  city: user.city ? user.city : <span className="fs-4">-</span>,
                  descriptionEn: user.descriptionEn ? user.descriptionEn : <span className="fs-4">-</span>,
                  descriptionAr: user.descriptionAr ? user.descriptionAr : <span className="fs-4">-</span>,
                  pickup_address_en: user.pickup_address_en ? user.pickup_address_en : <span className="fs-4">-</span>,
                  pickup_address_ar: user.pickup_address_ar ? user.pickup_address_ar : <span className="fs-4">-</span>,
                  actions: <div className="d-flex">
                    <button style={editBtn} onClick={() => acceptHandler(user.id, user)}><i className="fas fa-check" /> Accept
                    </button>
                    <button style={{ ...deleteBtn, gap: "5px" }}
                            onClick={(e) => rejectModal(user.id, user)}
                    ><i
                      className="fas fa-times fs-5" /> Reject
                    </button>
                  </div>
                }
        }))
        setTotalCount(res.data.data.pending_toys.total)
        props.setPendingCount(res.data.data.counter)
    })
  }

  const onPageHandler =  (type, { page }) => {
    setPageNo(page)
  }


  function setOpen(img) {
    setisOpen(true)
    setPrev(img)
  }


  function addProd(data, type) {
    setEditCop(null)
    if (type === "add") {
      setProductData(products => products.concat({
        ...data,
        image: <img src={`${data.image}`} alt="" style={{ width: "50px", height: "50px", objectFit: "cover" }} />,
        actions: <div style={{ display: "flex", gap: "10px" }}>
          <button style={editBtn} onClick={() => editCoupon(data)}>
            <i className="bx bx-edit fs-4"></i>
            Edit
          </button>
          <button style={deleteBtn} onClick={(e) => rejectModal()}>
            <i className="bx bx-trash fs-4"></i>
            Delete
          </button>
        </div>
      }))
    }
    else {
      setProductData(products => products.map(prod => prod.id === data.id ? { ...prod, ...data } : prod))
    }
  }


  function clickHandle() {
    setActive(!active)
    setEditCop(null)
  }

  function rejectClickHandle() {
    setRejectActive(!rejectActive)
  }


  function editCoupon(data) {
    setEditCop(data)
    setActive(true)
  }

  function rejectModal(id, user) {
    setRejectActive(true)
    setRejectIds(prevState => [id].concat(prevState))
    setPendingUsers(user)
  }


  function acceptHandler(id, data) {

    let ids = []
    ids.push(id)
    axios.post(`${process.env.REACT_APP_API}/admin/toys/accept`, { ids }, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
      }
    })
      .then(res => {
        bindData()
        setMsg("Accept!")
        setsuccess_msg(true)
        props.updateState(id)
      })
  }



  return (
    <React.Fragment>
      <div className="container-fluid p-0">
        {success_msg ? (
          <SweetAlert
            title={msg}
            success
            confirmBtnBsStyle="success"
            onConfirm={() => {
              setsuccess_msg(false)
            }}
            onCancel={() => {
              setsuccess_msg(false)
            }}
          >
          </SweetAlert>
        ) : null}
        {isOpen ? (
          <Lightbox
            mainSrc={prev}
            enableZoom={false}
            onCloseRequest={() => {
              setisOpen(!isOpen)
            }}
          />
        ) : null}
        <UpdateForm active={active} clickHandle={clickHandle} addProd={addProd} editCop={editCop} />
        <RejectReasonsForm pendingUsers={pendingUsers} active={rejectActive} clickHandle={rejectClickHandle} rejectId={rejectIds} bindData={bindData}/>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody className="pb-0">
              <AdminPagination
                data={productData} page={pageNo}
                keyFiled='id'
                setFilter={setFilter}
                sizePerPage={sizePerPage} totalSize={totalCount}
                onTableChange={onPageHandler} columns={columns} />

              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default PendingToys

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px"

}

const editBtn = {
  background: "transparent",
  border: "none",
  color: "green",
  display: "flex",
  alignItems: "center",
  gap: "5px"
}


const acc = {
  background: "transparent",
  color: "green",
  display: "flex",
  alignItems: "center",
  gap: "5px",
  width: "150px",
  height: "48px",
  border: "1px solid green",
  textAlign: "center",
  justifyContent: "center"
}
const rej = {
  background: "transparent",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px",
  width: "150px",
  height: "48px",
  border: "1px solid #D91414",
  textAlign: "center",
  justifyContent: "center"
}