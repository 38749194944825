import {
  GET_MAIN_DATA
} from "./actionTypes"

const INIT_STATE = {
  categories: null,
  countries: null,
  users: null,
}

const MainData = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MAIN_DATA:
      return {
        ...state,
        categories: action.payload.categories,
        countries: action.payload.countries,
        // users: action.payload.users.map(user => {return {name: user.nameEn, id: user.key}}),
      }
    default:
      return state
  }
}

export default MainData