/* eslint-disable react/prop-types */
import React, {useEffect, useState} from "react"
import MetaTags from 'react-meta-tags';
import { Container, Row, Col } from "reactstrap"
import './emailshot.scss'
import EmailImg from '../../../Images/email.png'
import EmailForm from './EmailForm'
import axios from "axios";

const CharacterPdf = (props) => {
  const [active, setActive] = useState(false)
  const [data, setData] = useState(null)
  function clickHandle () {
    setActive(!active)
  }

  useEffect(() => {
    setData(props.email);
  }, [props.email])


  return (
        <React.Fragment>
        <div className="page-content p-0">
            <MetaTags>
            <title>Starter Page | Skote - React Admin & Dashboard Template</title>
            </MetaTags>
            <Container fluid>
            <EmailForm clickHandle={clickHandle} active={active} data={data}/> 
            <Row className="email_shot_container m-0 ">
                <Col lg={8} xs={9} className="p-0 m-0 mr-1 " style={{background: "#fff"}}>
                <Row className="email_head m-0">
                  <svg width="182" height="67" viewBox="0 0 182 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M120.143 40.7829C118.684 39.3436 117.978 37.5723 117.978 35.3689V20.3038H125.827V17.7839H117.978V9.76514H115.23V35.3689C115.23 38.2939 116.238 40.7328 118.217 42.6159C120.193 44.4952 122.616 45.4445 125.418 45.4445H125.881V42.9207H125.418C123.326 42.9246 121.602 42.2223 120.143 40.7829Z" fill="black"/>
                      <path d="M178.582 17.7839V33.6517C178.582 36.1291 177.66 38.3132 175.846 40.1501C174.029 41.9831 171.856 42.913 169.379 42.913C166.901 42.913 164.729 41.9831 162.911 40.1501C161.094 38.3171 160.175 36.1291 160.175 33.6517V17.7839H157.428V33.6517C157.428 36.8739 158.605 39.6754 160.92 41.9715C163.235 44.2675 166.064 45.4329 169.325 45.4329H169.383C173.488 45.4175 176.576 43.9588 178.586 41.0878V43.7157C178.586 46.4169 177.598 48.7631 175.653 50.6926C173.874 52.4561 171.756 53.4247 169.383 53.5674H167.31V56.1027H167.773H169.402C172.62 55.9484 175.402 54.7251 177.671 52.4599C180.103 50.0288 181.334 47.0305 181.334 43.5459V17.7839H178.582Z" fill="black"/>
                      <path d="M109.135 21.8551C106.453 19.1539 103.234 17.7878 99.5646 17.7878L99.4488 17.7917C95.8292 17.8187 92.6147 19.1655 89.8904 21.7934C87.1312 24.456 85.7305 27.6859 85.7305 31.3943V31.8998C85.7305 32.359 85.7536 32.8182 85.7922 33.2697C86.0893 36.434 87.4593 39.2047 89.8595 41.5046C92.5723 44.1016 95.7983 45.4291 99.445 45.4522L99.9119 45.4561V43.3839L99.8926 42.9401L99.4488 42.9208H99.4064C99.0166 42.9169 98.623 42.8938 98.2333 42.8552C96.9907 42.7202 95.7906 42.3806 94.6638 41.8442C94.5326 41.7824 94.4014 41.713 94.2895 41.6551L94.247 41.6319C94.1081 41.5548 93.9692 41.4776 93.8303 41.3966L93.7531 41.3502C93.6258 41.2731 93.4984 41.192 93.3672 41.1071L93.0894 40.9103C93.0315 40.8717 92.9736 40.8332 92.9196 40.7907L92.7421 40.6556C92.6533 40.5862 92.5607 40.5206 92.472 40.4473C92.4411 40.4202 92.4102 40.3971 92.3793 40.3701L92.2327 40.2466C92.1671 40.1926 92.1015 40.1347 92.0359 40.0807C91.978 40.0266 91.9163 39.9726 91.8584 39.9186L91.7156 39.7874C91.677 39.7527 91.6384 39.7179 91.596 39.6755C91.4648 39.552 91.3413 39.4247 91.2178 39.2973L90.994 39.0542C89.6665 37.5222 88.7867 35.6815 88.4356 33.6981H113.021L113.067 33.2813C113.125 32.7295 113.156 32.1815 113.156 31.6529V31.622C113.163 27.8287 111.805 24.5448 109.135 21.8551ZM110.647 31.1512H88.2966C88.5938 25.2509 93.5139 20.5238 99.472 20.5238C105.43 20.5238 110.35 25.2509 110.647 31.1512Z" fill="black"/>
                      <path d="M141.334 17.9729C133.77 17.9729 127.615 24.1278 127.615 31.6913C127.615 39.2547 133.77 45.4097 141.334 45.4097C148.897 45.4097 155.052 39.2547 155.052 31.6913C155.052 24.1278 148.897 17.9729 141.334 17.9729ZM152.551 31.6913C152.551 37.8771 147.519 42.9091 141.334 42.9091C135.148 42.9091 130.116 37.8771 130.116 31.6913C130.116 25.5055 135.148 20.4735 141.334 20.4735C147.519 20.4735 152.551 25.5055 152.551 31.6913Z" fill="black"/>
                      <path d="M59.9532 15.3294L67.2459 8.03676C67.4759 7.80675 67.4759 7.43333 67.2459 7.20332L60.2184 0.173183C60.1021 0.0568256 59.9532 0 59.8017 0C59.6502 0 59.4986 0.0568256 59.385 0.173183L52.0924 7.4658L44.7998 0.173183C44.5697 -0.0568256 44.1963 -0.0568256 43.9663 0.173183L36.9362 7.20061C36.7062 7.43062 36.7062 7.80405 36.9362 8.03405L44.2288 15.3267L36.9362 22.622C36.7062 22.852 36.7062 23.2254 36.9362 23.4554L43.9663 30.4856C44.1963 30.7156 44.5697 30.7156 44.7998 30.4856L52.0924 23.193L59.385 30.4856C59.5014 30.6019 59.6502 30.6588 59.8017 30.6588C59.9532 30.6588 60.1048 30.6019 60.2184 30.4856L67.2486 23.4554C67.4786 23.2254 67.4786 22.852 67.2486 22.622L59.9532 15.3294ZM59.8017 28.1232L54.0569 22.3785V8.2803L59.8017 2.5328L64.8862 7.61733L58.5651 13.9385L57.1769 15.3267L58.5651 16.7148L64.8862 23.036L59.8017 28.1232Z" fill="#F6BCB1"/>
                      <path d="M0.172506 58.3491C-0.0575021 58.5791 -0.0575021 58.9526 0.172506 59.1826L7.20264 66.2127C7.319 66.3291 7.46783 66.3859 7.61936 66.3859C7.7709 66.3859 7.92243 66.3291 8.03609 66.2127L15.3287 58.9201L22.6213 66.2127C22.8513 66.4427 23.2248 66.4427 23.4548 66.2127L30.4849 59.1826C30.7149 58.9526 30.7149 58.5791 30.4849 58.3491L23.1896 51.0538L30.4822 43.7612C30.7122 43.5312 30.7122 43.1578 30.4822 42.9277L23.4521 35.8976C23.222 35.6676 22.8486 35.6676 22.6186 35.8976L15.326 43.1902L8.03609 35.9003C7.91973 35.784 7.7709 35.7271 7.61936 35.7271C7.46783 35.7271 7.3163 35.784 7.20264 35.9003L0.172506 42.9277C-0.0575021 43.1578 -0.0575021 43.5312 0.172506 43.7612L7.46512 51.0538L0.172506 58.3491ZM2.53212 58.7659L8.85329 52.4447L10.2415 51.0565L8.85329 49.6683L2.53212 43.3445L7.61666 38.2599L13.3615 44.0047V58.1029L7.61666 63.8477L2.53212 58.7659Z" fill="#A5C3B2"/>
                      <path d="M62.7622 40.3381C59.8018 37.3588 56.2489 35.8462 52.2007 35.8462L52.0736 35.8489C48.0795 35.8787 44.5293 37.367 41.5229 40.2678C38.476 43.2065 36.9336 46.7729 36.9336 50.8671V51.4245C36.9336 51.9305 36.9579 52.4393 37.0039 52.9345C37.3341 56.4279 38.844 59.4856 41.4932 62.0239C44.4887 64.8922 48.0471 66.3588 52.0736 66.3805L52.5904 66.3832V64.0939L52.5688 63.6042L52.079 63.5798H52.033C51.6027 63.5771 51.1671 63.55 50.7395 63.5067C49.3676 63.3579 48.0416 62.9818 46.7996 62.3919C46.6535 62.3215 46.5101 62.2458 46.3856 62.1835L46.3396 62.1592C46.1853 62.0753 46.0311 61.9887 45.8823 61.8994L45.7984 61.8453C45.6577 61.7587 45.5143 61.6721 45.3735 61.5774L45.0651 61.3609C45.0028 61.3176 44.9379 61.2743 44.8784 61.2283L44.6835 61.0795C44.5834 61.0037 44.486 60.9279 44.3886 60.8495C44.3534 60.8224 44.3182 60.7926 44.2857 60.7629L44.1234 60.6276C44.0503 60.568 43.9773 60.5058 43.9042 60.4436C43.8393 60.3867 43.7743 60.3245 43.7094 60.265L43.5524 60.1216C43.5118 60.0837 43.4685 60.0458 43.4198 59.9971C43.2764 59.8591 43.1384 59.7211 43.0031 59.5804L42.7569 59.3125C41.2902 57.6212 40.3215 55.589 39.9345 53.4026H67.0728L67.1215 52.9453C67.1865 52.3392 67.2189 51.733 67.2189 51.1485V51.116C67.2135 46.9326 65.7117 43.3093 62.7622 40.3381Z" fill="#ED7964"/>
                      <path d="M4.63959 26.1398C7.59994 29.1191 11.1529 30.6317 15.201 30.6317L15.3282 30.629C19.3222 30.5992 22.8725 29.111 25.8788 26.2101C28.9258 23.2715 30.4682 19.705 30.4682 15.6108V15.0534C30.4682 14.5474 30.4438 14.0386 30.3978 13.5435C30.0677 10.05 28.5578 6.99227 25.9086 4.45406C22.9158 1.58572 19.3574 0.121786 15.3309 0.0974325L14.8141 0.0947266V2.38399L14.8357 2.87377L15.3255 2.89812H15.3715C15.8018 2.90083 16.2374 2.92789 16.665 2.97118C18.0369 3.12001 19.3628 3.49614 20.6049 4.08605C20.751 4.1564 20.8944 4.23217 21.0189 4.29441L21.0649 4.31876C21.2191 4.40265 21.3734 4.48924 21.5222 4.57854L21.6061 4.63266C21.7468 4.71925 21.8902 4.80584 22.0309 4.90055L22.3394 5.11703C22.4017 5.16032 22.4666 5.20362 22.5261 5.24962L22.721 5.39845C22.8211 5.47422 22.9185 5.54998 23.0159 5.62846C23.0511 5.65552 23.0863 5.68528 23.1187 5.71505L23.2811 5.85035C23.3542 5.90988 23.4272 5.97212 23.5003 6.03435C23.5652 6.09118 23.6302 6.15342 23.6951 6.21295L23.8521 6.35637C23.8926 6.39425 23.9359 6.43213 23.9846 6.48084C24.1281 6.61885 24.2661 6.75685 24.4014 6.89756L24.6476 7.16545C26.1143 8.85669 27.083 10.8889 27.47 13.0753H0.33167L0.282962 13.5326C0.218019 14.1388 0.185547 14.7449 0.185547 15.3294V15.3619C0.190959 19.5453 1.69007 23.1713 4.63959 26.1398Z" fill="#4C8866"/>
                    </svg>

                    <h2 className="email_title">Thank you for your interest in the eToy App; We hope you enjoy your journey with us</h2>
                    <h2 className="fs-5 text-center mb-3" style={{lineHeight: '24px !important', width: "70%", margin: "auto"}}>Download eToy characters now and have fun with your kids</h2>
                    <Col xs={12}>
                        <button className="email_code" style={{background: '#4c8866',color: '#fff', fontSize: '16px'}}>Download Now</button>
                    </Col>
                </Row>
                <Row className="email_btns m-0">
                    
                    <Col>
                        <img src={EmailImg} alt='' style={{width: "60%"}}/>
                        <Row>
                            <p className="d-block text-center  fs-5" style={{color: "#000"}}>earn. exchange. encourage</p>
                        </Row>
                        
                        <Row>
                            <p className="d-block text-center color-black-500 fs-5 mb-0" style={{color: "#000",fontWeight: "600"}}>For questions or support, reach out to</p>
                        </Row>
                        <Row>
                            <p className="d-block text-center fs-5 fw-bolder mb-5" style={{color: "#4c8866",fontWeight: "600"}}>support@etoyapp.com</p>
                        </Row>
                    </Col>
                </Row>
                <Row className="email_s m-0">
                  <Col className="pt-3">
                    <div className="e_social">
                    <div className="d-flex align-items-center">
                        <i className='bx bxl-facebook fs-3 '></i>
                        Facebook
                      </div>
                      <div className="d-flex align-items-center gap-1">
                        <i className='bx bxl-instagram fs-3 '></i>
                        Instagram
                      </div>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="e_social fs-6">
                      <div >
                      <u>Terms & Conditions</u>
                      </div>
                  
                      <div>
                        <u>Covid-19 Policy</u>
                      </div>
                      <div>
                        <u>Point Policy</u>
                      </div>
                      <div>
                        <u>Photo Guideline</u>
                      </div>
                      </div>
                  </Col>
                  
                </Row>
                <Row className="email_foot m-0">
                    <Col className="p-0 m-0">
                    <p >Please do not reply to this email. Emails sent to this address will not be answered. Copyright © 2012-2022 <u><a href="www.layouti.com" style={{color: "#4c8866"}}> Layouti Design</a></u> All Rights Reserved This email was sent from <span style={{color: "#4c8866"}}>www.etoyapp.com</span></p>
                    </Col>
                </Row>
                </Col>
            </Row>
            </Container>
        </div>
        </React.Fragment>
  )
}

export default CharacterPdf