/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import MetaTags from 'react-meta-tags'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import "./datatables.scss"
import './specification.scss'
import axios from "axios";
import { toast } from "react-toastify"; 
import ReactStars from "react-rating-stars-component";


const YesToy = (props) => {
    const [active, setActive] = useState(false)
    const[productData, setProductData] = useState([])
    const[editCop, setEditCop] = useState(null)
    const columns = [{
      dataField: 'id',
      text: 'ID',
      sort: true,
    }, {
      dataField: 'toy_image',
      text: 'Owner Image',
      sort: true
    }, {
      dataField: 'toyowner',
      text: 'Toy Owner',
      sort: true
    }, {
      dataField: 'uploaded_image',
      text: 'Receiver Image',
      sort: true
    },{
      dataField: 'seconduser',
      text: 'Receiver',
      sort: true
    },{
      dataField: 'deal',
      text: 'Deal',
      sort: true
    },{
      dataField: 'rate',
      text: 'Rate',
      sort: true
    },{
      dataField: 'description',
      text: 'Description',
      sort: true
    },{
      dataField: 'actions',
      text: 'Actions',
      sort: true
    },{
      dataField: 'created_at',
      text: 'Created On',
      sort: true
    }];

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];


  const pagination = paginationFactory({
    page: 2,
    sizePerPageList: [{
      text: "10", value: 10
    }, {
      text: "25", value: 25
    }, {
      text: "50", value: 50
    }, {
      text: "75", value: 75
    }, {
      text: "100", value: 100
    }, {
      text: "All", value: productData.length
    }],
    showTotal: true
  })



  // Select All Button operation
  const selectRow = {
    mode: 'checkbox'
  }

  const { SearchBar } = Search;

  function addProd(data, type) {
    setEditCop(null);
    if(type === "add"){
      setProductData(products => products.concat({...data, actions: <div><button style={editBtn}  onClick={() => editCoupon(data)}><i className='bx bx-edit fs-4'></i></button><button style={deleteBtn} id={data.id} onClick={(e)=>deleteHandler(e.currentTarget.id)}><i className='bx bx-trash fs-4'></i></button></div>}))
    }else {
      setProductData(products => products.map(prod => prod.id === data.id? {...prod,...data}:prod))
    }
  }


  function clickHandle () {
    setActive(!active)
    setEditCop(null);
  }


  function editCoupon(data) {
    setEditCop(data)
    setActive(true)
  }


  function deleteHandler(id, user) {
      axios.post(`${process.env.REACT_APP_API}/admin/users/delete`, {id}, {
        headers: { 
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`,
        }
      })
      .then(res => {
      })
      setProductData(products => products.filter(prod => prod.id != id))
      toast.success(`delete ${user.nameEn}`)
      props.userChanges(user, "del")

  }

  function acceptHandler(id) {
      axios.post(`${process.env.REACT_APP_API}/admin/reviews/accept-yes-toy`, {id}, {
        headers: { 
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`,
        }
      })
      .then(res => {
          console.log(res.data);
      })
    setProductData(toys => toys.map(toy => toy.id == id? {...toy, actions: null}:toy))
  }
  function rejectDescriptionHandler(id) {
      axios.post(`${process.env.REACT_APP_API}/admin/reviews/reject-desc-no-toy`, {id}, {
        headers: { 
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`,
        }
      })
      .then(res => {
          console.log(res.data);
      })
    setProductData(toys => toys.map(toy => toy.id == id? {...toy, actions: null}:toy))
  }
  
  function rejectHandler(id) {
      axios.post(`${process.env.REACT_APP_API}/admin/reviews/reject-whole-review-yes-toy`, {id}, {
        headers: { 
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`,
        }
      })
      .then(res => {
          console.log(res.data);
      })
    setProductData(toys => toys.map(toy => toy.id == id? {...toy, actions: null}:toy))
  }


  useEffect(() => {
    setProductData(props.data.map(yes => {return{...yes, 
        toy_image: yes.toy_image?<img src={yes.toy_image} className='usernametabl' alt=""/>: <div className="usernameplc"></div>,
        uploaded_image: yes.uploaded_image?<img src={yes.uploaded_image} className='usernametabl' alt=""/>: <div className="usernameplc"></div>,
        toyowner: yes.toyOwner.nameEn, 
        seconduser: yes.user_make_review.nameEn, 
        to: "",
        rate: <span style={{display: 'block',
        width: '150px'}}><ReactStars
        value={yes.rate?parseInt(yes.rate):0}
        count={5}
        size={25}
        activeColor="#deac5b"
        isHalf={true}
        edit={false}
      /></span>, 
        actions: yes.approved != 1 && yes.chanceCount < 1?<div className="d-flex gap-2">
                    <button style={{...editBtn, color: "green",gap: "5px"}}  onClick={()=>acceptHandler(yes.id)}><i className="fas fa-check"></i> Approve</button>
                    <button style={{...editBtn, gap: "5px"}} onClick={()=>rejectDescriptionHandler(yes.id)}><i className="fas fa-times fs-5"></i> Reject Description</button>
                  <button style={{...deleteBtn, gap: "5px"}}  onClick={(e)=>rejectHandler(yes.id)}><i className="fas fa-times fs-5"></i> Reject Card</button>
                </div>: <div className="d-flex gap-2">
                    <button style={{...editBtn, color: "#ccc",gap: "5px"}}   disabled><i className="fas fa-check"></i> Approve</button>
                    <button style={{...editBtn, color: "#ccc",gap: "5px"}} disabled ><i className="fas fa-times fs-5"></i> Reject Description</button>
                  <button style={{...editBtn, color: "#ccc",gap: "5px"}}   disabled><i className="fas fa-times fs-5"></i> Reject Card</button>
                </div>
      }}))
  }, [props.data]) 

  return (
    <React.Fragment>
        <div className="container-fluid p-0">
          <Row>
            <Col className="col-12">
              <Card >
                <CardBody className="pb-0">
                      <ToolkitProvider
                        keyField='id'
                        columns={columns}
                        data={productData}
                        search
                      >
                        {props => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col>
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative ">
                                    <SearchBar
                                      {...props.searchProps}
                                      className="input_cus"
                                    />
                                    <i className="bx bx-search-alt search-icon fs-3 pt-1" />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                              
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    pagination={pagination}
                                    selectRow={selectRow}
                                    classes={
                                      "table align-middle table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...props.baseProps}
                                  />
                                  {productData.length === 0?<div className="emptyStateTable" style={{
                                      width: '100%',
                                      textAlign: 'center',
                                      fontSize: '16px',
                                      padding: '10px',
                                      paddingTop: '0', paddingBottom: "20px"}}>no data available in table</div>:null}
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )
                        }
                      </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
    </React.Fragment>
  )
}

export default YesToy

const deleteBtn = {
  background: "transparent",
  border: "none",
  color:"#D91414",
  display: "flex",
  alignItems: "center",
}

const editBtn = {
  background: "transparent",
  border: "none",
  color:"#000",
  display: "flex",
  alignItems: "center"
}