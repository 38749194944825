import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import classnames from "classnames"
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap"
import Sticky from "react-sticky-el"
import Select from "react-select"
import PendingToys from "./Components/PendingToys"
import ActiveToys from "./Components/ActiveToys"
// import AdminToys from "../AdminToys/Components/EToyApp"
import History from "./Components/History"
import ActiveToysChat from "./Components/ActiveToysChat"
import "./configration.scss"
import axios from "axios"
import { useSelector, useDispatch } from "react-redux"
import {
  setTitle,
  updateNotifications
} from "../../../store/actions"
import { useHistory } from "react-router-dom"

const Toys = () => {
  const dispatch = useDispatch()
  const historyy = useHistory()
  const user = useSelector(state => state.UserData.user)
  const countries = useSelector(state => state.MainData.countries)
  const [activeState, setActiveState] = useState(null)
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const [pendingCount, setPendingCount] = useState()
  const [activeCount, setActiveCount] = useState()
  const [chatCount, setChatCount] = useState()
  const [historyCount, setHistoryCount] = useState()
  const [country, setCountry] = useState("")


  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  function updateState(type) {
    setActiveState(type)
  }


  function update(toy, type) {
    if (type === "active") {
      setActive(toys => toys.map(t => {
        if (t.id === toy.id) {
          return toy
        }
        return t
      }))
    }
   
    else {
      setChat(toys => toys.map(t => {
        if (t.id === toy.id) {
          return toy
        }
        return t
      }))
    }
  }


  function countryFilter(value) {
    if(value && value !== "All"){
        setCountry(value)
    }else if(value === "All"){
        setCountry("")
    }
  }

 
  useEffect(() => {
    if (user && user.role === 2) {
      historyy.push("/")
      return
    }
  }, [user])

  useEffect(() => {
    dispatch(setTitle("User Toys"))
    axios.post(`${process.env.REACT_APP_API}/admin/setUnread`, { key: "2" }, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
      }
    }).then(res => {
      dispatch(updateNotifications({ unreadToyCount: 0 }))
    })
  }, [])


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Toys | Etoy App</title>
        </MetaTags>
        <Container fluid>
          <Col>
            <Row className="mb-3">
              <Col>
                <Select
                  options={countries ? [{ value: "All", label: "All" }].concat(countries.map(c => {
                    return { value: c.nameEn, label: c.nameEn + ` (${c.toys_count})` }
                  })) : null}
                  placeholder="Country"
                  onChange={(e) => countryFilter(e.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Sticky topOffset={-70} style={{ top: "70px" }}>
                  <Nav pills className="flex-row justify-content-between navs_aside">
                    <NavItem className="nav__item border_right">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "": true,
                          active: verticalActiveTab === "1",
                          "tab_link": true
                        })}
                        onClick={() => {
                          toggleVertical("1")
                        }}
                      >
                        Pending Toys ({pendingCount})
                      </NavLink>
                    </NavItem>

                    <NavItem className="nav__item border_right">
                      <NavLink
                        style={{ cursor: "pointer", borderRadius: 0 }}
                        className={classnames({
                          "": true,
                          active: verticalActiveTab === "2",
                          "tab_link": true
                        })}
                        onClick={() => {
                          toggleVertical("2")
                        }}
                      >
                        Active Toys ({activeCount})
                      </NavLink>
                    </NavItem>

                    <NavItem className="nav__item border_right">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "": true,
                          active: verticalActiveTab === "3",
                          "tab_link": true
                        })}
                        onClick={() => {
                          toggleVertical("3")
                        }}
                      >
                        Active Toys Chat ({chatCount})
                      </NavLink>
                    </NavItem>
                    <NavItem className="nav__item border_right">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "": true,
                          active: verticalActiveTab === "4",
                          "tab_link": true
                        })}
                        onClick={() => {
                          toggleVertical("4")
                        }}
                      >
                        History ({historyCount})
                      </NavLink>
                    </NavItem>

                  </Nav>
                </Sticky>
              </Col>
              <Col md="12  mt-2">
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted table__content mt-4 mt-md-0"
                >
                  <TabPane tabId="1">
                    <PendingToys 
                     setPendingCount={setPendingCount} 
                     country={country}  
                     update={update} 
                     updateState={updateState}
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <ActiveToys 
                     setActiveCount={setActiveCount}    
                     country={country}  
                     update={update} 
                     activeState={activeState}
                    />
                  </TabPane>
                  <TabPane tabId="3">
                    <ActiveToysChat 
                      setChatCount={setChatCount}   
                      country={country}   
                      update={update} 
                    />
                  </TabPane>
                  <TabPane tabId="4">
                    <History 
                      setHistoryCount={setHistoryCount}    
                      country={country}   
                      update={update} 
                    />
                  </TabPane> 
                </TabContent>
              </Col>
            </Row>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Toys


const navsStyle = {
  cursor: "pointer",
  borderRadius: 0

}