/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"



import "./datatables.scss"
import "./specification.scss"
import axios from "axios"
import PulseLoader from "react-spinners/PulseLoader"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import SweetAlert from "react-bootstrap-sweetalert"
import UpdateForm from "./UpdateForm"
import { useSelector } from "react-redux";
import AdminPagination from "pages/AdminSection/AdminNotifications/components/AdminPagination"

  const ActiveToys = (props) => {
  const notifications = useSelector(state => state.Notifications.notifyCount)
  const [active, setActive] = useState(false)
  const [productData, setProductData] = useState([])
  const [editCop, setEditCop] = useState(null)
  const [isOpen, setisOpen] = useState(false)
  const [toy, setToy] = useState(null)
  const [prev, setPrev] = useState("")
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  const [ids, setIds] = useState([])
  const [pageNo, setPageNo] = useState(1)
  const sizePerPage = 10
  const [totalCount, setTotalCount] = useState(0)
  const [filter, setFilter]= useState('')
  const [loading, setLoading] = useState(false)
  
  const columns = [{
    dataField: "id",
    text: "Id",
    sort: true
  }, {
    dataField: "ownerName",
    text: "User Name",
    sort: true
  }, {
    dataField: "deal",
    text: "Deal",
    sort: true
  }, {
    dataField: "image",
    text: "Image",
    sort: true
  }, {
    dataField: "nameEn",
    text: "Toy Title(English)",
    sort: true
  }, {
    dataField: "nameAr",
    text: "Toy Title(Arabic)",
    sort: true
  }, {
    dataField: "category",
    text: "Category",
    sort: true
  }, 
    {
    dataField: "ages",
    text: "Age",
    sort: true
  }, {
    dataField: "descriptionEn",
    text: "Description(English)",
    sort: true
  }, {
    dataField: "descriptionAr",
    text: "Description(Arabic)",
    sort: true
  }, {
    dataField: "pickup_address_en",
    text: "Pickup Address(English)",
    sort: true
  }, {
    dataField: "pickup_address_ar",
    text: "Pickup Address(Arabic)",
    sort: true
  }, {
    dataField: "country",
    text: "Country",
    sort: true
  }, {
    dataField: "city",
    text: "City",
    sort: true
  }, {
    dataField: "actions",
    text: "Actions",
    sort: true
  },  {
    dataField: "status",
    text: "Status",
    sort: true
  }, {
    dataField: "gender",
    text: "Gender",
    sort: true
  }, {
    dataField: "created_at",
    text: "Created On",
    sort: true
  }]

  useEffect(() => {
    bindData()
  }, [pageNo, filter, notifications, props.activeState])



  useEffect(() => {
    setFilter(props.country)
  }, [props.country])


  const bindData = async() => {
    await axios.get(`${process.env.REACT_APP_API}/admin/activetoys?page=${pageNo}&search=${filter}`, {
          headers: {
              'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`, 
          }
        })
    .then(res => {
        const resData = res.data.data.active_toys.data
        setProductData(resData.map(user => {
          return {
            ...user,
            image: <img src={user.image} onClick={(e) => setOpen(e.target.src)} className="usernametabl" alt="" />,
            nameEn: user.nameEn ? user.nameEn : <span className="fs-4">-</span>,
            nameAr: user.nameAr ? user.nameAr : <span className="fs-4">-</span>,
            category: user?.categories.length !== 0? user?.categories.map((c,i)=> <span key={i} className={`${c.nameEn===1?'':'image-opacity'}`}  style={{backgroundColor: 'rgb(216 216 216)',
            color: 'rgb(0, 0, 0)',
            borderRadius: '45px',
            padding: '5px 20px',
            margin: '0px 2px',
            display: 'inline-block'}}>{c.nameEn}</span>
            ):<span className="fs-4">-</span>,
        
            deal: user.deal === "sale" ? user.price + " " + user.currency : user.deal,
            price: user.price ? user.price : <span className="fs-4">-</span>,
            country: user.country ? user.country : <span className="fs-4">-</span>,
            ages: user.maxAge ?  user.minAge ?  <span className="fs-4" style={{backgroundColor: 'rgb(216 216 216)',
            color: 'rgb(0, 0, 0)',
            borderRadius: '45px',
            padding: '5px 20px',
            margin: '0px 2px',
            display: 'inline-block'}}>{ user.minAge } To  { user.maxAge }</span>:<span className="fs-4" style={{backgroundColor: 'rgb(216 216 216)',
            color: 'rgb(0, 0, 0)',
            borderRadius: '45px',
            padding: '5px 20px',
            margin: '0px 2px',
            display: 'inline-block'}}>{ user.maxAge }</span> :<span className="fs-4">-</span>,
            status: user.status ? user.status : <span className="fs-4">-</span>,
            gender: user.gender ? user.gender : <span className="fs-4">-</span>,
            city: user.city ? user.city : <span className="fs-4">-</span>,
            descriptionEn: user.descriptionEn ? user.descriptionEn : <span className="fs-4">-</span>,
            descriptionAr: user.descriptionAr ? user.descriptionAr : <span className="fs-4">-</span>,
            pickup_address_en: user.pickup_address_en ? user.pickup_address_en : <span className="fs-4">-</span>,
            pickup_address_ar: user.pickup_address_ar ? user.pickup_address_ar : <span className="fs-4">-</span>,
            actions: <div className="d-flex gap-2">
              <button style={editBtn} onClick={(e) => edit(user)}><i className="bx bx-edit fs-4" /> Edit</button>
              <button style={deleteBtn} onClick={(e) => deleteHandler(user.id, user)}><i
                className="bx bx-trash fs-4" /> Delete
              </button>
            </div>
          }
        }))
        setTotalCount(res.data.data.active_toys.total)
         props.setActiveCount(res.data.data.counter)

    })
  }

  const onPageHandler =  (type, { page }) => {
    setPageNo(page)
 }

  function setOpen(img) {
    setisOpen(true)
    setPrev(img)
  }

// Select All Button operation
  const selectRow = {
    mode: "checkbox",

    onSelect: (row, isSelect, rowIndex, e) => {
      if (e.target.localName === "input") {
        if (isSelect) {
          setIds(ids => ids.concat(row.id))
        } else {
          setIds(ids => ids.filter(id => id !== row.id))
        }
      }
    }
  }


  function addProd(data, type) {
    setEditCop(null)
    if (type === "add") {
      setProductData(products => products.concat({
        ...data,
        actions: <div>
          <button style={editBtn} onClick={() => editCoupon(data)}><i className="bx bx-edit fs-4" /></button>
          <button style={deleteBtn} id={data.id} onClick={(e) => deleteHandler(e.currentTarget.id)}><i
            className="bx bx-trash fs-4" /></button>
        </div>
      }))
    } else {
      setProductData(products => products.map(prod => prod.id === data.id ? { ...prod, ...data } : prod))
    }
  }


  function clickHandle() {
    setActive(!active)
  }


  function editCoupon(data) {
    setEditCop(!edit)
    // setActive(true)
  }

  function update(toy, type) {
      setProductData(toys => toys.map(t => {
        if (t.id === toy.id) {
          return {
            ...toy,
            image: <img src={toy.image} onClick={(e) => setOpen(e.target.src)} className="usernametabl" alt="" />,
            nameEn: toy.nameEn ? toy.nameEn : <span className="fs-4">-</span>,
            nameAr: toy.nameAr ? toy.nameAr : <span className="fs-4">-</span>,
            deal: toy.deal === "sale" ? toy.price + " " + toy.currency : toy.deal,
            price: toy.price ? toy.price : <span className="fs-4">-</span>,
            country: toy.country ? toy.country : <span className="fs-4">-</span>,
            age: toy.age ? toy.age : <span className="fs-4">-</span>,
            status: toy.status ? toy.status : <span className="fs-4">-</span>,
            gender: toy.gender ? toy.gender : <span className="fs-4">-</span>,
            city: toy.city ? toy.city : <span className="fs-4">-</span>,
            descriptionEn: toy.descriptionEn ? toy.descriptionEn : <span className="fs-4">-</span>,
            descriptionAr: toy.descriptionAr ? toy.descriptionAr : <span className="fs-4">-</span>,
            pickup_address_en: toy.pickup_address_en ? toy.pickup_address_en : <span className="fs-4">-</span>,
            pickup_address_ar: toy.pickup_address_ar ? toy.pickup_address_ar : <span className="fs-4">-</span>,
            actions: <div className="d-flex gap-2">
              <button style={editBtn} onClick={(e) => edit(toy)}><i className="bx bx-edit fs-4" /> Edit</button>
              <button style={deleteBtn} onClick={(e) => deleteHandler(toy.id, toy)}><i
                className="bx bx-trash fs-4" /> Delete
              </button>
            </div>
          }
        }
        return t
      }))
  }


  function deleteHandler(id, user) {
    let ids = []
    ids.push(id)
    axios.post(`${process.env.REACT_APP_API}/admin/toys/delete`, { ids }, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
      }
    })
      .then(res => {
        bindData()
        setMsg("Deleted!")
        setsuccess_msg(true)
      })
    
  }

  function deleteMultiHandler() {
    axios.post(`${process.env.REACT_APP_API}/admin/toys/delete`, { ids }, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
      }
    })
      .then(res => {
      })
   
    setMsg("Deleted!")
    setsuccess_msg(true)
    setIds([])
  }

  function edit(toy) {
    setEditCop(true)
    setToy(toy)
  }

  function mssg() {
    setMsg("Toy Added!")
    setsuccess_msg(true)
  }




  return (
    <React.Fragment>
      <div className="container-fluid p-0">
        {success_msg ? (
          <SweetAlert
            title={msg}
            success
            confirmBtnBsStyle="success"
            onConfirm={() => {
              setsuccess_msg(false)
            }}
            onCancel={() => {
              setsuccess_msg(false)
            }}
          >
          </SweetAlert>
        ) : null}
        {isOpen ? (
          <Lightbox
            mainSrc={prev}
            enableZoom={false}
            onCloseRequest={() => {
              setisOpen(!isOpen);
            }}
          />
        ) : null}
        {editCop&&<UpdateForm active={editCop} setPageNo={setPageNo} setLoading={setLoading} 
        editCoupon={editCoupon} addProd={props.userChanges} toy={toy} admin={false}
          bindData={bindData} update={update} mssg={mssg}/>}
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody className="pb-0">
              <AdminPagination
                data={productData} page={pageNo}
                keyFiled='id'
                setFilter={setFilter}
                sizePerPage={sizePerPage} totalSize={totalCount}
                onTableChange={onPageHandler} columns={columns} />

              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default ActiveToys

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center"
}

const editBtn = {
  background: "transparent",
  border: "none",
  color: "#000",
  display: "flex",
  alignItems: "center"
}


const rej = {
  background: "transparent",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px",
  width: "150px",
  height: "48px",
  border: "1px solid #D91414",
  textAlign: "center",
  justifyContent: "center"
}