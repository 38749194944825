/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"

// datatable related plugins
// import BootstrapTable from "react-bootstrap-table-next"
// import paginationFactory, {
//   PaginationProvider, PaginationListStandalone,
//   SizePerPageDropdownStandalone
// } from "react-bootstrap-table2-paginator"

// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
// import AddUserForm from "./AddUserForm"
import "./datatables.scss"
import "./specification.scss"
import axios from "axios"
// import { toast } from "react-toastify"
import NotificationsPagination from "../../../AdminSection/AdminNotifications/components/AdminNotificationsPagination"
import { useSelector } from "react-redux"

const AdminToUser = (props) => {
  const notifications = useSelector(state => state.Notifications.notifyCount)
  const [active, setActive] = useState(false)
  // const [productData, setProductData] = useState([])
  const [adminToUser, setAdminToUser] = useState([])
  const [editCop, setEditCop] = useState(null)
  const [ids, setIds] = useState([])

  const columns = [{
    dataField: "key",
    text: "Id",
    sort: true
  }, {
    dataField: "userto[nameEn]",
    text: "To",
    sort: true
  }, {
    dataField: "deal",
    text: "Deal",
    sort: true
  },{
    dataField: "message_en",
    text: "Description (English)",
    sort: true
  }, {
    dataField: "message_ar",
    text: "Description (Arabic)",
    sort: true
  }, {
    dataField: "actions",
    text: "Actions",
    sort: true
  }, {
    dataField: "created_at",
    text: "Created On",
    sort: true
  }]


  const [pageNo, setPageNo] = useState(1)
  const sizePerPage=10;
  const [totalCount, setTotalCount] = useState(0)

  useEffect(() => {
    bindData()

  }, [pageNo])

  useEffect(() => {
    if(notifications) {
      bindData()
    }
  }, [pageNo, notifications])

  const bindData = async () => {
    // let { pageNo } = filter
    await axios.get(`${process.env.REACT_APP_API}/admin/notifications/getAdminToUser?page=${pageNo}`, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
      }
    }).then(res => {
      // console.log(res.data.data.data)
      setAdminToUser(res.data.data.data.map(user => {
        return {
          ...user,
          image: <img src={user.image} className="usernametabl" alt="" />,
          deal: user.deal === "sale" ? user.price + " " + user.currency : user.deal,
          city: user.city ? user.city : <span className="fs-4">-</span>,
          from: user["userfrom"] ? user["userfrom"]["nameEn"] :<span className="fs-4">-</span>,
          to: user["userto"] ? user["userto"]["nameEn"] : <span className="fs-4">-</span>,
          message_en: user.message_en ? user.message_en : <span className="fs-4">-</span>,
          message_ar: user.message_ar ? user.message_ar : <span className="fs-4">-</span>,
          actions: <div className="d-flex gap-2">
            <button style={deleteBtn} onClick={(e) => deleteHandler(user.key, user)}><i
              className="bx bx-trash fs-4" /> Delete
            </button>
          </div>
        }
      }))
      setTotalCount(res.data.data.total)
    })
  }

  const onPageHandler = async (type, {page}) => {
    await setPageNo(page)
  }

  // const pagination = paginationFactory({
  //   page: 2,
  //   sizePerPageList: [{
  //     text: "10", value: 10
  //   }, {
  //     text: "25", value: 25
  //   }, {
  //     text: "50", value: 50
  //   }, {
  //     text: "75", value: 75
  //   }, {
  //     text: "100", value: 100
  //   }, {
  //     text: "All", value: productData.length
  //   }],
  //   showTotal: true
  // })


// Custom Pagination Toggle
//   const sizePerPageList = [
//     { text: "5", value: 5 },
//     { text: "10", value: 10 },
//     { text: "15", value: 15 },
//     { text: "20", value: 20 },
//     { text: "25", value: 25 },
//     { text: "All", value: (productData).length }]


  // Select All Button operation
  // const selectRow = {
  //   mode: "checkbox",
  //   onSelect: (row, isSelect, rowIndex, e) => {
  //     if (e.target.localName === "input") {
  //       if (isSelect) {
  //         setIds(ids => ids.concat(row.id))
  //       } else {
  //         setIds(ids => ids.filter(id => id !== row.id))
  //       }
  //     }
  //   }
  // }

  // const { SearchBar } = Search
  //
  // function addProd(data, type) {
  //   setEditCop(null)
  //   if (type === "add") {
  //     setProductData(products => products.concat({ ...data,
  //       actions: <div>
  //         <button style={editBtn} onClick={() => editCoupon(data)}><i className="bx bx-edit fs-4"></i></button>
  //         <button style={deleteBtn} id={data.id} onClick={(e) => deleteHandler(e.currentTarget.id)}><i
  //           className="bx bx-trash fs-4"></i></button>
  //       </div>
  //     }))
  //   } else {
  //     setProductData(products => products.map(prod => prod.id === data.id ? { ...prod, ...data } : prod))
  //   }
  // }


  // function clickHandle() {
  //   setActive(!active)
  //   setEditCop(null)
  // }
  //
  // function editCoupon(data) {
  //   setEditCop(data)
  //   setActive(true)
  // }

  // function ActiveHandler(id, user) {
  //   axios.post(`${process.env.REACT_APP_API}/admin/users/active`, { id }, {
  //     headers: {
  //       "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
  //     }
  //   })
  //     .then(res => {
  //     })
  //   setProductData(products => products.filter(prod => prod.id != id))
  //   props.userChanges(user, "active")
  //   toast.success(`active ${user.nameEn}`)
  // }
  //

  function deleteHandler(id, user) {
    props.userChanges(id, "atu")
    axios.post(`${process.env.REACT_APP_API}/admin/notifications/delete`, { id }, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
      }
    })
      .then(res => {
        bindData()
      })
  }

  // function deleteMultiHandler() {
  //   axios.post(`${process.env.REACT_APP_API}/admin/notifications/delete`, { ids }, {
  //     headers: {
  //       "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
  //     }
  //   })
  //     .then(res => {
  //     })
  //   setProductData(products => products.filter(prod => prod.id !== id))
  //   //props.userChanges(user, "delactive")
  //   setMsg("Deleted!")
  //   setsuccess_msg(true)
  // }

  // useEffect(() => {
  //   setProductData(adminToUser.map(user => {
  //     return {
  //       ...user,
  //       image: <img src={user.image} className="usernametabl" alt="" />,
  //       deal: user.deal ? user.deal : <span className="fs-4">-</span>,
  //       city: user.city ? user.city : <span className="fs-4">-</span>,
  //       from: user["userfrom"] ? user["userfrom"]["nameEn"] : "-",
  //       to: user["userto"] ? user["userto"]["nameEn"] : "-",
  //       actions: <div className="d-flex gap-2">
  //         <button style={deleteBtn} onClick={(e) => deleteHandler(user.id, user)}><i
  //           className="bx bx-trash fs-4" /> Delete
  //         </button>
  //       </div>
  //     }
  //   }))
  // }, [adminToUser])

  return (
    <React.Fragment>
      <div className="container-fluid p-0">
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody className="pb-0">

                <NotificationsPagination data={adminToUser} page={pageNo}
                                         sizePerPage={sizePerPage} totalSize={totalCount}
                                         onTableChange={onPageHandler} columns={columns} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      {/*<div className="container-fluid p-0">*/}

      {/*  <AddUserForm active={active} clickHandle={clickHandle} addProd={addProd} editCop={editCop} />*/}
      {/*  <Row>*/}
      {/*    <Col className="col-12">*/}
      {/*      <Card>*/}
      {/*        <CardBody className="pb-0">*/}
      {/*          <ToolkitProvider*/}
      {/*            keyField="key"*/}
      {/*            columns={columns}*/}
      {/*            data={productData}*/}
      {/*            search*/}
      {/*          >*/}
      {/*            {props => (*/}
      {/*              <React.Fragment>*/}

      {/*                <Row className="mb-2">*/}
      {/*                  <Col>*/}
      {/*                    <div className="search-box me-2 mb-2 d-inline-block">*/}
      {/*                      <div className="position-relative ">*/}
      {/*                        <SearchBar*/}
      {/*                          {...props.searchProps}*/}
      {/*                          className="input_cus"*/}
      {/*                        />*/}
      {/*                        <i className="bx bx-search-alt search-icon fs-3 pt-1" />*/}
      {/*                      </div>*/}
      {/*                    </div>*/}
      {/*                  </Col>*/}
      {/*                </Row>*/}

      {/*                <Row>*/}
      {/*                  <Col xl="12">*/}
      {/*                    <div className="table-responsive">*/}
      {/*                      <BootstrapTable*/}
      {/*                        keyField={"key"}*/}
      {/*                        responsive*/}
      {/*                        bordered={false}*/}
      {/*                        striped={false}*/}
      {/*                        pagination={pagination}*/}
      {/*                        selectRow={selectRow}*/}
      {/*                        classes={*/}
      {/*                          "table align-middle table-nowrap"*/}
      {/*                        }*/}
      {/*                        headerWrapperClasses={"thead-light"}*/}
      {/*                        {...props.baseProps}*/}
      {/*                      />*/}
      {/*                      {productData.length === 0 ? <div className="emptyStateTable" style={{*/}
      {/*                        width: "100%",*/}
      {/*                        textAlign: "center",*/}
      {/*                        fontSize: "16px",*/}
      {/*                        padding: "10px",*/}
      {/*                        paddingTop: "0", paddingBottom: "20px"*/}
      {/*                      }}>no data available in table</div> : null}*/}
      {/*                    </div>*/}
      {/*                  </Col>*/}
      {/*                </Row>*/}
      {/*              </React.Fragment>*/}
      {/*            )*/}
      {/*            }*/}
      {/*          </ToolkitProvider>*/}
      {/*        </CardBody>*/}
      {/*      </Card>*/}
      {/*    </Col>*/}
      {/*  </Row>*/}
      {/*</div>*/}
    </React.Fragment>
  )
}

export default AdminToUser

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414"
}

const editBtn = {
  background: "transparent",
  border: "none",
  color: "green"
}

const rej = {
  background: "transparent",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px",
  width: "150px",
  height: "48px",
  border: "1px solid #D91414",
  textAlign: "center",
  justifyContent: "center"
}