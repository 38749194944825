/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Container } from "reactstrap"
import MetaTags from "react-meta-tags"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import "./datatables.scss"
import "./specification.scss"
import axios from "axios"
import { toast } from "react-toastify"
import Select from "react-select"
import { useSelector, useDispatch } from "react-redux"
import {
  setTitle,
  updateNotifications
} from "../../../store/actions";

import SweetAlert from "react-bootstrap-sweetalert"
import { useHistory } from "react-router-dom"


const PaperSuggestions = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const countries = useSelector(state => state.MainData.countries)
  const user = useSelector(state => state.UserData.user)
  const users = useSelector(state => state.MainData.users)
  const [active, setActive] = useState(false)
  const [productData, setProductData] = useState([])
  const [data, setData] = useState([])
  const [editCop, setEditCop] = useState(null)
  const [id, setId] = useState(null)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  const notifications = useSelector(state => state.Notifications.suggestionCount)


  const columns = [
    {
      dataField: "key",
      text: "Id",
      sort: true,
    },
    {
      dataField: "userName",
      text: "User Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "gender",
      text: "Gender",
      sort: true,
    },
    {
      dataField: "description",
      text: "Desctiption",
      sort: true,
    },
    {
      dataField: "actions",
      text: "Actions",
      sort: true,
    },
    {
      dataField: "created_at",
      text: "Created On",
      sort: true,
    },
  ]


  const pageOptions = {
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
    sizePerPageList: [ {
      text: '10', value: 10
    }, {
      text: '25', value: 25
    }, {
      text: '50', value: 50
    }, {
      text: '75', value: 75
    }, {
      text: '100', value: 100
    }, {
      text: 'All', value: productData.length
    } ]
  }


  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect, rowIndex, e) => {
      if (e.target.localName === "input") {
        if (isSelect) {
          setIds(ids => ids.concat(row.id))
        } else {
          setIds(ids => ids.filter(id => id !== row.id))
        }
      }
    }
  }


  const { SearchBar } = Search


  // function clickHandle(id) {
  //   setId({ id, lang: "en" })
  //   setActive(!active)
  //   setEditCop(null)
  // }
  function clickHandle() {
    setActive(!active)
    setEditCop(null)
  }

  function deleteHandler(id) {
    axios
      .post(
        `${process.env.REACT_APP_API}/etoypaper-dashboard/toypapersuggestions/delete`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).authToken
            }`,
          },
        }
      )
      .then(res => {})
    setProductData(products => products.filter(prod => prod.key !== id))
    setMsg("Deleted!")
    setsuccess_msg(true)
  }

  // function deleteMultiHandler() {
  //   axios.post(`${process.env.REACT_APP_API}/admin/notifications/delete`, { ids }, {
  //     headers: {
  //       "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
  //     }
  //   })
  //     .then(res => {
  //     })
  //   setProductData(products => products.filter(prod => prod.id !== id))
  //   //props.userChanges(user, "delactive")
  //   setMsg("Deleted!")
  //   setsuccess_msg(true)
  // }


    // function countryFilter(name) {
    //   if (name === "All") {
    //     setProductData(
    //       data.map(user => {return{...user,
    //         email:user.email?user.email:<span className="fs-4">-</span>,
    //         description:user.description?user.description:<span className="fs-4">-</span>,
    //         actions: <div className="d-flex gap-2">
    //           <button style={deleteBtn}  onClick={(e)=>deleteHandler(user.key)}><i className='bx bx-trash fs-4'></i> Delete</button>
    //         </div>
    //       }}))
    //     return
    //   }
    //   let report = data.filter(
    //     re => re.country == name
    //   )
    //   setProductData(
    //     report.map(user => {return{...user,
    //       email:user.email?user.email:<span className="fs-4">-</span>,
    //       description:user.description?user.description:<span className="fs-4">-</span>,
    //       actions: <div className="d-flex gap-2">
    //         <button style={deleteBtn}  onClick={(e)=>deleteHandler(user.key)}><i className='bx bx-trash fs-4'></i> Delete</button>
    //       </div>
    //     }})
    //   )
    // }



  useEffect(() => {
    if(user && user.role == 2) {
      history.push('/')
      return;
    }
  }, [user])


  useEffect(() => {
    dispatch(setTitle("Suggestions"))
    axios.get(`${process.env.REACT_APP_API}/etoypaper-dashboard/toypapersuggestions`,  {
      headers: {
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`,
      }
    })
      .then(res => {
        setData(res.data.data)
        setProductData(res.data.data.map(user => {return{...user,
          email:user.email?user.email:<span className="fs-4">-</span>,
          description:user.description?user.description:<span className="fs-4">-</span>,
          actions: <div className="d-flex gap-2">
            <button style={deleteBtn}  onClick={(e)=>deleteHandler(user.key)}><i className='bx bx-trash fs-4'></i> Delete</button>
          </div>
        }}))
      })
    // axios.post(`${process.env.REACT_APP_API}/admin/setUnread`, {key: "3"},{
    //   headers: {
    //     'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`,
    //   }
    // }).then(res => {
    //   dispatch(updateNotifications({unreadSuggestionCount: 0}))
    // })
  }, [])



  useEffect(() => {
    if(notifications) {
      axios.get(`${process.env.REACT_APP_API}/etoypaper-dashboard/toypapersuggestions`,  {
        headers: {
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`,
        }
      })
        .then(res => {
          setData(res.data.data)
          setProductData(res.data.data.map(user => {return{...user,
            email:user.email?user.email:<span className="fs-4">-</span>,
            description:user.description?user.description:<span className="fs-4">-</span>,
            actions: <div className="d-flex gap-2">
              <button style={deleteBtn}  onClick={(e)=>deleteHandler(user.key)}><i className='bx bx-trash fs-4' /> Delete</button>
            </div>
          }}))
        })
      // axios.post(`${process.env.REACT_APP_API}/admin/setUnread`, {key: "3"},{
      //   headers: {
      //     'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`,
      //   }
      // }).then(res => {
      //   dispatch(updateNotifications({unreadSuggestionCount: 0}))
      // })
    }
  }, [notifications])


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Suggestions | Etoy App</title>
        </MetaTags>
        <Container fluid>
          {success_msg ? (
            <SweetAlert
              title={msg}
              success
              confirmBtnBsStyle="success"
              onConfirm={() => {
                setsuccess_msg(false)
              }}
              onCancel={() => {
                setsuccess_msg(false)
              }}
            >
            </SweetAlert>
          ) : null}
          <Row className="mb-3">
            <Col>
              {/* <Select
                        options={countries?[{value:"All", label:"All"}].concat(countries.map(c => {return {value: c.nameEn, label: c.nameEn} })):null}
                        placeholder="Country"
                        onChange={(e) => countryFilter(e.value)}
                    /> */}
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody className="pb-0">
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="key"
                    columns={columns}
                    data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="key"
                        columns={columns}
                        data={productData}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col>
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative ">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      className="input_cus"
                                    />
                                    <i className="bx bx-search-alt search-icon fs-3 pt-1" />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"key"}
                                    responsive
                                    bordered={false}
                                    striped={false}

                                    selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                  {productData.length === 0 ? (
                                    <div
                                      className="emptyStateTable"
                                      style={{
                                        width: "100%",
                                        textAlign: "center",
                                        fontSize: "16px",
                                        padding: "10px",
                                        paddingTop: "0",
                                        paddingBottom: "20px",
                                      }}
                                    >
                                      no data available in table
                                    </div>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>

                            {productData.length !== 0 ? (
                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            ) : null}
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PaperSuggestions

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px",
}

const editBtn = {
  background: "transparent",
  border: "none",
  color: "#000",
  display: "flex",
  alignItems: "center",
  gap: "5px",
}
