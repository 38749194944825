/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Form, Label, Input, FormFeedback } from "reactstrap"
import MetaTags from 'react-meta-tags'
import PulseLoader from "react-spinners/PulseLoader";

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import AddUserForm from './AddAvatarForm'
import "./datatables.scss"
import './specification.scss'
import axios from "axios";
import { toast } from "react-toastify";
import SweetAlert from "react-bootstrap-sweetalert"

const Points = (props) => {
  const [active, setActive] = useState(false)
  const[givAwayPoints, setGivAwayPoints] = useState("")
  const[ratePoints, setRatePoints] = useState("")
  const[swapPoints, setSwapPoints] = useState("")
  const [loading, setLoading] = useState(false)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")


  

  function submit(e) {
    e.preventDefault();
    setLoading(true)
    let data = {
      swap_points: swapPoints,
      give_away_points: givAwayPoints,
      rate_points: ratePoints
    }
    axios.post(`${process.env.REACT_APP_API}/admin/setting/pointUpdate`, data, {
      headers: {
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`, 
      }
    })
    .then((res) => {
      if(res.data.error) {
        console.log(res.data.error);
      }
      setMsg("Updated!")
      setsuccess_msg(true)
      setLoading(false)
    })
  }


  useEffect(() => {
    if(props.points) {
      setGivAwayPoints(props.points.givAwayPoints)
      setSwapPoints(props.points.swapPoints)
      setRatePoints(props.points.ratePoints)
    }
  }, [props.points]) 


  return (
    <React.Fragment>
        <div className="container-fluid p-0">
        {success_msg ? (
                    <SweetAlert
                      title={msg}
                      success
                      confirmBtnBsStyle="success"
                      onConfirm={() => {
                        setsuccess_msg(false)
                      }}
                      onCancel={() => {
                        setsuccess_msg(false)
                      }}
                    >
                    </SweetAlert>
                  ) : null}
          <Card>
            <CardBody>
                <Form className="border-0 p-0">
                  <Row>
                      <Col>
                          <div className="mb-3" >
                              <Label htmlFor="productdesc">
                              Swap Points
                              </Label>
                              <Input
                                  
                                  name="title"
                                  type="text"
                                  className="form-control input_cus"
                                  placeholder="e.g.Name"
                                  value={swapPoints}
                                  onChange = {e => setSwapPoints(e.target.value)}
                              />
                              <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                          </div>
                      </Col>
                  </Row>
                  <Row>
                      <Col>
                          <div className="mb-3" >
                              <Label htmlFor="productdesc">
                              Give Away Points
                              </Label>
                              <Input
                                  
                                  name="title"
                                  type="text"
                                  className="form-control input_cus"
                                  placeholder="e.g.Name"
                                  value={givAwayPoints}
                                  onChange = {e => setGivAwayPoints(e.target.value)}
                              />
                              <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                          </div>
                      </Col>
                  </Row>
                  <Row>
                      <Col>
                          <div className="mb-3" >
                              <Label htmlFor="productdesc">
                                Rate Points
                              </Label>
                              <Input
                                  
                                  name="title"
                                  type="text"
                                  className="form-control input_cus"
                                  placeholder="e.g.Name"
                                  value={ratePoints}
                                  onChange= {e => setRatePoints(e.target.value)}
                              />
                              <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                          </div>
                      </Col>
                  </Row>
                  <Row>
                      <Col>
                          <div className="save__btn top m-0">
                              <button onClick={e => submit(e)}>{loading?<PulseLoader color={"#ffffff"} loading={true}  size={10} />:"Save"}</button>
                          </div>
                      </Col>
                  </Row>
              </Form>    
            </CardBody>
          </Card>      
        </div>
    </React.Fragment>
  )
}

export default Points

const deleteBtn = {
  background: "transparent",
  border: "none",
  color:"#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px"

}

const editBtn = {
  background: "transparent",
  border: "none",
  color:"green",
  display: "flex",
  alignItems: "center",
  gap: "5px"
}