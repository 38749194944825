/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Container } from "reactstrap"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import AddUserForm from "../../../Options/Components/AddUserForm"
import UpdateUserForm from "../../../Options/Components/UpdateUserForm"
import "../datatables.scss"
import "../specification.scss"
import axios from "axios"
import SweetAlert from "react-bootstrap-sweetalert"
import { useSelector, useDispatch } from "react-redux"
import { getUserData } from "../../../../store/actions"
import MetaTags from "react-meta-tags"


const Admins = (props) => {
  const dispatch = useDispatch()
  const userData = useSelector(state => state.UserData.user)
  const [active, setActive] = useState(false)
  const [addActive, setAddActive] = useState(false)
  const [productData, setProductData] = useState([])
  const [user, setUser] = useState(null)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  const columns = [{
    dataField: "key",
    text: "Id",
    sort: true
  }, {
    dataField: "image",
    text: "Image",
    sort: true
  }, {
    dataField: "nameEn",
    text: "Name",
    sort: true
  }, {
    dataField: "email",
    text: "Email",
    sort: true
  }, {
    dataField: "role",
    text: "Role",
    sort: true
  }, {
    dataField: "country",
    text: "Country",
    sort: true
  }, {
    dataField: "actions",
    text: "Actions",
    sort: true
  }, {
    dataField: "created_at",
    text: "Created On",
    sort: true
  }]

  const pagination = paginationFactory({
    page: 2,
    sizePerPageList: [{
      text: "10", value: 10
    }, {
      text: "25", value: 25
    }, {
      text: "50", value: 50
    }, {
      text: "75", value: 75
    }, {
      text: "100", value: 100
    }, {
      text: "All", value: productData.length
    }],
    showTotal: true
  })


  //Select All Button operation
  const selectRow = {
    mode: "checkbox"
  }

  const { SearchBar } = Search


  function clickHandle(user, type) {
    setActive(type)
    setUser(user)
  }

  function clickAddHandle(user, type) {
    setAddActive(type)
  }

  function update(data, type) {
    if (type === "add") {
      setProductData(users => users.concat({
        ...data,
        image: <img src={data.image} className="usernametabl" alt="" />,
        role: data.role === "1" ? "Admin" : data.role === "2" ? "Viewer" : "User",
        actions: <div className="d-flex">
          <button style={editBtn} onClick={() => clickHandle(data, true)}><i className="bx bx-edit fs-4 pr-1"
                                                                            style={{ marginRight: "10px" }}></i> Edit
          </button>
          <button style={deleteBtn} onClick={(e) => deleteHandler(data.key)}><i className="bx bx-trash fs-4"
                                                                                style={{ marginRight: "10px" }}></i> Delete
          </button>
        </div>
      }))
      setMsg("Added!")
    } else {

      setProductData(users => users.map(user => user.key === data.key ? {
        ...data,
        image: <img src={data.image} className="usernametabl" alt="" />,
        role: data.role === "1" ? "Admin" : data.role === "2" ? "Viewer" : "User",
        actions: <div className="d-flex">
          <button style={editBtn} onClick={() => clickHandle(data, true)}><i className="bx bx-edit fs-4 pr-1"
                                                                            style={{ marginRight: "10px" }}></i> Edit
          </button>
          {user.key !== 1 ?
            <button style={deleteBtn} onClick={(e) => deleteHandler(user.key)}><i className="bx bx-trash fs-4"
                                                                                  style={{ marginRight: "10px" }}></i> Delete
            </button> : null}
        </div>
      } : user))
      setMsg("Updated!")
      if (userData.key == data.key) {
        let u = JSON.parse(localStorage.getItem("authUser"))
        dispatch(getUserData(data))
        localStorage.setItem("authUser", JSON.stringify({ ...u, ...data }))
      }
    }
    setsuccess_msg(true)
  }


  
  function deleteHandler(id) {
    axios.post(`${process.env.REACT_APP_API}/admin/setting/adminDelete`, { id }, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
      }
    })
      .then(res => {
      })
    setProductData(products => products.filter(prod => prod.key != id))
    setMsg("Deleted!")
    setsuccess_msg(true)
  }

  useEffect(() => {
    setProductData(props.admin.map(user => {
      return {
        ...user,
        image: <img src={user.image} className="usernametabl" alt="" />,
        role: user.role === "1" ? "Admin" : user.role === "2" ? "Viewer" : "User",
        actions: <div className="d-flex">
          <button style={editBtn} onClick={() => clickHandle(user, true)}><i className="bx bx-edit fs-4 pr-1"
                                                                            style={{ marginRight: "10px" }} /> Edit
          </button>
          {user.key !== 1 ?
            <button style={deleteBtn} onClick={(e) => deleteHandler(user.key)}><i className="bx bx-trash fs-4"
                                                                                  style={{ marginRight: "10px" }} /> Delete
            </button> : null}
        </div>
      }
    }))
  }, [props.admin])


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>eToy Paper</title>
        </MetaTags>
        <Container fluid>
          <UpdateUserForm active={active} clickHandle={clickHandle} user={user} update={update} />
          <AddUserForm active={addActive} clickAddHandle={clickAddHandle} update={update} />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody className="pb-0">
                  {success_msg ? (
                    <SweetAlert
                      title={msg}
                      success
                      confirmBtnBsStyle="success"
                      onConfirm={() => {
                        setsuccess_msg(false)
                      }}
                      onCancel={() => {
                        setsuccess_msg(false)
                      }}
                    >
                    </SweetAlert>
                  ) : null}
                  <ToolkitProvider
                    keyField="key"
                    columns={columns}
                    data={productData}
                    search
                  >
                    {props => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col>
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative ">
                                <SearchBar
                                  {...props.searchProps}
                                  className="input_cus"
                                />
                                <i className="bx bx-search-alt search-icon fs-3 pt-1" />
                              </div>
                            </div>
                          </Col>
                          <Col>
                            <div className="save__btn top">
                              <button onClick={() => clickAddHandle(null, true)}><i
                                className="bx bx-plus-circle fs-4"></i> Add Admin
                              </button>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"key"}
                                responsive
                                bordered={false}
                                striped={false}
                                pagination={pagination}
                                selectRow={selectRow}
                                classes={
                                  "table align-middle table-nowrap"
                                }
                                headerWrapperClasses={"thead-light"}
                                {...props.baseProps}
                              />
                              {productData.length === 0 ? <div className="emptyStateTable" style={{
                                width: "100%",
                                textAlign: "center",
                                fontSize: "16px",
                                padding: "10px",
                                paddingTop: "0", paddingBottom: "20px"
                              }}>no data available in table</div> : null}
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )
                    }
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Admins

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "0px"

}

const editBtn = {
  background: "transparent",
  border: "none",
  display: "flex",
  alignItems: "center",
  gap: "0px"
}