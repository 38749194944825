/* eslint-disable react/prop-types */
import axios from "axios"
import React, { useState, useRef, useEffect } from "react"
import { Card, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap"
import PulseLoader from "react-spinners/PulseLoader"
import ReactTagInput from "@pathofdev/react-tag-input"
import "@pathofdev/react-tag-input/build/index.css"
import { Link } from "react-router-dom"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

import "../../../AdminSection/AdminToys/specification.scss"
import Select from "react-select"
import { useSelector } from "react-redux"
// import { v4 as uuid } from "uuidv4"
import Dropzone from "react-dropzone"
import { useHistory } from "react-router-dom"


const UpdateEToyPaperForm = ({ update, mssg, editCoupon, toy, active, paperCategories, bindData, setEditCop }) => {
  const categories = useSelector(state => state.MainData.categories)
  const countries = useSelector(state => state.MainData.countries)

  const [isOpen, setisOpen] = useState(false)
  const [prev, setPrev] = useState("")
  const [titleEn, setTitleEn] = useState("")
  const [titleAr, setTitleAr] = useState("")
  const [descriptionEn, setDescriptionEn] = useState("")
  const [descriptionAr, setDescriptionAr] = useState("")
  const [paperCategoryId, setPaperCategory] = useState("")
  const [category, setCategoryName] = useState("")
  const [age, setAge] = useState("")
  const [downloadLink, setDownloadLink] = useState("")
  const [upload, setUpload] = useState([])
  const [photoIndex, setphotoIndex] = useState(0)

  const [loading, setLoading] = useState(false)

  function handleAcceptedFiles(files, key) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
        originalFile: file
      })
    )
    setUpload(fil => fil.concat(files))
  }


  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

 

  function close() {
    active ? setEditCop(false) : editCoupon()
  }

  function prevSelectImage(e, imgSrc) {
    e.preventDefault()
    setPrev(imgSrc)
    // setphotoIndex(id)
    setisOpen(!isOpen)
  }

  function deleteSelectImage(e, id, key) {
    e.preventDefault()
    axios.post(`${process.env.REACT_APP_API}/etoypaper-dashboard/toyspaper/deleteToyPaperImage`, { id: key }, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`
      }
    }).then(res => {
    })
    setUpload(files => files.filter((f, i) => i !== id))
  }


  function submitHandler({getPageNo}) {
    setLoading(true)
    const formData = new FormData()
    let data = {
      id: toy.key,
      titleEn,
      titleAr,
      descriptionEn,
      descriptionAr,
      toypapercatId: paperCategoryId,
      age,
      downloadLink
    }
    for (let key in data) {
      formData.append(key, data[key])
    }
    

    upload.map((photo, i) => {
      if (photo.name) {
        formData.append(`upload[${i}]`, photo)
      }
      else { 
        formData.append(`upload[${i}]`, photo.preview)
      }
    })

    axios.post(`${process.env.REACT_APP_API}/etoypaper-dashboard/toyspaper/update`, formData, {
      headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`,
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        setLoading(false)
        if (res.data.error) {
          return
        }
        // update(res.data.data, "updateCat")
        mssg("Updated!")
        editCoupon()
        setTitleEn("")
        setTitleAr("")
        setDescriptionEn("")
        close()
        bindData()

      })
  }


  useEffect(() => {
    if (toy) {
      setTitleEn(toy.titleEn || "")
      setTitleAr(toy.titleAr || "")
      setDescriptionEn(toy.descriptionEn || "")
      setDescriptionAr(toy.descriptionAr || "")
      setPaperCategory(toy.toypapercatId)
      setCategoryName(toy.catNameEn || "")
      setAge(toy.age)
      setDownloadLink(toy.downloadLink)
      setUpload(toy.images.map(s => {
        return { preview: s.image, id: s.key }
      }))
    }
  }, [toy])


  return (
    <div className={`overlay_coup ${active ? "active" : ""}`}>
      {isOpen ? (
        <Lightbox
          mainSrc={prev}
          enableZoom={false}
          onCloseRequest={() => {
            setisOpen(!isOpen)
          }}
        />
      ) : null}
      <Form id="toyUpdateForm" onSubmit={(e) => e.preventDefault()} style={{ paddingBottom: "70px", height: "580px" }}>
        <div className="head_form">
          <div className="log fs-5">
            {/* <i className="fas fa-user fs-5"></i> */}
            Edit Toy Paper
          </div>
          <button onClick={close}><i className="dripicons dripicons-cross" /></button>
        </div>
        <div className="inputs__wrap ">
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">
                  Toy Title (English)
                </Label>
                <Input
                  id="title"
                  name="title"
                  type="text"
                  className="form-control input_cus"
                  placeholder="e.g.Name"
                  value={titleEn}
                  onChange={e => setTitleEn(e.target.value)}
                />
                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">
                  Toy Title (Arabic)
                </Label>
                <Input
                  id="title"
                  name="title"
                  type="text"
                  className="form-control input_cus"
                  placeholder="مثال. الاسم"
                  dir="rtl"
                  value={titleAr}
                  onChange={e => setTitleAr(e.target.value)}
                />
                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">
                  Description (English)
                </Label>
                <Input
                  name="text"
                  type="textarea"
                  value={descriptionEn}
                  onChange={e => setDescriptionEn(e.target.value)}
                />
                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">
                  Description (Arabic)
                </Label>
                <Input
                  name="text"
                  type="textarea"
                  value={descriptionAr}
                  onChange={e => setDescriptionAr(e.target.value)}
                  dir="rtl"
                />
                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">
                  Age
                </Label>
                <select
                  className="form-select fs-5 invalid input_cus"
                  value={age}
                  onChange={e => setAge(e.target.value)}
                >
                  <option>Select</option>
                  <option value="+0">0 - 2</option>
                  <option value="+2">2 - 4</option>
                  <option value="+4">4 - 6</option>
                  <option value="+6">6 - 8</option>
                  <option value="+8">8 - 10</option>
                  <option value="+8">8 - 10</option>
                </select>
                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">
                  Toy category
                </Label>
                <Select
                  options={paperCategories ? paperCategories.map(c => {
                    return { value: c.key, label: c.titleEn }
                  }) : null}
                  placeholder={category}
                  onChange={(e) => setPaperCategory(e.value)}
                />
                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">
                  Download Link
                </Label>
                <Input
                  name="text"
                  type="text"
                  value={downloadLink}
                  onChange={e => setDownloadLink(e.target.value)}
                  dir="ltr"
                />
                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="metatitle">Toy Images</Label>
                {/*<div className="mb-3" ref={imageRef}>*/}
                {/*  <Input*/}
                {/*    type="file"*/}
                {/*    className="form-control form-control-lg "*/}
                {/*    onChange={(e) => {*/}
                {/*      // for (let i = 0; i < e.target.files.length; i++){*/}
                {/*      //   setImages(e.target.files[i])*/}
                {/*      // }*/}
                {/*      console.log("inside inchange", e.target.files[0])*/}
                {/*      handleAcceptedFiles(Object.values(e.target.files), image.key)*/}
                {/*    }}*/}
                {/*    style={{ borderRadius: 0 }}*/}
                {/*  />*/}
                {/*  <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>*/}
                {/*</div>*/}
                <Dropzone
                  onDrop={acceptedFiles => {
                    // setImagesss((prev) => [...prev, acceptedFiles] )
                    // console.log('acceptedFiles', acceptedFiles)
                    handleAcceptedFiles(acceptedFiles)
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone">
                      <div
                        className="dz-message needsclick"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className="dz-message needsclick">
                          <div className="mb-3">
                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                          </div>
                          <h4>Drop files here or click to upload.</h4>
                        </div>
                      </div>
                    </div>
                  )}
                </Dropzone>
                <div className="dropzone-previews mt-3" id="file-previews">
                  {upload.map((f, i) => {
                    return (
                      <Card
                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                        key={i + "-file"}
                      >
                        <div className="p-2">
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={f.name}
                                src={f.preview}
                                style={{ objectFit: "cover" }}
                              />
                            </Col>
                            <Col>
                              <Link to="#" className="text-muted ">
                                {f.name}
                              </Link>
                              <p className="mb-0">
                                <strong>{f.formattedSize}</strong>
                              </p>
                            </Col>
                            <Col>
                              <div className="image__options">
                                <button
                                  className="p-0"
                                  onClick={e => prevSelectImage(e, f.preview)}
                                >
                                  <i className="far fa-eye  fs-5 p-0" />
                                </button>
                                <button
                                  style={{ color: "#ff3838" }}
                                  onClick={(e) => deleteSelectImage(e, i, f.id)}
                                >
                                  <i className="bx bx-trash fs-4" />
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    )
                  })}
                </div>
              </div>
            </Col>

            {/*  {image ? <Row className="align-items-center" style={{ paddingLeft: "14px", paddingRight: "0px" }}>*/}
            {/*    <Col className="col-auto">*/}
            {/*      <img src={image.image} key={image.key}*/}
            {/*           onClick={(e) => setOpen(e.target.src)} alt="" className="usernametabl"*/}
            {/*           style={{ objectFit: "cover" }} />*/}
            {/*    </Col>*/}
            {/*    <Col>*/}
            {/*      <Link*/}
            {/*        to="#"*/}
            {/*        className="text-muted "*/}
            {/*      >*/}
            {/*        {image.name}*/}
            {/*      </Link>*/}
            {/*      <p className="mb-0">*/}
            {/*        <strong>{image.formattedSize}</strong>*/}
            {/*      </p>*/}
            {/*    </Col>*/}
            {/*    <Col className="p-0">*/}
            {/*      <div className="image__options">*/}
            {/*        <button className="p-0" onClick={(e) => setisOpen(true)}><i className="far fa-eye  fs-5 p-0" />*/}
            {/*        </button>*/}
            {/*        <button className="p-0" style={{ color: "#ff3838" }} onClick={(e) => deleteImage(image.key)}><i*/}
            {/*          className="bx bx-trash fs-4" /></button>*/}
            {/*      </div>*/}
            {/*    </Col>*/}
            {/*  </Row> : null }*/}
          </Row>
        </div>
        <div className="foot_form">
          <button className="cancel" onClick={close}>Cancel</button>
          <button className="save" onClick={submitHandler}>{loading ?
            <PulseLoader color={"#ffffff"} loading={true} size={10} /> : "Save"}</button>
        </div>
      </Form>
    </div>
  )
}

export default UpdateEToyPaperForm
