import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import classnames from "classnames"


import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"


import Sticky from "react-sticky-el"
import Select from "react-select"
import PendingUsers from "./Components/PendingUsers"
import ActiveUsers from "./Components/ActiveUsers"
import UnActiveUsers from "./Components/UnActiveUsers"
import "./configration.scss"
import axios from "axios"
import { useSelector, useDispatch } from "react-redux"
import { setTitle, updateNotifications } from "../../../store/actions"
import Guests from "./Components/Guests"
import { useHistory } from "react-router-dom"




const Users = () => {
  const dispatch = useDispatch()
  const countries = useSelector(state => state.MainData.countries)
  const user = useSelector(state => state.UserData.user)
  const history = useHistory()
  const [activeState, setActiveState] = useState(null)
  const [activeCount, setActiveCount] = useState([])
  const [unActiveCount, setUnActiveCount] = useState([])
  const [guestsCount, setGuestsCount] = useState([])
  const [pendingCount, setPendingCount] = useState([])
  const [users, setUsers] = useState([])
  const [isread, setIsRead] = useState(0)
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const [country, setCountry] = useState("")

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
    if (tab === "2" && isread === 1) {
      axios.post(
        `${process.env.REACT_APP_API}/admin/users/redDot`,
        {},
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).authToken
            }`,
          },
        }
      )
      setIsRead(0)
    }
  }

  function countryFilter(value) {
    console.log(value)
    if (value && value !== "All") {
      setCountry(value)
    } else if (value === "All") {
      setCountry("")
    }
  }

  useEffect(() => {
    if (user && user.role == 2) {
      history.push("/dashboard")
      return
    }
    dispatch(setTitle("Users"))
    axios
      .post(
        `${process.env.REACT_APP_API}/admin/setUnread`,
        { key: "1" },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).authToken
            }`,
          },
        }
      )
      .then(res => {
        dispatch(updateNotifications({ unreadUserCount: 0 }))
      })
  }, [user])

  function update(user) {
    setActive(users =>
      users.map(t => {
        if (t.key === user.key) {
          return user
        }
        return t
      })
    )
  }

  function updateState(type) {
    setActiveState(type)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Users | Etoy App</title>
        </MetaTags>
        <Container fluid>
          <Col>
            <Row className="mb-3">
              <Col>
                <Select
                  options={
                    countries
                      ? [{ value: "All", label: "All" }].concat(
                          countries.map(c => {
                            return {
                              value: c.nameEn,
                              label: c.nameEn + ` (${c.users_count})`,
                            }
                          })
                        )
                      : null
                  }
                  placeholder="Country"
                  onChange={e => countryFilter(e.value)}
                />

              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Sticky topOffset={-70} style={{ top: "70px" }}>
                  <Nav
                    pills
                    className="flex-row justify-content-between navs_aside"
                  >
                    <NavItem className="border_right">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "": true,
                          active: verticalActiveTab === "1",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("1")
                        }}
                      >
                        Active Users ({activeCount})
                      </NavLink>
                    </NavItem>

                    <NavItem className="border_right">
                      <NavLink
                        style={{ cursor: "pointer", borderRadius: 0 }}
                        className={classnames({
                          "": true,
                          active: verticalActiveTab === "2",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("2")
                        }}
                      >
                        {isread == 1 ? <span className="dot"></span> : null}{" "}
                        Pending Users ({pendingCount})
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "": true,
                          active: verticalActiveTab === "3",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("3")
                        }}
                      >
                        Deactive Users ({unActiveCount})
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "": true,
                          active: verticalActiveTab === "4",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("4")
                        }}
                      >
                        Guests ({guestsCount})
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Sticky>
              </Col>
              <Col md="12  mt-2">
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted table__content mt-4 mt-md-0"
                >
                  <TabPane tabId="1">
                    <ActiveUsers
                      setActiveCount={setActiveCount}
                      country={country}
                      update={update}
                      activeState={activeState}
                      updateState={updateState}
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <PendingUsers
                      setPendingCount={setPendingCount}
                      country={country}
                    />
                  </TabPane>
                  <TabPane tabId="3">
                    <UnActiveUsers
                      setUnActiveCount={setUnActiveCount}
                      country={country}
                      activeState={activeState}
                      updateState={updateState}
                    />
                  </TabPane>
                  <TabPane tabId="4">
                    <Guests
                      setGuestsCount={setGuestsCount}
                      country={country}
                      activeState={activeState}
                      updateState={updateState}
                    />
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Users

const navsStyle = {
  cursor: "pointer",
  borderRadius: 0,
}
